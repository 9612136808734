<div class="container-fluid sidebar-component" [class.hide]="!showTemplate" >
    <div class="row">
        <div class="col-lg-12">
                <span class="hm-icon-close top-x" (click)="close()"></span>
            <div class="header">
                <h2>Online reporting tool</h2>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="help-body">
                <ul class="help-main-list">
                    <li>
                        <a target="_blank" [href]="helpLinks.overview"><span class="fas fa-external-link-alt"></span> Overview of the new reporting tool</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.using_dashboards"><span class="fas fa-external-link-alt"></span> Help using dashboards</a>
                        <ul class="sub-list">
                            <li>
                                <a target="_blank" [href]="helpLinks.viewing_dashboards"><span class="fas fa-external-link-alt"></span> Viewing a dashboard</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.editing_dashboards"><span class="fas fa-external-link-alt"></span> Editing a dashboard</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.saving_dashboards"><span class="fas fa-external-link-alt"></span> Saving a dashboard</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.exporting_dashboards"><span class="fas fa-external-link-alt"></span> Exporting a dashboard</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.using_reports"><span class="fas fa-external-link-alt"></span> Help using reports</a>
                        <ul class="sub-list">
                            <li>
                                <a target="_blank" [href]="helpLinks.viewing_reports"><span class="fas fa-external-link-alt"></span> Viewing a report</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.editing_reports"><span class="fas fa-external-link-alt"></span> Editing a report</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.saving_reports"><span class="fas fa-external-link-alt"></span> Saving a report</a>
                            </li>
                            <li>
                                <a target="_blank" [href]="helpLinks.loading_reports"><span class="fas fa-external-link-alt"></span> Loading a report</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.using_datasets"><span class="fas fa-external-link-alt"></span> Help using datasets</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.updating_date_range"><span class="fas fa-external-link-alt"></span> Updating the date range</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.changing_saving_peer_groups"><span class="fas fa-external-link-alt"></span> Changing and saving peer groups</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.analyse_function"><span class="fas fa-external-link-alt"></span> Using the analyse function</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.inidicator_definitions"><span class="fas fa-external-link-alt"></span> Viewing indicator definitions</a>
                    </li>
                    <li>
                        <a target="_blank" [href]="helpLinks.faq"><span class="fas fa-external-link-alt"></span> Frequently asked questions</a>
                    </li>
                </ul>

                <div> Not found the answer you’re looking for? Contact the HouseMark data helpline on 024 7647 2707 or email
                    <a target="_blank" class="email" href="mailto:data@housemark.co.uk">data@housemark.co.uk</a>
                </div>
            </div>
        </div>
    </div>
</div>