import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IsSelectableOption } from '../../../models/options/is-selectable-option';

@Component({
    selector: 'add-report-section-modal',
    templateUrl: './add-report-section-modal.component.html'
})
export class AddReportSectionModalComponent {
    @Input() options!: IsSelectableOption<number>[];
    @Output() closeAddSectionModal = new EventEmitter<any>();
    @Output() addSection = new EventEmitter<number>();

    constructor() { }

    add() {
        this.addSection.emit(this.options.find(opt => opt.isSelected)!.value);
    }

    update(id: number) {
        this.options.forEach(opt => {
            opt.isSelected = opt.value === id;
        });
    }

    cancel() {
        this.closeAddSectionModal.emit();
    }
}
