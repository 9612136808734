<div class="manage-dropdown" tabindex="-1" (blur)="this.showDropdown = false">
    <div class="icon" [class.active]="showDropdown" (click)="showDropdown = !showDropdown">
        <i class="fas fa-bars"></i>
    </div>
    <div *ngIf="showDropdown" class="dropdown-body">
        <div class="item" (click)="showLoadModal()">
            <i class="far fa-folder-open"></i>
            <div>Load {{context}}</div>
        </div>
        <div class="item" (click)="showSaveModal()">
            <i class="far fa-save"></i>
            <div>Save {{context}}</div>
        </div>
    </div>
</div>

<simple-load-item-modal (closeModal)="showLoad = false" (load)="loadItem()" [items]="listItems" [context]="context" *ngIf="showLoad"></simple-load-item-modal>

<simple-save-item-modal (closeModal)="showSave = false" (save)="saveItem($event)" [context]="context" [items]="[]" *ngIf="showSave"></simple-save-item-modal>