import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartTileModule } from '../tiles/chart-tile/chart-tile.module';
import { FormsModule } from '@angular/forms';
import { DownloadReportDialogueComponent } from './download-report-dialogue/download-report-dialogue.component';
import { ReorderReportDialogueComponent } from './reorder-report-dialogue/reorder-report-dialogue.component';
import { SharedModule } from '../shared/shared.module';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ChartModule } from '../../highcharts/chart.module';
import { ReportComponent } from './report-component/report.component';
import { EditReportModalComponent } from './edit-report-modal/edit-report-modal.component';
import { OptionsModule } from '../options/options.module';
import { DeleteReportItemModalComponent } from './delete-report-item-modal/delete-report-item-modal.component';
import { AddReportSectionModalComponent } from './add-report-section-modal/add-report-section-modal.component';
import { JsReportService } from '../../services/js-report.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        ChartTileModule,
        ChartModule,
        FormsModule,
        DragDropModule,
        OptionsModule,
        SharedModule
    ],
    declarations: [
        DownloadReportDialogueComponent,
        ReorderReportDialogueComponent,
        ReportComponent,
        ReportHeaderComponent,
        EditReportModalComponent,
        DeleteReportItemModalComponent,
        AddReportSectionModalComponent
    ],
    providers: [
        JsReportService
    ]
})
export class ReportsModule { }
