<div class="report-modal" id="download-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Download dashboard</h4>
                </div>
                <div class="body">
                    <span>Select sections to download</span>
                    <div class="download-list">
                        <ng-container *ngFor="let section of dialogueItems">
                            <div class="list-item" [class.disabled]="!section.isSelected">
                                <div (click)="section.hideComponents = !section.hideComponents" class="name-wrapper">
                                    <i class="fas fa-chevron-right" *ngIf="section.hideComponents"></i>
                                    <i class="fas fa-chevron-down" *ngIf="!section.hideComponents"></i>
                                    {{section.sectionName}}
                                </div>
                                <input [(ngModel)]="section.isSelected" (ngModelChange)="clickSection(section)"
                                    type="checkbox">
                            </div>
                            <div class="nested" *ngIf="!section.hideComponents">
                                <div *ngFor="let component of section.components" [class.disabled]="!component.isSelected || !section.isSelected" class="list-item">
                                    <div class="label">
                                        {{component.componentName}}
                                    </div>
                                    <input [(ngModel)]="component.isSelected" (ngModelChange)="clickComponent(section, component)" type="checkbox">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="footer">

                    <button (click)="exportAsPDF()">Download PDF</button>
                    <button (click)="exportAsExcel()">Download XLS</button>

                    <button class="cancel dark-button" (click)="close()">Cancel</button>

                </div>
            </div>
        </div>
    </div>
</div>