import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { HideableOption } from 'src/app/models/options/hideable-option';

@Component({
    selector: 'component-options',
    templateUrl: './component-options.component.html'
})
export class ComponentOptionsComponent {

    @Input() availableComponents!: HideableOption<number>[];
    @Output() selectionChanged: EventEmitter<number> = new EventEmitter<number>();

    update(id: number) {
        this.selectionChanged.emit(id);
    }

}
