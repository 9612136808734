import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
    @Input() show = false;
    name = 'app-spinner';
    title?: string;
    body?: string;

    constructor(private spinnerService: SpinnerService) {
        this.spinnerService._register(this);
    }

    ngOnInit() {
        // if (this.name === undefined || this.name === '') throw new Error('Spinner must have a name!');

        // this.spinnerService._register(this);
    }

    showText(title: string, body: string) {
        this.title = title;
        this.body = body;
    }

    clearText() {
        this.title = '';
        this.body = '';
    }
}
