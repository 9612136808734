import { Component, ViewChild, OnInit } from '@angular/core';
import { PeersService } from '../../../services/peers.service';
import { PeerGroupType } from '../../../models/enum/peer-group-type';
import { NavigationService } from '../../../services/navigation.service';
import { BaseNavigationComponent } from '../../shared/base-navigation/base-navigation';
import { DynamicPeerGroupComponent } from './dynamic-peer-group/dynamic-peer-group.component';
import { BespokePeerGroupComponent } from './bespoke-peer-group/bespoke-peer-group.component';
import { OrganisationService } from '../../../services/organisation.service';
import { DateRangeService } from '../../../services/date-range.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { MyAccountService } from '../../../services/my-account.service';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { PeerGroupListItem } from '../../../models/user-preferences/my-account-list-item';
import { SimpleLoadOption } from '../../../models/options/simple-load-option';

@Component({
    selector: 'sidebar-peer-selection',
    templateUrl: './peer-selection.component.html'
})
export class PeerSelectionComponent extends BaseNavigationComponent implements OnInit {
    peerGroupCount!: number;
    showBespoke = false;
    slidersSetup = false;
    displayDiscardModal = false;
    showLoadModal = false;
    showSaveModal = false;
    showResetModal = false;

    @ViewChild(DynamicPeerGroupComponent) dynamicPeerGroupComponent!: DynamicPeerGroupComponent;
    @ViewChild(BespokePeerGroupComponent) bespokePeerGroupComponent!: BespokePeerGroupComponent;
    dateRangeText: string = '';
    filterItems!: SimpleLoadOption<number>[];

    constructor(private peersService: PeersService, navigationService: NavigationService, organisationService: OrganisationService,
        private dateRangeService: DateRangeService, private spinnerService: SpinnerService, private myAccountService: MyAccountService,
        private toastr: ToastrService) {
        super(navigationService, 'PeerSelectionComponent', organisationService);
    }

    ngOnInit() {
        this.peerGroupCount = this.peersService.currentPeerCount;
        this.filterItems = this.myAccountService.userPeerGroups.filters.map(upg => new SimpleLoadOption<number>(false, upg.name, upg.id, upg.isOwnedByUser));

        this.peersService.peersChanged.subscribe(() => {
            this.peerGroupCount = this.peersService.currentPeerCount;
            this.filterItems = this.myAccountService.userPeerGroups.filters.map(upg => new SimpleLoadOption<number>(false, upg.name, upg.id, upg.isOwnedByUser));
        });

        this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();

        this.dateRangeService.dateRangeLoadedOrChanged.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
        });
    }
    
    changeTab(name: string) {
        if ((name === 'dynamic' && !this.showBespoke) || (name === 'bespoke' && this.showBespoke)) return;

        if (this.showBespoke && this.bespokePeerGroupComponent.includedPeerCount > 0) {
            if (this.peersService.hasUnsavedChanges(PeerGroupType.Bespoke)) {
                this.showDiscardModal();
            } else {
                this.showBespoke = false;
            }
        } else if (!this.showBespoke && this.dynamicPeerGroupComponent.canChangeTab()) {
            if (this.peersService.hasUnsavedChanges(PeerGroupType.Dynamic)) {
                this.showDiscardModal();
            } else {
                this.showBespoke = true;
            }
        }
    }

    canSaveOptions(): boolean {
        return this.showBespoke === true || !this.dynamicPeerGroupComponent.noStandardOptionsChosen();
    }

    showDiscardModal() {
        this.displayDiscardModal = true;
    }

    closeDiscardModal() {
        this.displayDiscardModal = false;
    }

    reset() {
        this.peersService.resetFilters();
        this.dynamicPeerGroupComponent.dynamicFilters = this.peersService.currentFilters;
        this.bespokePeerGroupComponent.peers = this.peersService.peers;
        this.bespokePeerGroupComponent.countPeers();
        this.closeDiscardModal();
    }

    ensureSlidersAreSetup() {
        if (this.slidersSetup) return;

        this.dynamicPeerGroupComponent.reInitStockSlider();

        this.slidersSetup = true;
    }

    loadFilter() {
        const selectedItem = this.filterItems.find(fi => fi.isSelected);

        if (!selectedItem) return;

        if (selectedItem.value !== null && selectedItem.value > 0) {
            const peerFilter = this.myAccountService.userPeerGroups.filters.find(fi => fi.id === selectedItem.value);

            if (!peerFilter) return;

            this.showBespoke = peerFilter.type === PeerGroupType.Bespoke;
            this.peersService.loadPeerGroup(peerFilter.filterId, selectedItem.text);
        } else {
            // text here is the 'default peer group name
            this.peersService.getDefaultPeers(selectedItem.text);
        }

        this.showLoadModal = false;
    }

    saveFilter(value: string) {
        this.spinnerService.show('app-spinner');
        if(!this.peersService.currentFilter) return;
        
        this.myAccountService.addPeerGroupFilter(value, this.peersService.currentFilter.id).subscribe((res: PeerGroupListItem) => {
            this.myAccountService.addToUserFilterList(res);
            this.peersService.filterName = value;
            this.toastr.success('Your peer group was successfully saved', 'Success');
            this.showSaveModal = false;
            this.spinnerService.hide('app-spinner');
        }, () => {
            this.toastr.error('Please try again', 'A problem occured when saving your peer group');
            this.spinnerService.hide('app-spinner');
        });
    }

    openSaveModal() {
        this.showSaveModal = true;
        this.displayDiscardModal = false;
    }

    getPeerGroupName() {
        return this.peersService.filterName;
    }

    applyAndOpenSaveModal() {
        this.apply(false);
        this.openSaveModal();
    }

    openSaveOrDiscardModal() {
        if (this.peersService.hasUnsavedChanges(PeerGroupType.Bespoke) || this.peersService.hasUnsavedChanges(PeerGroupType.Dynamic)) {
            this.showDiscardModal();
        } else {
            this.openSaveModal();
        }
    }

    apply(closeWindow: boolean) {
        if (this.showBespoke) {
            this.bespokePeerGroupComponent.apply(closeWindow);
        } else {
            this.dynamicPeerGroupComponent.apply(closeWindow);
        }

        this.displayDiscardModal = false;
    }
}
