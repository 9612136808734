import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HeadersService } from './headers.service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {
    private headers: HttpHeaders;
    private apiUrl: string;

    constructor(private httpClient: HttpClient, private headersService: HeadersService) {
        this.apiUrl = `/api/Charts/`;
        this.headers = headersService.getHeaders();
    }

    getComponents(graphType: string, organisationKey: string, infoComponentIds: string): Observable<any> {
        const newUrl = this.apiUrl + `${graphType}/${organisationKey}/${infoComponentIds}`;

        return this.httpClient.get(newUrl, { headers: this.headers });
    }
}
