import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExportService } from '../../services/export.service';
import { Activity } from '../../models/export/activities';
import { ActivityListItem } from '../../models/export/activity-list-item';
import { PeersService } from '../../services/peers.service';
import { PeerGroupType } from '../../models/enum/peer-group-type';
import { SpinnerService } from '../spinner/spinner.service';
import { DateRangeService } from '../../services/date-range.service';
import { NavigationService } from '../../services/navigation.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'data-sets',
    templateUrl: './data-sets.component.html'
})
export class DataSetsComponent implements OnInit, OnDestroy {
    dateRangeSubscription!: Subscription;
    dateRangeText!: string;
    peerGroupType!: string;
    peerGroupCount!: number;
    peerSubscription!: Subscription;
    organisationSubscription!: Subscription;
    activities: ActivityListItem[] = [];
    sortCriteria = '';
    searchTerm = '';

    constructor(private exportService: ExportService, private peersService: PeersService, private spinnerService: SpinnerService,
        private dateRangeService: DateRangeService, public navigationService: NavigationService) { }

    ngOnInit() {
        this.getActivities();
        this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
        this.dateRangeSubscription = this.dateRangeService.dateRangeLoaded.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
            this.getActivities();

        });

        this.peerGroupCount = this.peersService.currentPeerCount;
        this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];

        this.peerSubscription = this.peersService.peersChanged.subscribe(() => {
            this.peerGroupCount = this.peersService.currentPeerCount;
            this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];
        });

        this.dateRangeSubscription = this.dateRangeService.dateRangeChanged.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
            this.getActivities();
        });
    }

    private getActivities() {
        this.spinnerService.show('app-spinner');
        this.exportService.getActivitiesForExport().subscribe({
            next: (res: Activity[]) => {
                this.activities = res.map(activity => new ActivityListItem(activity));
                this.sort();
            }, 
            complete: () => this.spinnerService.hide('app-spinner')
        });
    }

    ngOnDestroy(): void {
        if (this.peerSubscription) this.peerSubscription.unsubscribe();
        if (this.dateRangeSubscription) this.dateRangeSubscription.unsubscribe();
        if (this.organisationSubscription) this.organisationSubscription.unsubscribe();
    }

    sort() {
        const reverse = this.sortCriteria.includes('-');

        if (this.sortCriteria.includes('checked')) this.sortBy(['selected', 'name'], [reverse ? 'asc' : 'desc', 'asc']);
        else if (this.sortCriteria.includes('sectionName')) this.sortBy(['sectionName', 'name'], [reverse ? 'desc' : 'asc', 'asc']);
        else this.sortBy(['name'], [reverse ? 'desc' : 'asc']);
    }

    sortBy(properties: string[], order: string[]) {
        const testObj = new ActivityListItem(new Activity());

        if (properties.some(val => !(val in testObj))) return;

        this.activities = this.activities.sort((left: ActivityListItem, right: ActivityListItem) => {

            if (left.isEnabled && !right.isEnabled) return -1;
            else if (!left.isEnabled && right.isEnabled) return 1;

            for (let i = 0; i < properties.length; i++) {
                const element = properties[i];
                const orderStr = i + 1 > order.length ? 'asc' : order[i];
                const leftVal = left[element];
                const rightVal = right[element];

                let compareResult = 0;
                if (typeof leftVal === 'boolean') {
                    if (leftVal && !rightVal) compareResult = 1;
                    else if (!leftVal && rightVal) compareResult = -1;
                } else {
                    compareResult = leftVal.localeCompare(rightVal, undefined, { numeric: true, sensitivity: 'base' });
                }

                if (compareResult === 0) continue;

                return orderStr === 'asc' ? compareResult : compareResult === -1 ? 1 : -1;
            }

            return 0;
        });
    }

    setSortCriteria(option: string) {
        if (this.sortCriteria === '') {
            this.sortCriteria = option;
        } else if (this.sortCriteria.includes(option)) {
            if (this.sortCriteria.includes('-')) {
                this.sortCriteria = '';
            } else {
                this.sortCriteria = '-' + option;
            }
        } else {
            this.sortCriteria = option;
        }

        this.sort();
    }

    toggleAllActivities(e: Event) {
        this.activities.filter(activity => activity.isEnabled)
            .forEach(activity => activity.isSelected = (<HTMLInputElement>e.target).checked);
    }

    areAllActivities(value: boolean): boolean {
        if (this.activities) {
            return this.activities
                .filter(activity => activity.isEnabled)
                .every(peer => peer.isSelected === value);
        }
        return false;
    }

    download() {
        this.spinnerService.show('app-spinner');
        this.exportService.exportDataset(this.activities
            .filter(item => item.isSelected)
            .map(item => item.activityName))
            .subscribe((res: Blob) => {
                this.spinnerService.hide('app-spinner');
                const fileName = `Export.xlsx`;
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(res, fileName);
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    link.download = fileName;
                    link.click();
                }
            }, () => this.spinnerService.hide('app-spinner'));
    }

    public filterItems() {
        this.activities.forEach((activity: ActivityListItem) => {
            activity.isVisible = !this.searchTerm || this.searchTerm.length < 3 || activity.name.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
    }

    public clearSearchTerm() {
        this.searchTerm = '';
        this.filterItems();
    }
}
