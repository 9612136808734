import { ChartType } from "../enum/chart-type";
import { Tile } from "../dashboard/tile";
import { DifferenceType } from "./difference-type";
import { QuartileSummary } from "../../highcharts/quartile-summary";
import { TileData } from "../../highcharts/options";

export class InfoTile {
    title!: string;
    previousValue!: string;
    latestValue!: string;
    differenceType!: DifferenceType;
    units!: string;
    quartileClass = 'none';
    calcRef!: number;
    description!: string;
    dashboardItemRef!: string;
    chartType = ChartType.infoTile;
    icon!: string;
    noData!: boolean;
    quartileSummary!: QuartileSummary;

    constructor(tile: Tile) {
        if(!tile) return;

        if (!tile.selected) {
            this.quartileClass = "no-data";
            this.calcRef = tile.selectedCalcRef;
            this.dashboardItemRef = tile.dashboardItemRef;
            return;
        }

        this.calcRef = tile.selected.calcRef;
        this.title = tile.selected.name;
        this.units = tile.selected.units;
        this.dashboardItemRef = tile.dashboardItemRef;
        this.icon = tile.selected.icon
            ? `hm-icon-pi-${tile.selected.icon}`
            : "";

        if(tile.data) {
            this.quartileSummary = tile.data.quartileSummary;
            if (!this.title) {
                this.title = tile.data.title!.text!;
            }

            if (tile.data.series) {
                const tileDatas = tile.data.series! as TileData[];
                const tileData = tileDatas[0];
    
                this.setPropertiesBasedOnData(tileData, tile);
            }
        }        
    }

    private setPropertiesBasedOnData(tileData: TileData, tile: Tile) {
        if (tileData.quartile < 1) {
            this.quartileClass = "no-data";
            this.noData = true;
            return;
        }

        this.previousValue = tileData.previousValue;
        if (this.previousValue === null)
            this.differenceType = DifferenceType.NoDataPrevious;
        else if (tileData.previousValue < tileData.latestValue)
            this.differenceType = DifferenceType.UpFrom;
        else if (tileData.previousValue === tileData.latestValue)
            this.differenceType = DifferenceType.NoChange;
        else
            this.differenceType = DifferenceType.DownFrom;

        this.setValueBasedOnUnit(tileData);
        this.quartileClass = `rank-${tileData.quartile}`;
    }

    private setValueBasedOnUnit(tileData: TileData) {
        this.latestValue = tileData.latestValue;
        if (this.units === "£") {
            this.latestValue = `£${this.latestValue}`;
            this.previousValue = `£${this.previousValue}`;
        } else if (this.units === "%") {
            this.latestValue += "%";
            this.previousValue += "%";
        } else if (this.units === "days" || this.units === "seconds")
            this.description = this.units;
    }
}
