import { Component, Input } from '@angular/core';
import { InfoTile } from '../../../models/info-tile/info-tile';
import { DifferenceType } from '../../../models/info-tile/difference-type';

@Component({
    selector: 'large-info-tile',
    templateUrl: './large-info-tile.component.html'
})
export class LargeInfoTileComponent {
    DifferenceType = DifferenceType;
    @Input() infoTile!: InfoTile;
    noDataReason: string = '';

    constructor() { }
}
