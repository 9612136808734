import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { EditAnalyseTileSection } from '../../../../models/analyse/edit-analyse-tile-section';
import { AnalyseChosenOptions } from '../../../../models/chosen-options';
import { HideableOption } from '../../../../models/options/hideable-option';
import { ChartType } from '../../../../models/enum/chart-type';
import { Subject } from 'rxjs';
import { ComponentCharts } from '../../../../models/component-charts';
import { EditAnalyseTileComponent } from '../../../../models/analyse/edit-analyse-tile-component';

@Component({
  selector: 'edit-bespoke-modal',
  templateUrl: './edit-bespoke-modal.component.html'
})
export class EditBespokeModalComponent implements OnInit {
    @Input() model: Array<EditAnalyseTileSection>;
    @Output() closeModal = new EventEmitter<any>();
    @Output() applyEdit = new EventEmitter<EditAnalyseTileComponent>();

    selectedComponent: HideableOption<number>;
    selectedSection: string;
    searchSubject = new Subject<string>();
    currentlySelectedEditOption: EditAnalyseTileComponent;
    currentChartTypes: ComponentCharts;
    filterText: string;

    constructor() { }

    ngOnInit() {
        this.currentChartTypes = new ComponentCharts();
        for (let i = 0; i < this.model.length; i++) {
            const section = this.model[i];

            section.piOptions.forEach(option => {
                if(option.isSelected) {
                    const currentComponent = section.components.find(comp => comp.calcRef === option.value)!;
                    this.assignSelections(section.function, option, currentComponent);
                }

                option.isVisible = true;
            });
        }
    }

    ngAfterViewInit(): void {
        this.scrollToSelectedPi();
    }

    private scrollToSelectedPi() {
        if(!this.selectedComponent) return;
        
        const selectedItemElement = document.getElementById(`first-${this.selectedComponent.value}`)!;
        selectedItemElement.scrollIntoView();
    }

    private assignSelections(section: string, selectedOption: HideableOption<number>, editOption: EditAnalyseTileComponent) {
        this.selectedSection = section;
        this.selectedComponent = selectedOption;
        this.currentlySelectedEditOption = editOption;
        this.currentChartTypes.chartTypes = this.currentlySelectedEditOption.chartTypes;

        if(!this.currentlySelectedEditOption.selectedChartType) 
            this.currentChartTypes.selectedChartType = this.currentlySelectedEditOption.chartTypes[0];
        else
            this.currentChartTypes.selectedChartType = this.currentlySelectedEditOption.selectedChartType;
    }

    cancel() {
        this.closeModal.emit();
    }

    public applyChoices(): void {
        this.selectedComponent.isSelected = false;
        this.applyEdit.emit(this.currentlySelectedEditOption);
    }

    selectChartType(value: { chartType: ChartType, index: number }): void {
        this.currentlySelectedEditOption.selectedChartType = value.chartType;    
    }

    selectComponent(id: number): void {
        const currentSection = this.model.find(section => section.function === this.selectedSection);

        if (currentSection) currentSection.piOptions.forEach(option => option.isSelected = false);

        for (let i = 0; i < this.model.length; i++) {
            const section = this.model[i];
            const component = section.components.find(comp => comp.calcRef === id);

            if (component) {
                section.piOptions.forEach(opt => {
                    opt.isSelected = opt.value === id;

                    if (opt.isSelected) {
                        this.selectedComponent = opt;
                        this.currentlySelectedEditOption = component;
                        this.currentChartTypes.chartTypes = this.currentlySelectedEditOption.chartTypes;

                        if(!this.currentlySelectedEditOption.selectedChartType) 
                            this.currentChartTypes.selectedChartType = this.currentlySelectedEditOption.chartTypes[0];
                        else
                            this.currentChartTypes.selectedChartType = this.currentlySelectedEditOption.selectedChartType;
                    }
                });

                this.selectedSection = section.function;
                break;
            }
        }
    }

    filterPis(filter: string) {
        this.model.forEach((sect: EditAnalyseTileSection, index: number) => {
            sect.piOptions.forEach((pi: HideableOption<number>, innerIndex: number) => {
                pi.isVisible = !filter || filter.length < 3 || pi.text.toLowerCase().includes(filter.toLowerCase());
            });
        });

        this.searchSubject.next(filter);
    }

    public disableApply(): boolean {
        return this.model.every(sect => sect.piOptions.every(pi => !pi.isSelectable));
    }
}
