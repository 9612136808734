import { NgModule } from '@angular/core';
import { IsVisibleInListPipe } from './IsVisible/is-visible-in-list.pipe';
import { OrdinalPipe } from './ordinal/ordinal.pipe';

@NgModule({
    declarations: [
        IsVisibleInListPipe,
        OrdinalPipe
    ],
    exports: [
        IsVisibleInListPipe,
        OrdinalPipe
    ]
})
export class PipesModule {

}
