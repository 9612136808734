import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SimpleLoadOption } from '../../../models/options/simple-load-option';

@Component({
    selector: 'manage-dropdown',
    templateUrl: './manage-dropdown.component.html'
})
export class ManageDropDownComponent implements OnInit {
    showLoad = false;
    showSave = false;
    showReset = false;
    showDropdown = false;

    @Output() loadCallback = new EventEmitter<number>();
    @Output() saveCallback = new EventEmitter<string>();
    @Input() listItems: SimpleLoadOption<number>[] = [];
    @Input() context: string = '';

    constructor() { }

    ngOnInit() {
    }

    showLoadModal() {
        this.showLoad = true;
    }

    loadItem() {
        this.showLoad = false;
        this.loadCallback.emit(this.listItems.find(item => item.isSelected)!.value);
    }

    showSaveModal() {
        this.showReset = false;
        this.showSave = true;
    }

    saveItem(name: string) {
        this.showSave = false;
        this.saveCallback.emit(name);
    }

    loadButtonDisabled(): boolean {
        return this.listItems.every(item => !item.isSelected);
    }

    blur() {
        this.showDropdown = false;
    }

}
