import { SelectableOption } from './selectable-option';

export class SimpleLoadOption<T> extends SelectableOption<T> {
    isOwnedByUser: boolean;

    constructor(isSelected: boolean, text: string, value: T, isOwnedByUser: boolean) {
        super(isSelected, text, value);

        this.isOwnedByUser = isOwnedByUser;
    }
}
