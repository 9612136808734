import { ElementRef, Input, OnChanges, OnDestroy, SimpleChange, HostListener, Component } from '@angular/core';
import { Chart } from './chart';
import * as Highcharts from 'highcharts';
import { Gauge } from './gauge';
import { Table } from './table';
import * as svgjs from 'svg.js';

@Component({
    selector: 'highcharts-chart',
    template: '<div class="highcharts-chart"></div>'
})
export class ChartComponent implements OnDestroy, OnChanges {
    @Input() chart!: Chart;
    @Input() units?: string;
    width: number = window.innerWidth;
    el: HTMLElement;
    constructor(elementRef: ElementRef) {
        this.el = elementRef.nativeElement as HTMLElement;
    }

    ngOnDestroy() {
        this.destroy();
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        this.destroy();
        this.init();
    }

    @HostListener('window:resize', ['$event'])
    private resize(event: any) {
        if (window.innerWidth !== this.width) {

            if (this.chart.options.chart!.type === 'solidgauge') Gauge.adjustText(this.chart);
            else if (this.chart.options.chart!.type === 'fivenumbersummary') Table.RenderTable(this.el.firstChild as HTMLElement, this.chart, this.units ?? '');
        }
    }

    private init(): void {
        const chartWrappingDiv = this.el.firstChild as HTMLElement;
        if (this.chart instanceof Chart && this.chart.options.chart) {
            if (this.chart.options.chart!.type === 'solidgauge') {
                this.chart.ref = Highcharts.chart(chartWrappingDiv, this.chart.options, (chart) => Gauge.RenderMedianPoints(chart));
                return;
            } 
            
            if (this.chart.options.chart!.type === 'fivenumbersummary') {
                Table.RenderTable(chartWrappingDiv, this.chart, this.units ?? '');
                this.chart.ref = undefined;
                return;
            } 
            
            if (this.chart.options.chart!.type === 'column' && this.chart.options.series!.length > 1) { 
                this.chart.options.tooltip!.formatter = this.columnFormatter;
            }
            
            if (this.chart.options.chart!.type === 'boxplot') {
                this.chart.options.tooltip!.formatter = this.boxplotFormatter;
            }

            if (this.chart.options.chart!.type === 'column' && this.chart.options.series!.length === 1) { 

                this.setupHoverForColumnPlotlines();
                this.chart.ref = Highcharts.chart(chartWrappingDiv, this.chart.options);
                this.renderHoverLabels();
                return;

            }

            this.chart.ref = Highcharts.chart(chartWrappingDiv, this.chart.options);
        }
    }

    private renderHoverLabels()  {
        const yAxisOptions = this.chart.options.yAxis as Highcharts.YAxisOptions;
        yAxisOptions.plotLines!.forEach((options: Highcharts.YAxisPlotLinesOptions) => {
            this.buildHoverLabelTwo(options);
        });
    }


    private setupHoverForColumnPlotlines() {        
        const yAxisOptions = this.chart.options.yAxis as Highcharts.YAxisOptions;
        yAxisOptions.plotLines!.forEach((options: Highcharts.YAxisPlotLinesOptions) => {
            options.events = {
                mouseover: function (this: any) {
                    // this is any because HighCharts.PlotLineOrBand type is wrong
                    // svgElement is actually svgElem
                    const element = this.svgElem.element.ownerSVGElement.querySelector(`.plotLineHover-${options.id}`) as HTMLElement;

                    const bbox = this.svgElem.getBBox();
                    const y = (bbox.y - 35.5);
                    element.setAttribute('transform', `translate(${bbox.x}, ${y})`);
                },
                mouseout: function (this: any) {
                    // this is any because HighCharts.PlotLineOrBand type is wrong
                    // svgElement is actually svgElem
                    const element = this.svgElem.element.ownerSVGElement.querySelector(`.plotLineHover-${options.id}`)
                    element.setAttribute('transform', 'translate(99999,99999)');
                }
            };
        });
    }

    private buildHoverLabelTwo(options: Highcharts.YAxisPlotLinesOptions) {
        const highchartsSvg = svgjs(this.chart.ref!.container.firstChild as HTMLElement);
        const draw = highchartsSvg.group();
        const borderColor = 'rgb(52,155,207)';
        const fill = "rgba(247, 247, 247, 0.85)";
        const border = {
            width: 1.25,
            color: borderColor
        };
        

        const text = highchartsSvg.text(`${options.id}: ${options.value}`).attr("style", "font-weight: bold; z-index: 7").move(10, 7);
        const bbox = text.bbox();
        const width = bbox.width + 10;
        const height = bbox.height + 14;
        const triangleWidth = 10;
        const halfOfTriangle = triangleWidth / 2;
        
        const halfWidth = width / 2;
        const triangleStart = halfWidth - halfOfTriangle;
        const triangleEnd = halfWidth  + halfOfTriangle;        
        const triangleHeight = halfOfTriangle + height;
        
        const polygon = draw.polygon(`0,0 ${width},0
                                      ${width},0 ${width},${height}
                                      
                                      ${width},${height} ${triangleEnd},${height}
                                      ${triangleEnd},${height} ${halfWidth},${triangleHeight}
                                      
                                      ${halfWidth},${triangleHeight} ${triangleStart},${height}
                                      ${triangleStart},${height} 0,${height}
                                      
                                      0,${height} 0,0`).fill(fill).stroke(border);

        polygon.translate(5, 0);
        text.addTo(draw);
        draw.addClass(`plotLineHover-${options.id}`);
        draw.translate(99999, 999999);
    }

    private columnFormatter(this: Highcharts.TooltipFormatterContextObject): string {
        if (this.series.name === 'Median' || this.series.name.startsWith('Q')) {
            const pointValue = this.point.options.low! + (this.point.options.high! - this.point.options.low!) / 2;
            return `<b>Peer group ${this.series.name}: ${pointValue}`;
        }

        return `${this.series.name}: ${this.y}`;
    }

    private boxplotFormatter(this: Highcharts.TooltipFormatterContextObject): string {
        return '<strong>' + this.key + '</strong><br/><strong>' + this.y + '</strong>';
    }

    private destroy() {
        if (this.chart && this.chart.ref) {
            this.chart.ref.destroy();
            delete this.chart.ref;
        }
    }
}
