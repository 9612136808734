import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ReportDialogueSection } from '../../../models/report-dialogue-section';
import { ReportDialogueComponent } from '../../../models/report-dialogue-component';

@Component({
    selector: 'reorder-report-dialogue',
    templateUrl: './reorder-report-dialogue.component.html',
})
export class ReorderReportDialogueComponent {
    @Input() dialogueItems: ReportDialogueSection[] = new Array<ReportDialogueSection>();
    @Output() reorder: EventEmitter<any> = new EventEmitter();
    @Output() closeModal: EventEmitter<any> = new EventEmitter();

    componentNotBeingDragged = true;

    constructor() { }

    close() {
        this.closeModal.emit();
    }

    apply() {
        this.reorder.emit();
    }

    toggleSection(section: ReportDialogueSection) {
        section.isSelected = !section.isSelected;

        section.components.forEach(component => {
            component.isSelected = true;
        });
    }

    toggleComponent(component: ReportDialogueComponent) {
        component.isSelected = !component.isSelected;
    }

    dropSection(event: {previousIndex: number, currentIndex: number}) {
        const currentSection = this.dialogueItems[event.previousIndex];

        if(!currentSection) return;

        this.dialogueItems.splice(event.previousIndex, 1);

        this.dialogueItems.splice(event.currentIndex, 0, currentSection);
    }

    dropSubItem(event: {previousIndex: number, currentIndex: number}, sectionIndex: number) {
        const currentSection = this.dialogueItems[sectionIndex];

        if(!currentSection) return;

        const currentComponent = currentSection.components[event.previousIndex];

        if(!currentComponent) return;

        currentSection.components.splice(event.previousIndex, 1);

        currentSection.components.splice(event.currentIndex, 0, currentComponent);
    }
}
