import { Component, EventEmitter, Output } from '@angular/core';
import { BaseTile } from '../base-tile/base-tile';
import { Tile } from '../../../../models/dashboard/tile';
import { TileMetaData } from '../../../../models/tile-metadata';
import { ChartType } from '../../../../models/enum/chart-type';
import { EditTileOptions } from '../../../../models/edit-tile-options';
import { InfoTile } from '../../../../models/info-tile/info-tile';
import { HideableOption } from '../../../../models/options/hideable-option';
import { ComponentCharts } from '../../../../models/component-charts';
import { DifferenceType } from '../../../../models/info-tile/difference-type';

@Component({
    selector: 'icon-info-tile',
    templateUrl: './icon-info-tile.component.html'
})
export class IconInfoTileComponent extends BaseTile {
    DifferenceType = DifferenceType;
    model!: Tile;
    data!: InfoTile;
    selectedChart!: number;
    currentCalcRef!: number;

    @Output() override openEditEvent: EventEmitter<BaseTile> = new EventEmitter<BaseTile>();
    
    constructor() {
        super();
    }

    getComponentsInTile(): TileMetaData[] {
        const metaData = new TileMetaData();
        metaData.chartType = ChartType[ChartType.infoTile];
        metaData.calcRef = this.data.calcRef;
        metaData.dashboardItemRef = this.data.dashboardItemRef;

        return new Array<TileMetaData>(metaData);
    }

    initializeModel(model: Tile): void {

        if (model) {
            this.model = model;

            this.data = new InfoTile(model);
            this.currentCalcRef = this.data.calcRef;
        } else {
            this.model = new Tile();
        }
    }

    updateModel(tileData: TileMetaData) {
        const selected = this.model.available.find(a => a.calcRef === tileData.calcRef);

        if (selected) {
            this.model.selected = selected;
            this.model.data = tileData.data;
            this.data = new InfoTile(this.model);
        }
    }

    getEditOptions(): EditTileOptions {
        const options = new EditTileOptions();

        options.chartsTypes = new Array<ComponentCharts>();
        const componentCharts = new ComponentCharts();
        componentCharts.selectedChartType = ChartType.infoTile;
        componentCharts.dashboardItemRef = this.data.dashboardItemRef;
        componentCharts.calcRef = this.data.calcRef;

        options.chartsTypes.push(componentCharts);

        options.piOptions = this.model.available.map(available => {
            return new HideableOption<number>(available.calcRef === this.data.calcRef, available.name, available.calcRef, true, available.noDataMessage);
        });

        return options;
    }
}
