<div class="edit-item-modal edit-modal" id="edit-item-modal" *ngIf="model">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content delete-content">
                <div class="edit-modal-header">
                    <h5>Delete Chart</h5>
                </div>
                <div class="body">
                    <span class="text">Are you sure you want to delete this chart from your report?</span>
                </div>
                <div class="footer-wrapper">
                    <div class="footer">
                        <button (click)="delete()">Delete</button>
                        <button (click)="cancel()" class="dark-button">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>