import { SliderModel } from './../slider';
import { PeerFilters } from './peer-filters';
import { SelectableOption } from '../options/selectable-option';
import { SliderData } from './slider-data';
import { APIPeerFilterData } from './api-peer-filter-data';

export class SelectedPeerFilters {
    dlo: SelectableOption<string>[];
    stock: SliderModel;
    regions: SelectableOption<string>[];
    organisationTypes: SelectableOption<string>[];
    advancedFilters: Map<number, SliderModel>;
    showOnlyDetailedCosts = false;

    constructor(peerFilters: PeerFilters) {

        this.advancedFilters = new Map<number, SliderModel>(
            [
                [2692, new SliderModel('Supported housing / very sheltered units as a % of all housing stock', 0, 100, 0, 100)],
                [2693, new SliderModel('Non-social housing units as a % of all housing stock', 0, 100, 0, 100)],
                [105320, new SliderModel('Percentage of units that were built pre-1919', 0, 100, 0, 100)],
                [105321, new SliderModel('Percentage of units that were built 1919-1944', 0, 100, 0, 100)],
                [105322, new SliderModel('Percentage of units that were built 1945-1964', 0, 100, 0, 100)],
                [105323, new SliderModel('Percentage of units that were built 1965-1982', 0, 100, 0, 100)],
                [105324, new SliderModel('Percentage of units that were built 1983-2002', 0, 100, 0, 100)],
                [105325, new SliderModel('Percentage of units that were built post 2002', 0, 100, 0, 100)],
                [2673, new SliderModel('Community investment spend as a % of direct revenue costs', 0, 100, 0, 100)]
            ]
        );

        this.stock = new SliderModel('', peerFilters.minimumStock, peerFilters.maximumStock, peerFilters.minimumStock, peerFilters.maximumStock);

        this.regions = peerFilters.regions.map(reg => new SelectableOption<string>(true, reg, reg));

        this.organisationTypes = peerFilters.organisationTypes.map(orgType => new SelectableOption<string>(true, orgType, orgType));

        this.dlo = [
            new SelectableOption<string>(true, 'Yes', 'Yes'),
            new SelectableOption<string>(true, 'No', 'No'),
            new SelectableOption<string>(true, 'No data', 'No data'),
        ];
    }

    applyAPIPeerFilterData(apiData: APIPeerFilterData) {
        const newStockSlider = new SliderModel(this.stock.name, this.stock.options.floor!, this.stock.options.ceil!, apiData.stock.lowerBound, apiData.stock.upperBound);
        this.stock = newStockSlider;

        if (apiData.regions.find(region => region.toLocaleLowerCase() === 'all')) {
            this.regions.forEach(option => option.isSelected = true);
        } else {
            this.regions.forEach(option => {
                option.isSelected = apiData.regions.includes(option.value);
            });
        }

        if (apiData.organisationTypes.find(region => region.toLocaleLowerCase() === 'all')) {
            this.organisationTypes.forEach(option => option.isSelected = true);
        } else {
            this.organisationTypes.forEach(option => {
                option.isSelected = apiData.organisationTypes.includes(option.value);
            });
        }

        if (apiData.dlos.find(region => region.toLocaleLowerCase() === 'all')) {
            this.dlo.forEach(option => option.isSelected = true);
        } else {
            this.dlo.forEach(option => {
                option.isSelected = apiData.dlos.includes(option.text);
            });
        }

        apiData.selectedAdvancedFilters.forEach((data: SliderData) => {
            const slider = this.advancedFilters.get(data.calcRef);

            if (slider) {
                this.advancedFilters.set(data.calcRef, new SliderModel(slider.name, slider.options.floor!, slider.options.ceil!, data.lowerBound, data.upperBound));
            }
        });

        if (apiData.showOnlyDetailedCosts !== undefined) this.showOnlyDetailedCosts = apiData.showOnlyDetailedCosts;
    }

    public copyFilters(): SelectedPeerFilters {
        const advancedOptions = new Map<number, SliderModel>();

        this.advancedFilters.forEach((filter, filterKey) => {
            const filterCopy = JSON.parse(JSON.stringify(filter));
            advancedOptions.set(filterKey, Object.setPrototypeOf(filterCopy, SliderModel.prototype));
        });

        const af = this.advancedFilters;
        this.advancedFilters = new Map<number, SliderModel>();
        const dynamicFilters = JSON.parse(JSON.stringify(this));
        dynamicFilters.advancedFilters = advancedOptions;

        this.advancedFilters = af;

        return Object.setPrototypeOf(dynamicFilters, SelectedPeerFilters.prototype);
    }
}

