<div class="dynamic-peer-group-body" id="dynamic-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="top-row">
                <h3>Standard Filters
                    <i [class.error]="noStandardOptionsChosen()" id="standard-chevron" (click)="toggleSectionVisibility($event)" class="hide fas fa-chevron-down "></i>
                </h3>

                <div class="buttons">
                    <button [class.disabled]="noStandardOptionsChosen()" (click)="apply(true)">Apply </button>
                    <button (click)="cancel()" class="grey-button">Discard changes</button>
                </div>
            </div>
        </div>
    </div>
    <div class="standard-filters">
        <div class="standard-wrapper">
            <div id="standard-filters-body">
                <div *ngIf="dynamicFilters">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="icon-title">
                                <i class="hm-icon-pi-7"></i> Stock</div>
                            <slider [model]="dynamicFilters.stock"></slider>
                        </div>
                    </div>
                    <div class="row checkbox-options-row">
                        <div class="col-lg-4" [class.highlight]="noneSelected(dynamicFilters.regions)">
                            <div class="icon-title">
                                <i class="hm-icon-location"></i> Location</div>
                            <checkbox-options [options]="dynamicFilters.regions"></checkbox-options>
                        </div>
                        <div class="col-lg-4" [class.highlight]="noneSelected(dynamicFilters.organisationTypes)">
                            <div class="icon-title">
                                <i class="hm-icon-org-type"></i> Organisation type</div>
                            <checkbox-options [options]="dynamicFilters.organisationTypes"></checkbox-options>
                        </div>
                        <div class="col-lg-4" [class.highlight]="noneSelected(dynamicFilters.dlo)">
                            <div class="icon-title">
                                <i class="hm-icon-pi-8"></i> DLO</div>
                            <checkbox-options [options]="dynamicFilters.dlo"></checkbox-options>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <input [(ngModel)]="dynamicFilters.showOnlyDetailedCosts" type="checkbox"> Only show organisations
                            who have completed detailed costs
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="advanced-filters">
            <div class="row">
                <div class="col-lg-12">
                    <h4>Advanced Filters
                        <i id="advanced-chevron" (click)="toggleSectionVisibility($event)" class="fas fa-chevron-down"></i>
                    </h4>
                </div>
            </div>
            <div id="advanced-filters-body" class="hide">
                <div *ngIf="dynamicFilters">
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(2692)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(2693)"></slider>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105320)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105321)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105322)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105323)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105324)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(105325)"></slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <slider [model]="getFilter(2673)"></slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons pull-right">
            <button [class.disabled]="noStandardOptionsChosen()" (click)="apply(true)">Apply </button>
            <button (click)="cancel()" class="grey-button">Discard changes</button>
        </div>
    </div>
</div>