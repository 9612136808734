<div class="dropdown-load-modal simple-modal">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Load {{context}}</h4>
                </div>
                <div class="body">
                    <span>Select a {{context}} to load from the list below</span>

                    <div *ngIf="items" class="list-wrapper">
                        <ul class="list-unstyled">

                            <li (click)="selectItem(item.value)" [class.selected]="item.isSelected" *ngFor="let item of items">
                                <i *ngIf="!item.isOwnedByUser" class="fas fa-lock" ngbTooltip="This option is either the HouseMark default option or the default set by your local administrator"></i>
                                {{item.text}}
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="!items">
                        There are no {{context}}s to load
                    </div>

                    <div class="button-wrapper">
                        <button [class.disabled]="loadButtonDisabled()" (click)="load.emit()">Load</button>
                        <button class="dark-button" (click)="closeModal.emit()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>