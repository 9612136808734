import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { PeersService } from '../../../services/peers.service';
import { PeerGroupType } from '../../../models/enum/peer-group-type';
import { DateRangeService } from '../../../services/date-range.service';
import { NavigationService } from '../../../services/navigation.service';
import { MyAccountService } from '../../../services/my-account.service';
import { LayoutType } from '../../../models/enum/layout-type';
import { LayoutService } from '../../../services/layout.service';
import { SimpleLoadOption } from '../../../models/options/simple-load-option';
import { UserPeerGroups } from '../../../models/user-preferences/user-peer-groups';
import { JsReportService } from '../../../services/js-report.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../../../services/export.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashboard-header',
    templateUrl: './dashboard-header.component.html'
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {

    peerGroupType!: string;
    peerGroupCount!: number;
    peersSubscription!: Subscription;
    dateRangeSubscription!: Subscription;
    dateRangeText!: string;
    showDownloadModal = false;
    dashboardsList: SimpleLoadOption<number>[] = [];
    @Input() dashboardName!: string;
    @Input() dashboardHTMLId!: string;
    @Input() portraitExport?: boolean;
    @Input() dashboardId!: number;
    @Output() exportExcel = new EventEmitter<any>();
    @Output() exportPdf = new EventEmitter<any>();

    constructor(private peersService: PeersService, private dateRangeService: DateRangeService, public navigationService: NavigationService,
        private myAccountService: MyAccountService, private layoutService: LayoutService, private jsReportService: JsReportService, private spinnerService: SpinnerService,
        private toastr: ToastrService, private exportService: ExportService) {
    }

    ngOnInit() {
        this.peerGroupCount = this.peersService.currentPeerCount;
        this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];

        this.peersSubscription = this.peersService.peersChanged.subscribe(() => {
            this.peerGroupCount = this.peersService.currentPeerCount;
            this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];
        });

        this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();

        this.dateRangeSubscription = this.dateRangeService.dateRangeLoadedOrChanged.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
        });

        this.myAccountService.peerGroupsLoaded.subscribe((userPreferences: UserPeerGroups) => {
            this.dashboardsList = userPreferences.layouts.filter(layout => layout.layoutId === this.dashboardId && layout.type === LayoutType.Dashboard)
                .map(layout => new SimpleLoadOption<number>(false, layout.name, layout.id, layout.isOwnedByUser));
        });
    }

    ngOnDestroy() {
        this.peersSubscription.unsubscribe();
        this.dateRangeSubscription.unsubscribe();
    }

    closeDownloadModal(): void {
        this.showDownloadModal = false;
    }

    openDownloadModal() {
        this.showDownloadModal = true;
    }

    loadDashboardLayout(userLayoutId: number) {
        this.layoutService.dashboardLayoutId = userLayoutId;
        this.layoutService.loadDashboardLayout(userLayoutId);
    }

    saveDashboardLayout(name: string) {
        this.layoutService.saveDashboardLayout.next(name);
    }

    exportAsPDF() {
        this.closeDownloadModal();
        this.exportPdf.emit();
    }

    exportAsExcel() {
        this.closeDownloadModal();
        this.exportExcel.emit();
    }

    exportAsPng() {
        this.spinnerService.show('app-spinner');

        this.exportService.exportDashboardAsPng(this.dashboardName).subscribe(() => {
            this.closeDownloadModal();
            this.spinnerService.hide('app-spinner');
        } );
    }
}
