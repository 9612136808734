import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SelectableOption } from '../../../models/options/selectable-option';

@Component({
    selector: 'simple-save-item-modal',
    templateUrl: './simple-save-item-modal.component.html'
})
export class SimpleSaveItemModalComponent implements OnInit {
    @Output() closeModal = new EventEmitter<any>();
    @Output() save = new EventEmitter<string>();
    @Input() items!: Array<SelectableOption<number>>;
    @Input() context!: string;

    newItemName: string = '';

    constructor() { }

    ngOnInit() {
    }

    canSave() {
        return this.newItemName && !this.itemAlreadyExists();
    }

    itemAlreadyExists(): boolean {
        if (!this.newItemName || !this.items) return false;
        const normalisedName = this.normalizeString(this.newItemName);

        return this.items.some(item => this.normalizeString(item.text) === normalisedName);
    }

    normalizeString(text: string): string {
        return text.toLocaleLowerCase().trim();
    }
}
