import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from '../../../highcharts/chart.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LargeInfoTileComponent } from './large-info-tile.component';


@NgModule({
    declarations: [
        LargeInfoTileComponent
    ],
    imports: [
        CommonModule,
        ChartModule,
        BrowserModule,
        FormsModule
    ],
    exports: [
        LargeInfoTileComponent
    ]
})
export class LargeInfoTileModule { }
