import { AnalyseTileComponent } from './analyse-tile-component';
import { ChartType } from '../enum/chart-type';

export class EditAnalyseTileComponent {
    name: string;
    calcRef: number;
    chartTypes: ChartType[];
    selectedChartType: ChartType;
    guidance: string;
    units: string;
    noDataMessage: string;
    icon: string;

    constructor(atc?: AnalyseTileComponent) {

        if (atc) {
            this.name = atc.name;
            this.icon = atc.icon;
            this.calcRef = atc.id;
            this.chartTypes = atc.chartTypes.split(', ').map(val => ChartType[val]);
            this.selectedChartType = this.chartTypes[0];
            this.guidance = atc.guidance;
            this.units = atc.units;
            this.noDataMessage = atc.noDataMessage;
        }
    }
}
