<div class="container-fluid dashboard" id="bespoke-dashboard">
    <div class="row dashboard-header">
        <div class="col-12">
            <div class="header">
                <div class="top-row">
                    <h1>{{dashboardName}}</h1>
                    <div class="action-buttons">
                        <button id="change-style-button" (click)="openChangeStyleModal()">Layout</button>
                        <button id="download-button" (click)="openDownloadModal()">Download</button>
                        <manage-dropdown [context]="'dashboard'" [listItems]="dashboardsList"
                            (saveCallback)="saveDashboardLayout($event)"
                            (loadCallback)="loadDashboardLayout($event)"></manage-dropdown>
                    </div>
                </div>
                <div class="header-buttons">
                    <button (click)="navigationService.toggleComponentByName('DateRangeComponent')">
                        <i class="hm-icon-date-range"></i> {{dateRangeText}}
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-group"></i> {{peerGroupType}} Peer Group
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-count"></i> Peers In Group {{peerGroupCount}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-body" [id]="dashboardHTMLId">
        <div id="dashboard-export-wrapper">
            <ng-template template-host></ng-template>
        </div>
    </div>
</div>

<edit-bespoke-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit($event)" [model]="editTileOptions"></edit-bespoke-modal>

<div *ngIf="showDownloadModal" class="dashboard-download-modal simple-modal" id="download-dashboard-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Download dashboard</h4>
                </div>
                <div class="body">
                    <span>Select format to download</span>

                    <div class="button-wrapper">
                        <div class="left-side">
                            <button (click)="exportAsPDF()">Download PDF</button>
                            <button (click)="exportAsExcel()">Download XLS</button>
                            <button (click)="exportAsPng()">Download PNG</button>
                        </div>

                        <button class="dark-button" id="close-download-modal" (click)="closeDownloadModal()">Cancel</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showChangeStyleModal" class="simple-modal" id="change-style-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Change dashboard style</h4>
                </div>
                <div class="body">
                    <span>Select the layout format you wish to change to</span>
                    <span class="warning">Warning: When changing dashboard style you will lose any unsaved changes</span>

                    <div class="button-wrapper">
                        <div class="left-side">
                            <button (click)="changeStyle(DashboardStyle.smallCharts)">Mini charts</button>
                            <button (click)="changeStyle(DashboardStyle.dualCharts)">Combination</button>
                            <!-- <button (click)="changeToQuadrantLayout()">Quadrant</button> -->
                        </div>

                        <button class="dark-button" id="close-download-modal" (click)="closeChangeStyleModal()">Cancel</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>