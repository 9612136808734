import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { ChartType } from '../../../models/enum/chart-type';
import { AnalyseChosenOptions } from '../../../models/chosen-options';
import { ComponentCharts } from '../../../models/component-charts';
import { EditAnalyseTileSection } from '../../../models/analyse/edit-analyse-tile-section';
import { EditAnalyseTileComponent } from '../../../models/analyse/edit-analyse-tile-component';
import { HideableOption } from '../../../models/options/hideable-option';
import { Subject } from 'rxjs';

@Component({
    selector: 'edit-analyse-modal',
    templateUrl: './edit-analyse-modal.component.html'
})
export class EditAnalyseModalComponent implements OnInit, AfterViewInit {
    showSecondPiList: boolean = false;
    @Input() model!: EditAnalyseTileSection[];
    @Output() closeModal = new EventEmitter<any>();
    @Output() applyEdit = new EventEmitter<AnalyseChosenOptions>();

    selectedComponent!: HideableOption<number>;
    secondSelectedComponent?: HideableOption<number>;
    selectedSection!: string;
    selectedChartType!: ChartType;
    currentChartTypes!: ComponentCharts;
    filterText: string = '';
    modelCopy: EditAnalyseTileSection[] = [];
    searchSubject = new Subject<string>();

    constructor() { }

    ngOnInit() {
        this.currentChartTypes = new ComponentCharts();
        for (let i = 0; i < this.model.length; i++) {
            const section = this.model[i];

            const selectedOption = section.piOptions.find(opt => opt.isSelected);

            if (selectedOption) {
                const currentComponent = section.components.find(comp => comp.calcRef === selectedOption.value)!;
                this.assignSelections(section.function, selectedOption, currentComponent);

                break;
            }
        }

        if (this.selectedChartType === ChartType.scatter) {
            this.secondSelectedComponent = this.selectedComponent;
            this.showSecondPiList = true;
            this.deepCopyList();
        }
    }

    ngAfterViewInit(): void {
        this.scrollToSelectedPi();
    }

    private scrollToSelectedPi() {
        if(!this.selectedComponent) return;

        const selectedItemElement = document.getElementById(`first-${this.selectedComponent.value}`)!;
        selectedItemElement.scrollIntoView();
    }

    private assignSelections(section: string, selectedOption: HideableOption<number>, editOption: EditAnalyseTileComponent) {
        this.selectedSection = section;
        this.selectedComponent = selectedOption;
        this.currentChartTypes.chartTypes = editOption.chartTypes;
        this.currentChartTypes.selectedChartType = editOption.selectedChartType;
        this.selectedChartType = editOption.selectedChartType;
    }

    cancel() {
        this.closeModal.emit();
    }

    public applyChoices(): void {
        const secondComponent = this.secondSelectedComponent ? this.secondSelectedComponent.value : undefined;
        this.applyEdit.emit(new AnalyseChosenOptions(this.selectedComponent.value, this.currentChartTypes.selectedChartType, secondComponent));
    }

    selectChartType(value: { chartType: ChartType, index: number }): void {
        this.currentChartTypes.selectedChartType = value.chartType;
        this.selectedChartType = value.chartType;

        const section = this.model.find(eats => eats.function === this.selectedSection)!;
        section.components.find(comp => comp.calcRef === this.selectedComponent.value)!.selectedChartType = value.chartType;

        this.showSecondPiList = value.chartType === ChartType.scatter;

        if (value.chartType === ChartType.scatter) {
            this.deepCopyList();
            this.secondSelectedComponent = this.selectedComponent;
        } else {
            this.secondSelectedComponent = undefined;
            this.showSecondPiList = false;
        }
    }

    deepCopyList() {
        this.modelCopy = new Array<EditAnalyseTileSection>();
        this.model.forEach((editAnalyseTileSection: EditAnalyseTileSection) => {

            const ts = new EditAnalyseTileSection();
            ts.function = editAnalyseTileSection.function;
            ts.piOptions = JSON.parse(JSON.stringify(editAnalyseTileSection.piOptions));
            ts.components = new Array<EditAnalyseTileComponent>();

            ts.components = editAnalyseTileSection.components.map((val) => JSON.parse(JSON.stringify(val)));

            this.modelCopy.push(ts);
        });
    }

    selectComponent(id: number): void {
        const currentSection = this.model.find(section => section.function === this.selectedSection);

        if (currentSection) currentSection.piOptions.forEach(option => option.isSelected = false);

        for (let i = 0; i < this.model.length; i++) {
            const component = this.model[i].components.find(comp => comp.calcRef === id);

            if (component) {
                this.model[i].piOptions.forEach(opt => {
                    opt.isSelected = opt.value === id;

                    if (opt.isSelected) this.selectedComponent = opt;
                });

                const section = this.model[i];
                this.selectedSection = section.function;
                this.currentChartTypes.chartTypes = component.chartTypes;
                component.selectedChartType = this.selectedChartType;
                break;
            }
        }
    }

    selectSecondComponent(id: number): void {
        for (let i = 0; i < this.modelCopy.length; i++) {
            const component = this.model[i].components.find(comp => comp.calcRef === id);

            if (component) {
                this.secondSelectedComponent = this.model[i].piOptions.find(pi => pi.value === id);
            }
        }
    }

    filterPis(filter: string) {
        this.model.forEach((sect: EditAnalyseTileSection, index: number) => {
            sect.piOptions.forEach((pi: HideableOption<number>, innerIndex: number) => {
                pi.isVisible = !filter || filter.length < 3 || pi.text.toLowerCase().includes(filter.toLowerCase());

                if (this.showSecondPiList) this.modelCopy[index].piOptions[innerIndex].isVisible = pi.isVisible;
            });
        });

        this.searchSubject.next(filter);
    }

    public disableApply(): boolean {
        return this.model.every(sect => sect.piOptions.every(pi => !pi.isSelectable));
    }
}
