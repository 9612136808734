import { Component, Input } from '@angular/core';
import { SliderModel } from '../../models/slider';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'slider',
    templateUrl: './slider.component.html'
})
export class SliderComponent {
    @Input() model: SliderModel;

    constructor() { }

    reInitSlider() {
        this.model = new SliderModel(this.model.name, this.model.options.floor!, this.model.options.ceil!, this.model.minValue, this.model.maxValue);
    }
}
