export class SelectableOption<T> {
    isSelected: boolean;
    text: string;
    value: T;

    constructor(isSelected: boolean, text: string, value: T) {
        this.isSelected = isSelected;
        this.text = text;
        this.value = value;
    }
}
