import { JsReportPage } from '../export/js-report-page';

export class JsReportPageState {

    constructor(startHtml: string) {
        this.html = startHtml;
    }
    pages = new Array<JsReportPage>();
    currentListItemCount = 0;
    html: string;
    pageNum = 0;
}
