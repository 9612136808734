import { Component, OnDestroy } from '@angular/core';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { ToastrService } from 'ngx-toastr';
import { ChartsService } from '../../../services/charts.service';
import { TilesRequest } from '../../../models/tiles-request';
import { Dashboard } from '../../../models/dashboard/dashboard';
import { EditTileOptions } from '../../../models/edit-tile-options';
import { LayoutService } from '../../../services/layout.service';
import { TileMetaData } from '../../../models/tile-metadata';
import { ComponentCharts } from '../../../models/component-charts';
import { ChartType } from '../../../models/enum/chart-type';
import { SelectableOption } from '../../../models/options/selectable-option';
import { ExportService } from '../../../services/export.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { LayoutType } from '../../../models/enum/layout-type';
import { UserLayout } from '../../../models/user-preferences/user-layout';
import { JsReportService } from '../../../services/js-report.service';
import { Options } from '../../../highcharts/options';
import { DashboardId } from '../../../models/enum/dashboard-id';
import { filter, Subscription } from 'rxjs';

@Component({
    selector: '',
    template: '',
})
export abstract class DashboardComponent implements OnDestroy {
    abstract dashboardId: DashboardId;
    abstract dashboardHTMLId: string;
    abstract portraitExport: boolean;

    layout = new Dashboard();
    componentMap = new Map<string, BaseTile>();
    editOptions = new EditTileOptions();
    showEdit = false;
    peersSubscription!: Subscription;
    layoutSaveSubscription!: Subscription;
    dashboardName: string = '';
    loadDashboardLayoutSubscription!: Subscription;

    constructor(
        private layoutService: LayoutService,
        private chartsService: ChartsService,
        private peersService: PeersService,
        protected jsReportService: JsReportService,
        protected spinnerService: SpinnerService,
        private toastr: ToastrService,
        protected exportService: ExportService,
        private myAccountService: MyAccountService
    ) {}

    Init() {
        this.getInitialDashboard();

        this.peersSubscription = this.peersService.peersChanged.subscribe(
            (fullReload: boolean) => {
                if (fullReload) return this.getInitialDashboard();

                return this.getTileDataForDashboard();
            }
        );

        this.layoutSaveSubscription =
            this.layoutService.saveDashboardLayout.subscribe((name: string) => {
                this.spinnerService.show('app-spinner');
                const tilesRequest = new TilesRequest(this.componentMap);

                this.layoutService
                    .addLayout(
                        name,
                        this.dashboardId,
                        tilesRequest,
                        LayoutType.Dashboard
                    )
                    .subscribe(
                        (layout: UserLayout) => {
                            this.myAccountService.addToLayoutList(
                                layout,
                                this.dashboardName
                            );
                            this.spinnerService.hide('app-spinner');
                            this.toastr.success(
                                `Your dashboard layout, ${layout.name}, was successfully saved`,
                                'Success'
                            );
                        },
                        () => {
                            this.spinnerService.hide('app-spinner');
                            this.toastr.error(
                                'Please try again',
                                'A problem occurred when saving your dashboard layout'
                            );
                        }
                    );
            });

        this.loadDashboardLayoutSubscription =
            this.layoutService.dashboardLayoutChange
                .pipe(filter((id: number | null) => id !== null))
                .subscribe(() => this.getInitialDashboard());
    }

    ngOnDestroy() {
        this.peersSubscription.unsubscribe();
        this.layoutSaveSubscription.unsubscribe();
        this.loadDashboardLayoutSubscription.unsubscribe();
    }

    getInitialDashboard() {
        const layoutId = this.layoutService.dashboardLayoutId!;
        this.spinnerService.show('app-spinner');
        this.layoutService
            .getDashboardLayout(this.dashboardId, layoutId)
            .subscribe({
                next: (res: Dashboard) => {
                    this.layout = res;
                    this.dashboardName = this.layout.title;
                    this.componentMap.forEach((value, key) => {
                        const groupedCharts = this.layout.charts.filter(
                            (char) => char.dashboardItemRef.startsWith(key)
                        );

                        value.initializeModel(...groupedCharts);
                    });
                },
                error: () => {
                    this.error(this.toastr);
                },
            })
            .add(() => {
                this.spinnerService.hide('app-spinner');
            });
    }

    getTileDataForDashboard(): void {
        this.spinnerService.show('app-spinner');
        const tilesRequest = new TilesRequest(this.componentMap);
        this.chartsService
            .getDataForTiles(tilesRequest)
            .subscribe({
                next: (res: TilesRequest) => {
                    this.updateAllTiles(res);
                },
                error: () => {
                    this.error(this.toastr);
                },
            })
            .add(() => {
                this.spinnerService.hide('app-spinner');
            });
    }

    error(toastr: ToastrService) {
        toastr.error(
            'Please refresh the page or set filters to try again',
            'An error occured when loading the dashboard'
        );
    }

    updateAllTiles(tilesRequest: TilesRequest) {
        this.componentMap.forEach((value, key) => {
            const groupedCharts = tilesRequest.tiles.filter((char) =>
                char.dashboardItemRef.startsWith(key)
            );

            value.updateModel(...groupedCharts);
            this.spinnerService.hide('app-spinner');
        });
    }

    public openEditModal(tile: BaseTile) {
        let tileToEdit: BaseTile | undefined;
        this.componentMap.forEach((baseTile: BaseTile) => {
            if (baseTile === tile) {
                tileToEdit = baseTile;
            }
        });

        if (!tileToEdit) return;

        this.editOptions = tileToEdit.getEditOptions();
        this.showEdit = true;
    }

    public closeEditModal($event?: any) {
        this.editOptions = new EditTileOptions();
        this.showEdit = false;
    }

    applyEdit() {
        const selectedOption = this.editOptions.piOptions.find(
            (pi) => pi.isSelected
        )!;

        this.spinnerService.show('app-spinner');

        // dashboard id 5 is tenant satisfaction and is a combination tile but doesn't have a second chart choice
        // so wouldnt get picked up here normally
        if (
            this.editOptions.chartsTypes.length > 1 ||
            this.dashboardId === DashboardId.TenantSatisfaction ||
            this.dashboardId === DashboardId.TSM
        ) {
            this.updateGroupedTile(selectedOption);
        } else {
            this.updateSimpleTile(selectedOption);
        }
    }

    exportExcel() {
        const calcRefs = new Array<number>();

        this.componentMap.forEach((baseTile) => {
            baseTile.getComponentsInTile().forEach((tileMetadata) => {
                calcRefs.push(tileMetadata.calcRef);
            });
        });

        this.spinnerService.show('app-spinner');

        this.exportService
            .exportExcel('Export.xlsx', ...calcRefs)
            .subscribe(() => {
                this.spinnerService.hide('app-spinner');
            });
    }

    private updateGroupedTile(selectedOption: SelectableOption<number>) {
        const tr = new TilesRequest();
        const tileMetadatas = this.editOptions.chartsTypes.map(
            (cc: ComponentCharts) => {
                const tm = new TileMetaData();
                tm.calcRef = selectedOption.value;
                tm.dashboardItemRef = cc.dashboardItemRef;
                tm.chartType = ChartType[cc.selectedChartType];
                return tm;
            }
        );

        // we need to build up the info tile that always tops the grouped tile
        // we know that this will always have the same items ref as the other tiles but will end with a
        const infoTileMetaData = new TileMetaData();
        const dashboardItemRef = tileMetadatas[0].dashboardItemRef;
        infoTileMetaData.calcRef = selectedOption.value;
        infoTileMetaData.chartType = ChartType[ChartType.infoTile];
        infoTileMetaData.dashboardItemRef =
            dashboardItemRef.slice(0, dashboardItemRef.length - 1) + 'a';

        tileMetadatas.push(infoTileMetaData);
        tr.tiles = tileMetadatas;

        this.chartsService.getDataForTiles(tr).subscribe(
            (tilesRequest: TilesRequest) => {
                this.componentMap.forEach((value, key) => {
                    const groupedCharts = tilesRequest.tiles.filter((tile) =>
                        tile.dashboardItemRef.startsWith(key)
                    );

                    if (groupedCharts && groupedCharts.length > 0)
                        value.updateModel(...groupedCharts);
                });

                this.closeEditModal();
                this.spinnerService.hide('app-spinner');
            },
            () => {
                this.spinnerService.hide('app-spinner');
                this.toastr.error(
                    'Please try again',
                    'An error occurred whilst updating this chart'
                );
            }
        );
    }

    private updateSimpleTile(selectedOption: SelectableOption<number>) {
        const selectedChart = this.editOptions.chartsTypes[0];
        this.chartsService
            .getChartData(selectedChart.selectedChartType, selectedOption.value)
            .subscribe({
                next: (res: Options) => {
                    let tile: BaseTile | undefined;
                    this.componentMap.forEach(
                        (value: BaseTile, key: string) => {
                            if (
                                !tile &&
                                selectedChart.dashboardItemRef
                                    .toLowerCase()
                                    .startsWith(key.toLowerCase())
                            ) {
                                tile = value;
                            }
                        }
                    );

                    if (!tile) {
                        return;
                    }

                    const tmd = new TileMetaData();
                    tmd.dashboardItemRef = selectedChart.dashboardItemRef;
                    tmd.chartType = ChartType[selectedChart.selectedChartType];
                    tmd.calcRef = selectedOption.value;
                    tmd.data = res;
                    tile.updateModel(tmd);

                    this.closeEditModal();
                },
                error: () => {
                    this.toastr.error(
                        'Please try again',
                        'An error occurred whilst updating this chart'
                    );
                },
            })
            .add(() => {
                this.spinnerService.hide('app-spinner');
            });
    }

    public exportPdf() {
        this.spinnerService.show('app-spinner');

        this.exportService
            .exportDashboardPdf(
                this.dashboardName,
                this.portraitExport,
                this.dashboardHTMLId
            )
            .subscribe(() => {
                this.spinnerService.hide('app-spinner');
            });
    }
}
