<div class="navigation-menu" *ngIf="showTemplate">
    <span class="hm-icon-close top-x" (click)="hide()"></span>
    <div class="navigation-selection">
        <a (click)="navigate('/annual-summary-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Annual summary</span>
        </a>
        <a (click)="navigate('/responsive-repairs-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Responsive repairs and void works</span>
        </a>
        <a (click)="navigate('/tenant-satisfaction-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>STAR satisfaction</span>
        </a>
        <a *ngIf="!restrictNavigationItem()" (click)="navigate('/global-accounts-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Global accounts</span>
        </a>
        <a (click)="navigate('/vfm-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>VFM quadrant</span>
        </a>
        <a (click)="navigate('/bespoke-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Bespoke dashboard</span>
        </a>
        <a (click)="navigate('/esg-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Environmental, Social and Governance</span>
        </a>
        <a (click)="navigate('/tsm-dashboard')">
            <i class="fas fa-chevron-right"></i>
            <span>Tenant Satisfaction Measures</span>
        </a>
    </div>
</div>
