import { NgModule } from '@angular/core';
import { ChartTileComponent } from './chart-tile.component';
import { CommonModule } from '@angular/common';
import { ChartModule } from '../../../highcharts/chart.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        ChartTileComponent
    ],
    imports: [
        CommonModule,
        ChartModule,
        BrowserModule,
        FormsModule
    ],
    exports: [
        ChartTileComponent
    ]
})
export class ChartTileModule { }
