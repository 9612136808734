import { Component, OnInit } from '@angular/core';
import { PeersService } from '../../../../services/peers.service';
import { PeerGroup } from '../../../../models/peer-group';
import { PeerGroupType } from '../../../../models/enum/peer-group-type';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../../../../services/navigation.service';
import { PeerDateRange, DateOption } from '../../../../models/peer-filters/peer-date-range';
import { SectionData } from '../../../../models/peer';
import * as _ from 'lodash';
import { ExportService } from '../../../../services/export.service';
import { JsReportService } from '../../../../services/js-report.service';
import { SpinnerService } from '../../../spinner/spinner.service';
import { first } from 'rxjs';

@Component({
    selector: 'bespoke-peer-group',
    templateUrl: './bespoke-peer-group.component.html'
})
export class BespokePeerGroupComponent implements OnInit {
    peers: PeerGroup[] = [];
    includedPeerCount: number;
    excludedPeerCount: number;
    filterText: string;
    sortCriteria = '';
    dateSelectorMap: Map<number, PeerDateRange>;
    filterCurrentlySelectedPeers = true;
    retainFilters = false;

    constructor(private peersService: PeersService, private toastr: ToastrService, private navigationService: NavigationService, private exportService: ExportService,
        private spinnerService: SpinnerService, private jsReportService: JsReportService) {
    }

    ngOnInit(): void {
        this.peers = this.peersService.peers;
        this.initialiseDateSelectorMap();

        this.peersService.peersChanged.subscribe(() => {
            if (this.peers) {
                this.sortCriteria = 'includedInGroup';
                this.changeDateSelectionsBasedOnPeerSelection();
                this.sort();
                this.countPeers();
            }
        });
    }

    public changeDateSelectionsBasedOnPeerSelection(): void {
        if (this.peersService.peerGroupType === PeerGroupType.Dynamic) this.retainFilters = false;

        if (this.retainFilters) return;

        const includedPeers = this.peersService.masterPeerList.filter(peer => peer.includedInGroup);

        const flatSections = _.flatten(includedPeers.map(peer => peer.sections));
        const uniqueSections = _.uniqWith(flatSections, (left: SectionData, right: SectionData) => left.infoComponentId === right.infoComponentId && left.periodWKey === right.periodWKey);
        const groupedSections = _.groupBy(uniqueSections, (section: SectionData) => section.infoComponentId) as { property: SectionData[] };

        for (const key in groupedSections) {
            if (groupedSections.hasOwnProperty(key)) {
                const sectionDataArr = groupedSections[key] as SectionData[];
                const sectionDropdown = this.dateSelectorMap.get(+key);

                if (sectionDropdown) {
                    sectionDropdown.selectedFrom = _.minBy(sectionDataArr, 'periodWKey').periodWKey;
                    sectionDropdown.selectedTo = _.maxBy(sectionDataArr, 'periodWKey').periodWKey;
                }
            }
        }
    }

    private initialiseDateSelectorMap(): void {
        this.dateSelectorMap = new Map<number, PeerDateRange>();

        const sectionsSections = this.peers.map(peer => peer.sections);

        this.buildUniqueSections(sectionsSections);

        this.dateSelectorMap.forEach(value => {
            value.dateOptions = _.orderBy(value.dateOptions, ['value'], ['desc']);
            value.selectedFrom = value.dateOptions.length > 0 ? value.dateOptions[value.dateOptions.length - 1].value : 0;
            value.selectedTo = value.dateOptions.length > 0 ? value.dateOptions[0].value : 0;
        });
    }

    private buildUniqueSections(sectionsSections: SectionData[][]) {
        let sections = _.flatten(sectionsSections) as SectionData[];

        sections = _.uniqWith(sections, (left: SectionData, right: SectionData) => left.infoComponentId === right.infoComponentId && left.periodWKey === right.periodWKey);

        sections = _.orderBy(sections, ['infoComponentId'], ['asc']);

        sections.forEach(section => {
            let dateRange = this.dateSelectorMap.get(section.infoComponentId);
            if (!dateRange) {
                dateRange = new PeerDateRange(section.infoComponentId, section.infoComponentName);
                this.dateSelectorMap.set(section.infoComponentId, dateRange);
            }
            if (!dateRange.dateOptions.some(option => option.value === section.periodWKey)) {
                const dateOption = new DateOption();
                dateOption.value = section.periodWKey;
                dateOption.text = section.benchmarkYearData;
                dateRange.dateOptions.push(dateOption);
            }
        });
    }

    apply(closeWindow: boolean): void {
        if (this.includedPeerCount === 0) return;

        this.peersService.setOrganisationFilters(PeerGroupType.Bespoke);
        this.toastr.success('These peers have been applied to your charts', 'Success!');
        if (closeWindow) this.close();
    }

    close(): void {
        this.navigationService.toggleComponentByName('PeerSelectionComponent');
    }

    reset(): void {
        this.peersService.resetFilters();
        this.peers = this.peersService.peers;
        this.countPeers();
    }

    filterOrgs(filter: string) {
        this.peers.forEach((peer: PeerGroup) => {
            peer.isVisibleInList = !filter || filter.length < 3 || peer.organisationName.toLowerCase().includes(filter.toLowerCase());
        });
    }

    countPeers() {
        this.includedPeerCount = 0;
        this.excludedPeerCount = 0;

        for (let i = 0; i < this.peers.length; i++) {
            this.peers[i].includedInGroup ? this.includedPeerCount++ : this.excludedPeerCount++;
        }
    }

    setSortCriteria(option: string) {
        if (this.sortCriteria === '') {
            this.sortCriteria = option;
        } else if (this.sortCriteria.includes(option)) {
            if (this.sortCriteria.includes('-')) {
                this.sortCriteria = '';
            } else {
                this.sortCriteria = '-' + option;
            }
        } else {
            this.sortCriteria = option;
        }

        this.sort();
    }

    sort() {
        if (this.sortCriteria === '') {
            this.sortByName();
            return;
        }

        let sortByAscending = false;
        if (this.sortCriteria.includes('-')) {
            sortByAscending = true;
        }

        if (this.sortCriteria.includes('peerName')) {
            this.sortByName();
        } else if (this.sortCriteria.includes('includedInGroup')) {
            this.peers = this.peers.sort((peerLeft, peerRight): number => {
                if (peerLeft.includedInGroup === peerRight.includedInGroup) return this.makeNameDecision(peerLeft, peerRight);
                else if (peerLeft.includedInGroup && !peerRight.includedInGroup) return -1;
                else return 1;
            });
        } else if (this.sortCriteria.includes('region')) {
            this.peers = this.peers.sort((peerLeft, peerRight): number => {
                if (peerLeft.organisationRegion > peerRight.organisationRegion) return -1;
                else if (peerLeft.organisationRegion < peerRight.organisationRegion) return 1;
                else return this.makeNameDecision(peerLeft, peerRight);
            });
        }

        if (sortByAscending) {
            this.peers.reverse();
        }
    }

    sortByName() {
        this.peers = this.peers.sort((peerLeft, peerRight): number => {
            return this.makeNameDecision(peerLeft, peerRight);
        });
    }

    makeNameDecision(peerLeft: PeerGroup, peerRight: PeerGroup): number {
        if (peerLeft.organisationName < peerRight.organisationName) return -1;
        else if (peerLeft.organisationName > peerRight.organisationName) return 1;
        return 0;
    }

    toggleAllPeers() {
        if (this.peers.every(peer => peer.includedInGroup)) {
            this.peers.forEach(peer => peer.includedInGroup = false);
        } else {
            this.peers.forEach(peer => peer.includedInGroup = true);
        }

        this.countPeers();
    }

    areAllPeersChecked(): boolean {
        if (this.peers) return this.peers.every(peer => peer.includedInGroup);

        return false;
    }

    resetBespoke() {
        this.peersService.resetFilters();
    }

    togglePeer(peer: PeerGroup) {
        this.retainFilters = false;
        peer.includedInGroup = !peer.includedInGroup;
        this.countPeers();
    }

    filtersValid(): boolean {
        if (!this.dateSelectorMap) return false;
        let filtersAreValid = true;

        this.dateSelectorMap.forEach(dateRange => {
            if (dateRange.error) filtersAreValid = false;
        });

        return filtersAreValid;
    }

    anyFiltersEnabled(): boolean {
        let filtersAreEnabled = false;
        this.dateSelectorMap.forEach(dateRange => {
            if (!dateRange.disabled) filtersAreEnabled = true;
        });

        return filtersAreEnabled;
    }

    setFilters() {
        const filtersAreValid = this.filtersValid() && this.anyFiltersEnabled();

        if (!filtersAreValid) return;

        this.retainFilters = true;
        // if this current filter is dynamic then filter on the existing selected peers
        // otherwise filter out all of the peers
        if (this.filterCurrentlySelectedPeers) this.setFiltersOnAppliedPeerGroup();
        else this.setFiltersBasedOnAllPeers();

        this.countPeers();
        this.sortCriteria = 'includedInGroup';
        this.sort();
    }

    resetDateFilters() {
        this.retainFilters = false;
        this.changeDateSelectionsBasedOnPeerSelection();
    }

    setFiltersOnAppliedPeerGroup() {
        for (let i = 0; i < this.peersService.masterPeerList.length; i++) {
            const masterPeer = this.peersService.masterPeerList[i];
            const associatedPeer = this.peersService.peers.find(peer => peer.organisationWkey === masterPeer.organisationWkey)!;

            associatedPeer.includedInGroup = masterPeer.includedInGroup;

            if (associatedPeer.includedInGroup) {
                this.setGroupInclusionBasedOnDateFilters(associatedPeer);
            }
        }
    }

    setGroupInclusionBasedOnDateFilters(peer: PeerGroup) {
        const groupedSections = _.groupBy(peer.sections, (section: SectionData) => section.infoComponentId) as { property: SectionData[] };

        this.dateSelectorMap.forEach((dateSection: PeerDateRange, key: number) => {
            const peerSections = groupedSections[key];

            if (dateSection.disabled) return;

            if (!peerSections || !peerSections.find((peerSection: SectionData) => peerSection.periodWKey >= dateSection.selectedFrom && peerSection.periodWKey <= dateSection.selectedTo)) {
                peer.includedInGroup = false;
            }
        });
    }

    setFiltersBasedOnAllPeers() {
        this.peers.forEach(peer => {
            peer.includedInGroup = true;
            this.setGroupInclusionBasedOnDateFilters(peer);
        });
    }

    validateSelection(peerDateRange: PeerDateRange) {
        peerDateRange.error = (+peerDateRange.selectedFrom) > (+peerDateRange.selectedTo);
    }

    public downloadPeerList(): void {
        this.spinnerService.show('app-spinner');
        const fileName = 'PeerListExport';

        if(!this.peersService.currentFilter) return;

        this.exportService.exportPeersListAsXls(this.peersService.currentFilter.id, fileName).subscribe((res: Blob) => {
            this.spinnerService.hide('app-spinner', true);

            this.jsReportService.startDownload(fileName, res);
        }, (error) => this.spinnerService.hide('app-spinner', true));
    }
}
