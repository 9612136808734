import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { LayoutService } from '../../../services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { ChartsService } from '../../../services/charts.service';
import { PeersService } from '../../../services/peers.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { SmallChartTileComponent } from '../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'quarterly-performance-dashboard',
    templateUrl: './quarterly-performance.component.html'
})
export class QuarterlyPerformanceDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.QuarterlyPerformance;
    dashboardHTMLId = 'quarterly-performance-dashboard-body';
    portraitExport = false;

    @ViewChild('QPDashChart1') leftTile1!: SmallChartTileComponent;
    @ViewChild('QPDashChart2') leftTile2!: SmallChartTileComponent;
    @ViewChild('QPDashChart3') leftTile3!: SmallChartTileComponent;
    @ViewChild('QPDashChart4') rightTile1!: SmallChartTileComponent;
    @ViewChild('QPDashChart5') rightTile2!: SmallChartTileComponent;
    @ViewChild('QPDashChart6') rightTile3!: SmallChartTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }
    
    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['QPDashChart1', this.leftTile1],
            ['QPDashChart2', this.leftTile2],
            ['QPDashChart3', this.leftTile3],
            ['QPDashChart4', this.rightTile1],
            ['QPDashChart5', this.rightTile2],
            ['QPDashChart6', this.rightTile3]
        ]);

        this.Init();
    }

}
