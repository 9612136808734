import { Activity } from './activities';

export class ActivityListItem extends Activity {
    selected: boolean;
    isEnabled: boolean;
    isVisible: boolean;
    activityName: string;

    get isSelected(): boolean {
        return this.isEnabled ? this.selected : false;
    }

    set isSelected(selected: boolean) {
        if (this.isEnabled && this.selected !== selected) {
            this.selected = selected;
        }
    }

    constructor(activity: Activity) {
        super();

        this.selected = false;
        this.isVisible = true;

        if (activity) {
            this.activityName = activity.name;
            this.name = activity.activityShortCode + ' - ' + activity.name;
            this.isEnabled = activity.noDataMessage === '';
            this.noDataMessage = activity.noDataMessage;
            this.activityShortCode = activity.activityShortCode;
            this.sectionName = activity.sectionName;
        }
    }
}
