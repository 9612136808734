import { AnalyseTileSection } from './analyse-tile-section';
import { EditAnalyseTileComponent } from './edit-analyse-tile-component';
import { HideableOption } from '../options/hideable-option';
import { EditModalSection } from '../dashboard/bespoke/edit-modal-section';

export class EditAnalyseTileSection extends EditModalSection {
    components: EditAnalyseTileComponent[];

    constructor(ats?: AnalyseTileSection) {
        super();
        
        if (ats) {
            this.function = ats.function;
            this.components = ats.components.map(comp => new EditAnalyseTileComponent(comp));

            this.piOptions = ats.components.map(val => {
                return new HideableOption(false, val.name, val.id, true, val.noDataMessage);
            });
        }
    }
}
