<div class="tile tile-height-5">
    <div class="dual-chart-tile combo-tile icon-hover">
        <div class="icons-hover-wrapper icon-hover-left">
            <div class="icons-hover-wrapper-inner top">
                <i class="far fa-edit" (click)="openEdit()"></i>
            </div>
        </div>
        <div class="tile-portion tile-summary small-icon-tile" [class.no-data]="infoTileChart.quartileClass === 'no-data'" *ngIf="infoTile && infoTileChart">
            <small-icon-info-tile [infoTile]="infoTileChart" [displayDifference]="false"></small-icon-info-tile>
        </div>

        <ng-container *ngIf="infoTileChart?.quartileClass !== 'no-data'">
            <div class="tile-portion tile-first-chart">
                <highcharts-chart [chart]="chartData" *ngIf="chartTile && chartData" class="tile-chart"></highcharts-chart>
            </div>
        </ng-container>
    </div>
</div>