import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SelectableOption } from '../../models/options/selectable-option';

@Component({
    selector: 'checkbox-options',
    templateUrl: './checkbox-options.component.html'
})
export class CheckboxOptionsComponent implements OnInit, OnChanges {
    @Input() options!: SelectableOption<number | string>[];

    constructor() { }

    ngOnInit() {
        if (this.options === undefined) this.options = new Array<SelectableOption<number>>();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges['options'] && this.options) {
            if (this.options.findIndex(value => value.text.replace(' ', '').toLocaleLowerCase() === 'all') === -1) {
                this.options.splice(0, 0, new SelectableOption<number>(true, 'All', 0));
            }
        }
    }

    checkboxChanged(option: any) {
        if (option.text !== 'All') {
            if (option.isSelected === false) {
                this.options.find(op => op.text === 'All')!.isSelected = false;
            } else if (this.options.filter(op => op.text !== 'All').every(op => op.isSelected)) {
                this.options.forEach(op => op.isSelected = true);
            }
        } else if (option.isSelected) {
            this.options.forEach(op => op.isSelected = true);
        } else {
            this.options.forEach(op => op.isSelected = false);
        }
    }
}
