import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { BaseNavigationComponent } from '../shared/base-navigation/base-navigation';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OrganisationService } from '../../services/organisation.service';
import { Organisation } from '../../models/organisation';
import { LayoutType } from '../../models/enum/layout-type';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export enum MyAccountTabType {
    PeerGroup,
    DashboardLayout,
    ReportLayout,
    Admin
}

@Component({
    selector: 'my-account',
    templateUrl: './my-account.component.html'
})
export class MyAccountComponent extends BaseNavigationComponent implements OnInit {
    organisation?: Organisation;
    myAccountTab = MyAccountTabType;
    LayoutType = LayoutType;
    currentlyOpenTab = MyAccountTabType.PeerGroup;
    showAdminPage!: Observable<boolean>;

    constructor(navigationService: NavigationService, private oidcSecurityService: OidcSecurityService,
                organisationService: OrganisationService, private appInsightsService: ApplicationInsights) {
        super(navigationService, 'MyAccountComponent', organisationService);
    }

    ngOnInit() {
        this.organisation = this.organisationService.currentLandlord;

        this.organisationService.landlordChanged.subscribe(() => {
            this.organisation = this.organisationService.currentLandlord;
        });

        this.showAdminPage = this.oidcSecurityService.getUserData().pipe(
            map(ud => ud.organization === "housemark")
        );
    }

    setPeerGroupTabToShow(tabType: MyAccountTabType) {
        this.currentlyOpenTab = tabType;
    }

    isTabActive(tabType: MyAccountTabType) {
        return this.currentlyOpenTab === tabType;
    }

    closePage() {
        this.navigationService.closeAllTabs();
    }

    signout() {
        this.appInsightsService.clearAuthenticatedUserContext();
        this.oidcSecurityService.logoff();
    }
}
