<div class="filters">
    <input id="layout-search" type="search" placeholder="Search layouts" [(ngModel)]="searchTerm" (keyup)="filterList()"
        (blur)="filterList()" />
</div>

<div class="table-wrapper">
    <div class="table-header" [class.admin-view]="isAdmin">
        <div id="load-layout" (click)="setSortCriteria('name')">Load layout
            <i *ngIf="sortCriteria == 'name'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-name'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('name')" class="fas fa-sort"></i>
        </div>
        <div id="layout-type" (click)="setSortCriteria('type')">{{LayoutType[type]}} type
            <i *ngIf="sortCriteria == 'type'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-type'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('type')" class="fas fa-sort"></i>
        </div>
        <div id="layout-creation-date" (click)="setSortCriteria('creationdate')">Creation date
            <i *ngIf="sortCriteria == 'creationdate'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-creationdate'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('creationdate')" class="fas fa-sort"></i>
        </div>
        <div id="edit-delete">
        </div>
        <div id="set-as-default" *ngIf="isAdmin">
            Set as default
        </div>
    </div>
    <div class="table-body" *ngIf="groupedItems">
        <div *ngFor="let group of groupedItems | keyvalue">
            <div class="table-row" *ngFor="let layout of group.value" [class.hide]="!layout.isVisible"
                [class.admin-view]="isAdmin">
                <div class="name" (click)="loadItem(layout)">
                    <i *ngIf="!layout.isOwnedByUser" class="fas fa-lock" ngbTooltip="This option can't be amended or deleted as it is either the HouseMark default option or the default set by your local administrator">
                    </i>
                    <i class="far fa-folder-open"></i>{{layout.name}}
                </div>
                <div class="type">{{layout.layoutName}}</div>
                <div class="date">
                    <ng-container *ngIf="layout.creationDate">
                        {{layout.creationDate | date : 'dd/MM/YYYY'}}
                    </ng-container>
                </div>
                <div class="edit">
                    <ng-container *ngIf="layout.isOwnedByUser  && !layout.isHouseMarkDefinedDefault">
                        <i class="fas fa-pencil-alt" (click)="showEditModal(layout)"></i>
                        <i class="far fa-trash-alt" (click)="showDeleteModal(layout)"></i>
                    </ng-container>
                </div>
                <div class="default" *ngIf="isAdmin">
                    <button *ngIf="layout.isDefault" disabled>Default <i class="hm-icon-tick"></i></button>
                    <button *ngIf="!layout.isDefault" (click)="setAsDefault(layout)">Set as default</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="manage-layouts-edit-modal simple-modal" id="manage-layouts-edit-modal" *ngIf="showEdit">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Rename</h4>
                </div>
                <div class="body">
                    <span>Edit the name of the saved layout</span>

                    <input [(ngModel)]="editName" type="search" />
                    <div *ngIf="itemAlreadyExists()" class="item-already-exists">A layout with this name already
                        exists!</div>


                    <div class="button-wrapper">
                        <button [class.disabled]="saveButtonDisabled()" (click)="saveItem()">Save</button>
                        <button class="dark-button" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="manage-layouts-delete-modal simple-modal" id="manage-layouts-delete-modal" *ngIf="showDelete">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Do you want to delete this layout</h4>
                </div>
                <div class="body">
                    <span>It will be permanently removed. You can recreate this layout at a later date</span>

                    <div class="button-wrapper">
                        <button (click)="deleteItem()">Delete</button>
                        <button class="dark-button" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>