import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Input } from '@angular/core';
import { EditAnalyseTileSection } from '../../../models/analyse/edit-analyse-tile-section';
import { Observable } from 'rxjs';
import { EditModalSection } from '../../../models/dashboard/bespoke/edit-modal-section';

@Component({
    selector: 'sectioned-component-options',
    templateUrl: './sectioned-component-options.component.html'
})
export class SectionedComponentOptionsComponent implements OnInit {
    @Input() availableSections!: EditModalSection[];
    @Input() group!: string;
    @Input() searchEvent!: Observable<string>;
    @Output() selectionChanged: EventEmitter<number> = new EventEmitter<number>();
    
    hiddenSections = new Set<string>();

    constructor(private changedetector: ChangeDetectorRef) {
        
    }
    ngOnInit(): void {
        this.availableSections.forEach(as => {
            this.hiddenSections.add(as.function);
        });

        this.searchEvent.subscribe((searchString: string) => {
            this.availableSections.forEach((section: EditModalSection) => {
                if(this.allComponentsHidden(section.function) || searchString.length < 3) {
                    this.hiddenSections.add(section.function);
                } else {
                    this.hiddenSections.delete(section.function);
                }
            });

            this.changedetector.detectChanges();
        })
    }


    update(id: number) {
        this.selectionChanged.emit(id);
    }

    shouldHideSection(section: string): boolean {
        return this.hiddenSections.has(section);
    }

    toggleSection(section: string) {
        if (this.hiddenSections.has(section)) this.hiddenSections.delete(section);
        else this.hiddenSections.add(section);
    }

    allComponentsHidden(section: string): boolean {
        return this.availableSections.find(sect => sect.function === section)!.piOptions.every(val => !val.isVisible);
    }

}
