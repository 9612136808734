import { Injectable } from '@angular/core';
import { PeersService } from './peers.service';
import { OrganisationService } from './organisation.service';
import { DateRangeService } from './date-range.service';
import { HeadersService } from './headers.service';
import { APIData } from '../models/api-data';

@Injectable()
export class CombinedStreamService {

    constructor(private peersService: PeersService, private organisationSevice: OrganisationService,
        private dateRangeService: DateRangeService, private headersService: HeadersService) {
    }

    getAPIParamsAndHeaderValues(): APIData {
        const apiData = new APIData();
        if (this.peersService.currentFilter) apiData.filterId = this.peersService.currentFilter.id;
        if (this.dateRangeService.currentSections) apiData.periods = this.dateRangeService.currentSections.getPeriods();
        if (this.organisationSevice.currentLandlord) {
            apiData.organisationWKey = this.organisationSevice.currentLandlord.wKey;
            apiData.hasAccessToCore = this.organisationSevice.currentLandlord.hasAccessToCore;
        }

        apiData.headers = this.headersService.getHeaders();

        return apiData;
    }
}
