import { Injectable } from '@angular/core';
import { HeadersService } from './headers.service';
import { HttpClient } from '@angular/common/http';
import { Section, SectionsViewModel, SectionViewModel } from '../models/section';
import { OrganisationService } from './organisation.service';
import { SpinnerService } from '../components/spinner/spinner.service';
import { SelectableOption } from '../models/options/selectable-option';
import { merge, Observable, Subject } from 'rxjs';

@Injectable()
export class DateRangeService {
    private _viewModel: SectionsViewModel;
    private _sections: Section[];
    dateRangeChanged = new Subject<void>();
    dateRangeLoaded = new Subject<void>();
    dateRangeLoadedOrChanged = new Observable<void>();

    url = 'api/Periods/All';

    constructor(private headersService: HeadersService, private httpClient: HttpClient, organisationService: OrganisationService,
        private spinnerService: SpinnerService) {
        organisationService.landlordChanged.subscribe(() => {
            this.fetchDateRanges(organisationService.currentLandlord!.wKey, false);
        });

        this.dateRangeLoadedOrChanged = merge(this.dateRangeChanged,this.dateRangeLoaded);
    }

    public fetchDateRanges(orgWKey: number, showSpinner = true) {
        const headers = this.headersService.getHeaders();
        const url = `/api/Sections/ById/${orgWKey}`;

        if(showSpinner) this.spinnerService.show('app-spinner');
        
        this.httpClient.get<Section[]>(url, { headers: headers }).subscribe({
            next: (sections: Section[]) => {
                this._sections = sections;
                this._viewModel = new SectionsViewModel(sections);
                this.dateRangeLoaded.next();

                if(showSpinner) this.spinnerService.hide('app-spinner');

            }, error: () => {
            this.spinnerService.hide('app-spinner');
        }});
    }

    get currentSections(): SectionsViewModel {
        return this._viewModel;
    }

    public getDateRangeHeaderDisplayText(): string {
        if (this._viewModel) {
            const allDatesAreLatestPossibleDates = this._viewModel.sections.every((section: SectionViewModel) => {
                const selectedOption = section.options.find((option: SelectableOption<number>) => option.isSelected);

                return section.options.indexOf(selectedOption!) === 0;
            });

            return allDatesAreLatestPossibleDates ? ' Most recent data' : 'Custom date range';
        }

        return 'Loading dates';
    }

}
