import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { LayoutService } from '../../../services/layout.service';
import { ChartsService } from '../../../services/charts.service';
import { CombinedStreamService } from '../../../services/combined-stream.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { ExportService } from '../../../services/export.service';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { DualChartTileComponent } from '../../tiles/dashboard-tiles/dual-chart-tile/dual-chart-tile.component';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'tenant-satisfaction-dashboard',
    templateUrl: './tenant-satisfaction-dashboard.component.html'
})
export class TenantSatisfactionDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.TenantSatisfaction;
    dashboardHTMLId = 'tenant-satisfaction-dashboard-body';
    portraitExport = true;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    @ViewChild('TSDashChart1') leftTile1!: DualChartTileComponent;
    @ViewChild('TSDashChart2') leftTile2!: DualChartTileComponent;
    @ViewChild('TSDashChart3') leftTile3!: DualChartTileComponent;
    @ViewChild('TSDashChart4') rightTile1!: DualChartTileComponent;
    @ViewChild('TSDashChart5') rightTile2!: DualChartTileComponent;
    @ViewChild('TSDashChart6') rightTile3!: DualChartTileComponent;

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['TSDashChart1', this.leftTile1],
            ['TSDashChart2', this.leftTile2],
            ['TSDashChart3', this.leftTile3],
            ['TSDashChart4', this.rightTile1],
            ['TSDashChart5', this.rightTile2],
            ['TSDashChart6', this.rightTile3]
        ]);

        this.Init();
    }

}
