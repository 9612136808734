<div class="container-fluid peer-selection sidebar-component" [class.hide]="!showTemplate">
    <div class="row">
        <div class="col-12">
            <div class="header">
                <div class="top-row">
                    <h1>Peer selection</h1>
                    <i class="hm-icon-close top-x" (click)="hide()"></i>
                </div>

                <div class="second-row">
                    <div class="peer-group-info">
                        <div>
                            <div class="faded-text">Current Peer Group <i placement="right" ngbTooltip="This is the name of the peer group currently applied. You can manage your saved peer groups in the ‘my account’ area"
                                    class="hm-icon-help"></i></div>
                            <div>{{getPeerGroupName()}} </div>
                        </div>
                        <div class="peers-in-group">
                            <div class="faded-text"> Peers In Group <i placement="right" ngbTooltip="This is the total number of potential organisations in your current peer group. Note that the sample for an individual PI may be lower either because data has not yet been received for some of these peers, or because they have opted out of some modules"
                                    class="hm-icon-help"></i></div>
                            <div>{{peerGroupCount}}</div>
                        </div>
                    </div>
                    <div class="load-save-buttons">
                        <div class="load" (click)="showLoadModal = true"><i class="hm-icon-load"></i>Load group </div>
                        <div class="save" (click)="openSaveOrDiscardModal()"><i class="hm-icon-save"></i> Save group </div>
                    </div>
                </div>

                <ul class="nav nav-tabs">
                    <li class="nav-item dynamic-peer-group" id="dynamic-tab" (click)="changeTab('dynamic')">
                        <span class='nav-link'>Filter peers</span>
                    </li>
                    <li class="nav-item bespoke-peer-group" id="bespoke-tab" (click)="changeTab('bespoke')">
                        <span class='nav-link'>Add / remove peers</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row peer-selection-body" [class.show-bespoke]="showBespoke" id="peer-selection-body">
        <div class="col-lg-12">
            <div [class.hide]="showBespoke">
                <dynamic-peer-group></dynamic-peer-group>
            </div>
            <div [class.hide]="!showBespoke">
                <bespoke-peer-group></bespoke-peer-group>
            </div>
        </div>
    </div>
</div>

<div class="discard-change-modal simple-modal" *ngIf="displayDiscardModal">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>You have changes that aren't applied</h4>
                </div>
                <div class="body">
                    <p>You have made changes to your peer group but have not applied those changes yet.</p>
                    <p> The system is designed for you to apply filters first, and then optionally make manual amends by adding / removing peers from the shortlist as required.</p>
                    <p>Changes made to filters after you have manually added / removed peers may override the manual amends.</p>
                    <p>What do you want to do? </p>
                    <div class="button-wrapper">
                        <button (click)="applyAndOpenSaveModal()">Save</button>
                        <button (click)="apply(true)">Apply changes without saving</button>
                        <button class="white-button-bordered" (click)="reset()">Discard changes</button>
                        <button class="white-button-bordered" (click)="displayDiscardModal = false">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<simple-load-item-modal *ngIf="showLoadModal" [items]="filterItems" [context]="'peer group'" (closeModal)="showLoadModal = false"
    (load)="loadFilter()"></simple-load-item-modal>

<simple-save-item-modal *ngIf="showSaveModal" [items]="filterItems" [context]="'peer group'" (closeModal)="showSaveModal = false"
    (save)="saveFilter($event)"></simple-save-item-modal>