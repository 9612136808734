import { Pipe, PipeTransform } from '@angular/core';
import { CanHideItemInList } from '../../interfaces/is-visible-in-list';

@Pipe({
    name: 'IsVisibleInList',
    pure: false
})
export class IsVisibleInListPipe<T extends CanHideItemInList> implements PipeTransform {

    transform(values: T[]): T[] {
        if (!values) {
            return values;
        }

        return values.filter((value: T) => value.isVisibleInList);
    }

}
