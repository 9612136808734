<div class="navigation-menu" *ngIf="showTemplate">
    <i class="hm-icon-close top-x" (click)="hide()"></i>
    <div class="organisation-selection navigation-selection">
        <div class="select-search">
            <h1>Select Organisation</h1>
            <input type="search" #OrgSearchBox placeholder="Search organisations" (keyup.enter)="selectIfOnlyOneLandlord()" [(ngModel)]="searchTerm" (ngModelChange)="searchList($event)" class="search-box rounded-borders">
        </div>

        <div class="client-list">
            <div class="client-item" [class.hide]="!organisation.isVisibleInList" [class.selected]="isOrganisationSelected(organisation.wKey)"
                *ngFor="let organisation of organisations" (click)="changeOrganisation(organisation.wKey)">
                {{organisation.name}}
            </div>
        </div>
    </div>
</div>