import { Component, ElementRef, ViewChild, Input, EventEmitter, Output, Type, AfterViewInit } from '@angular/core';
import { BaseTile } from '../base-tile/base-tile';
import { Tile } from '../../../../models/dashboard/tile';
import { Chart } from '../../../../highcharts/chart';
import { TileMetaData } from '../../../../models/tile-metadata';
import { EditTileOptions } from '../../../../models/edit-tile-options';
import { AvailablePi } from '../../../../models/dashboard/available-pi';
import { ComponentCharts } from '../../../../models/component-charts';
import { HideableOption } from '../../../../models/options/hideable-option';
import { ChartType } from '../../../../models/enum/chart-type';
import { Coordinates } from '../../../../models/coordinates';
import { BaseBespokeDashboardTile } from '../../../../interfaces/bespoke-dashboard-tile';

@Component({
    selector: 'dashboard-section',
    templateUrl: './dashboard-section.component.html'
})
export class DashboardSectionComponent extends BaseBespokeDashboardTile {
    Type = DashboardSectionComponent;
    costTile!: Tile;
    costChart?: Chart;
    performanceTile!: Tile;
    performanceChart?: Chart;
    @Input() sectionName: string = '';
    @Input() index: string = '';
    @Output() modelUpdated: EventEmitter<DashboardSectionComponent> = new EventEmitter<DashboardSectionComponent>();
    @Output() override openEditEvent: EventEmitter<BaseTile> = new EventEmitter<BaseTile>();

    @ViewChild('SectionContent') sectionContent?: ElementRef;
    
    chartToBeEdited: string = '';
    noCostData: boolean = false;
    costTitle: string = '';
    noPerformanceData: boolean = false;
    performanceTitle: string = '';

    constructor() {
        super();
    }

    public getComponentsInTile(): Array<TileMetaData> {
        const costTileMetaData = new TileMetaData();
        costTileMetaData.chartType = this.costTile.selected.selectedKpiChartType;
        costTileMetaData.calcRef = this.costTile.selected.calcRef;
        costTileMetaData.dashboardItemRef = this.costTile.dashboardItemRef;

        const performanceTileMetaData = new TileMetaData();
        performanceTileMetaData.chartType = this.performanceTile.selected.selectedKpiChartType;
        performanceTileMetaData.calcRef = this.performanceTile.selected.calcRef;
        performanceTileMetaData.dashboardItemRef = this.performanceTile.dashboardItemRef;

        return new Array<TileMetaData>(costTileMetaData, performanceTileMetaData);
    }

    public initializeModel(...model: Array<Tile>) {
        if (model && model.length > 0) {
            this.costTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('a'))!;

            if (this.costTile.data) {
                this.costChart = new Chart(this.costTile.data);
                this.costTitle = this.costTile.data.title!.text!;
                this.noCostData = this.costTile.data.noDataAvailable === true;
            }

            
            this.performanceTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('b'))!;

            if(this.performanceTile.data) {
                this.performanceChart = new Chart(this.performanceTile.data);
                this.performanceTitle = this.performanceTile.data.title!.text!;
                this.noPerformanceData = this.performanceTile.data.noDataAvailable === true;
            }
            
            this.modelUpdated.emit(this);
        }
    }

    public updateModel(...tileData: Array<TileMetaData>) {
        const costData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('a'));
        const perfData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('b'));

        if (costData) {
            const selected = this.costTile.available.find(available => available.calcRef === costData.calcRef);

            if (selected) {
                this.costTile.selected = selected;
                this.costTile.selected.selectedKpiChartType = costData.chartType;

                this.noCostData = costData.data.noDataAvailable === true;
                this.costTitle = costData.data.title!.text!;
                this.costTile.data = costData.data;
                this.costChart = new Chart(costData.data);
            }
        }

        if (perfData) {
            const selected = this.performanceTile.available.find(available => available.calcRef === perfData.calcRef);

            if (selected) {
                this.performanceTile.selected = selected;
                this.performanceTile.selected.selectedKpiChartType = perfData.chartType;

                this.performanceTile.data = perfData.data;
                this.performanceTitle = perfData.data.title!.text!;
                this.noPerformanceData = perfData.data.noDataAvailable;
                this.performanceChart = new Chart(perfData.data);
            }
        }

        this.modelUpdated.emit(this);
    }

    public openEditWith(chartToBeEdited: string) {
        this.chartToBeEdited = chartToBeEdited;

        this.openEdit();
    }

    public getEditOptions(): EditTileOptions {
        if (this.chartToBeEdited === 'cost') return this.buildEditTileOptions(this.costTile);

        return this.buildEditTileOptions(this.performanceTile);
    }

    public buildEditTileOptions(chartTile: Tile): EditTileOptions {
        const editOptions = new EditTileOptions();

        editOptions.piOptions = chartTile.available.map((availableChart: AvailablePi, index: number) => {
            const isSelected = availableChart.calcRef === chartTile.selected.calcRef;
            return new HideableOption<number>(isSelected, availableChart.name, availableChart.calcRef, true, availableChart.noDataMessage);
        });

        const componentCharts = new ComponentCharts();
        componentCharts.chartTypes = chartTile.selected.availableChartTypes.split(', ').map(act => ChartType[act]);
        componentCharts.selectedChartType = ChartType[chartTile.selected.selectedKpiChartType];
        componentCharts.dashboardItemRef = chartTile.dashboardItemRef;

        editOptions.chartsTypes.push(componentCharts);

        return editOptions;
    }

    public toggleSection() {
        if(!this.sectionContent) return;

        const element = this.sectionContent.nativeElement as HTMLElement;

        if (element.classList.contains('hide')) element.classList.remove('hide');
        else element.classList.add('hide');
    }

    public openSection() {
        if(!this.sectionContent) return;

        const element = this.sectionContent.nativeElement as HTMLElement;
        if (element.classList.contains('hide')) element.classList.remove('hide');
    }

    public closeSection() {
        if(!this.sectionContent) return;

        const element = this.sectionContent.nativeElement as HTMLElement;
        if (!element.classList.contains('hide')) element.classList.add('hide');
    }

    public isSectionHidden(): boolean {
        if(!this.sectionContent) return true;

        const element = this.sectionContent.nativeElement as HTMLElement;

        if (element && element.classList.contains('hide')) {
            return true;
        }

        return false;
    }

    public getPercentiles(): Coordinates {
        if(this.performanceTile.data && this.costTile.data) return new Coordinates(this.performanceTile.data.percentile, this.costTile.data.percentile);

        return new Coordinates(0,0);
    }

    public getChartsHtml(): string[] {
        if(!this.sectionContent) return [];

        const sectionContentElement = this.sectionContent.nativeElement as HTMLElement;

        const elements = Array.from(sectionContentElement.getElementsByClassName('small-chart-tile'));

        return elements.map((el: Element) => el.innerHTML);
    }

    public getChartTitles(): string[] {
        return [this.costTitle, this.performanceTitle];
    }

    updateFromBespokeDashboard(...tile: Array<Tile>) {
        throw new Error("Method not implemented.");
    }

    clearTile(): void {
        throw new Error("Method not implemented.");

    }
}
