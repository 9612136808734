import { ChartType } from './enum/chart-type';

export class ChosenOptions {
    calcRef: number;
    chartType: ChartType;

    constructor(calcRef: number, chartType: ChartType) {
        this.calcRef = calcRef;
        this.chartType = chartType;
    }
}

export class AnalyseChosenOptions extends ChosenOptions {
    secondCalcRef?: number;

    constructor(calcRef: number, chartType: ChartType, secondCalcRef?: number) {
        super(calcRef, chartType);
        this.secondCalcRef = secondCalcRef;
    }
}

export class ReportChosenOptions extends ChosenOptions {

    sectionId: number;
    chartIndex: number;

    constructor(calcRef: number, chartType: ChartType, sectionId: number, chartIndex: number) {
        super(calcRef, chartType);

        this.sectionId = sectionId;
        this.chartIndex = chartIndex;
    }
}
