<div class="container-fluid dashboard" id="esg-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="esg-dashboard-body">
        <div id="dashboard-export-wrapper">

            <div class="col-lg-6">
                <div class="col-left dashboard-contents">
                    <div class="tiles-container">
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart1></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart2></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart3></icon-info-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-right dashboard-contents">
                    <div class="tiles-container">
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart4></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart5></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" (closeEditEvent)="closeEditModal($event)" #ESGDashChart6></icon-info-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>