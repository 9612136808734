<div class="container-fluid">
    <div class="row report-header dashboard-header">
        <div class="col-12">
            <div class="header">
                <div class="top-row">
                    <h1>{{reportName}}</h1>

                    <div class="action-buttons">
                        <button class="dark-button" (click)="openReorderModal.emit()">Reorder</button>
                        <button (click)="openDownloadModal.emit()">Download</button>
                        <manage-dropdown [context]="'report'" [listItems]="reportLayoutsList"
                            (saveCallback)="saveReportLayout($event)"
                            (loadCallback)="loadReportLayout($event)"></manage-dropdown>
                    </div>
                </div>

                <div class="supplemental-text" *ngIf="reportName">
                    <div *ngIf="supplementalTextLineOne">{{supplementalTextLineOne}}</div>
                    <div *ngIf="supplementalTextLineTwo">{{supplementalTextLineTwo}}</div>

                </div>
                <div class="header-buttons">
                    <button (click)="navigationService.toggleComponentByName('DateRangeComponent')">
                        <i class="hm-icon-date-range"></i> {{dateRangeText}}
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-group"></i> {{peerGroupType}} Peer Group
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-count"></i> Peers In Group {{peerGroupCount}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
