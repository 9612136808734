import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType } from '../../../models/enum/chart-type';
import { ComponentCharts } from '../../../models/component-charts';

@Component({
    selector: 'chart-type-options',
    templateUrl: './chart-type-options.component.html'
})
export class ChartTypeOptionsComponent {
    ChartType = ChartType;
    @Input() componentCharts: ComponentCharts;
    @Input() chartIndex: number;
    @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

    chartTypeMap = new Map<ChartType, string[]>([
        [ChartType.boxPlot, ['hm-icon-box-plot', 'Box Plot']],
        [ChartType.column, ['hm-icon-bar', 'Column']],
        [ChartType.summaryStackedColumn, ['hm-icon-stacked-bar', 'Summary Stacked Column']],
        [ChartType.annualTrend, ['hm-icon-annual-trend', 'Annual Trend']],
        [ChartType.annualStackedTrend, ['hm-icon-annual-stacked-trend', 'Annual Stacked Trend']],
        [ChartType.tree, ['hm-icon-treemap', 'Tree Map']],
        [ChartType.pie, ['hm-icon-pie', 'Pie']],
        [ChartType.table, ['hm-icon-table', 'Table']],
        [ChartType.scatter, ['hm-icon-scatter', 'Scatter']],
        [ChartType.stackedBar, ['hm-icon-stacked-bar', 'Stacked Bar']],
        [ChartType.infoTile, ['far fa-window-maximize', 'Info Tile']],
        [ChartType.complexGauge, ['hm-icon-gauge', 'Complex Gauge']],
        [ChartType.drillBar, ['hm-icon-drill', 'Drill Bar']],
        [ChartType.drillStackedBar, ['hm-icon-drill', 'Drill Stacked Bar']],
        [ChartType.quarterlyTrend, ['hm-icon-quarterly-trend', 'Quarterly Trend']],
        [ChartType.quarterlyStackedTrend, ['hm-icon-quaterly-stacked-trend', 'Quarterly Stacked Trend']]
    ]);

    constructor() {
    }

    isSelected(chartType: ChartType): boolean {
        return this.componentCharts.selectedChartType === chartType;
    }

    emitEvent(chartType: ChartType) {
        this.componentCharts.selectedChartType = chartType;
        this.selectionChanged.emit({ chartType: chartType, index: this.chartIndex });
    }

    chartTypeIncluded(chartType: ChartType) {
        return this.componentCharts.chartTypes.includes(chartType);
    }

    getChartTypeIcon(type: ChartType) {
        const valueArr = this.chartTypeMap.get(type);

        if (valueArr) return valueArr[0];

        return '';
    }

    getChartTypeName(type: ChartType) {
        const valueArr = this.chartTypeMap.get(type);

        if (valueArr) return valueArr[1];

        return '';
    }

    chartNotAvailable(type: ChartType) {
        return this.chartTypeMap.get(type) === undefined;
    }
}
