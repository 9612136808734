import { Type, Injectable } from "@angular/core";
import { SmallChartTileComponent } from "../../../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component";
import { ColumnDashboardDefinition } from "../../../../../models/dashboard/bespoke/column-dashboard-definition";
import { DashboardType } from "../../../../../models/enum/dashboard-type";
import { BespokeDashboard } from "../../../../../models/dashboard/bespoke/bespoke-dashboard";
import { DashboardStyle } from "../../../../../models/enum/dashboard-style";
import { ColumnDashboardComponent } from "../dashboard-hosts/column-dashboard/column-dashboard.component";
import { DynamicTile } from "../../../../../models/dashboard/bespoke/dynamic-tile";
import { DualChartTileComponent } from "../../../../tiles/dashboard-tiles/dual-chart-tile/dual-chart-tile.component";

@Injectable()
export class BespokeDashboardProvider {
    public dashboardTypes = new Map<DashboardType, Type<BespokeDashboard>>(
        [
            [DashboardType.column, ColumnDashboardComponent]
        ]
    );

    private dashboardStyle = new Map<DashboardStyle, (self: BespokeDashboardProvider) => ColumnDashboardDefinition>(
        [
            [DashboardStyle.smallCharts, this.updateSmallChartDashboardItemRefs],
            [DashboardStyle.dualCharts, this.updateDualChartDashboardItemRefs]
        ]
    );

    public getDashboardStyle(dashboardStyle: DashboardStyle): ColumnDashboardDefinition | undefined {
        const definition = this.dashboardStyle.get(dashboardStyle);

        if(definition) return definition(this);

        return undefined;
    }

    private updateSmallChartDashboardItemRefs(self: BespokeDashboardProvider): ColumnDashboardDefinition {

        const dynamicTileOne = new DynamicTile(SmallChartTileComponent, "BespokeDashboard1");
        const dynamicTileTwo = new DynamicTile(SmallChartTileComponent, "BespokeDashboard2");
        const dynamicTileThree = new DynamicTile(SmallChartTileComponent, "BespokeDashboard3");
        const dynamicTileFour = new DynamicTile(SmallChartTileComponent, "BespokeDashboard4");
        const dynamicTileFive = new DynamicTile(SmallChartTileComponent, "BespokeDashboard5");
        const dynamicTileSix = new DynamicTile(SmallChartTileComponent, "BespokeDashboard6");

        const leftSide = new Array<DynamicTile>(dynamicTileOne, dynamicTileThree, dynamicTileFive);
        const rightSide = new Array<DynamicTile>(dynamicTileTwo, dynamicTileFour, dynamicTileSix);

        return new ColumnDashboardDefinition(leftSide, rightSide);
    }

    private updateDualChartDashboardItemRefs(self: BespokeDashboardProvider): ColumnDashboardDefinition {
        const dynamicTileOne = new DynamicTile(DualChartTileComponent, "BespokeDashboard1a", "BespokeDashboard1b");
        const dynamicTileTwo = new DynamicTile(DualChartTileComponent, "BespokeDashboard2a", "BespokeDashboard2b");
        const dynamicTileThree = new DynamicTile(DualChartTileComponent, "BespokeDashboard3a", "BespokeDashboard3b");
        const dynamicTileFour = new DynamicTile(DualChartTileComponent, "BespokeDashboard4a", "BespokeDashboard4b");
        const dynamicTileFive = new DynamicTile(DualChartTileComponent, "BespokeDashboard5a", "BespokeDashboard5b");
        const dynamicTileSix = new DynamicTile(DualChartTileComponent, "BespokeDashboard6a", "BespokeDashboard6b");

        const leftSide = new Array<DynamicTile>(dynamicTileOne, dynamicTileThree, dynamicTileFive);
        const rightSide = new Array<DynamicTile>(dynamicTileTwo, dynamicTileFour, dynamicTileSix);

        return new ColumnDashboardDefinition(leftSide, rightSide);
    }
}
