import { Component, Input } from '@angular/core';
import { InfoTile } from '../../../../models/info-tile/info-tile';
import { DifferenceType } from '../../../../models/info-tile/difference-type';

@Component({
    selector: 'small-icon-info-tile',
    templateUrl: './small-icon-info-tile.component.html'
})
export class SmallIconInfoTileComponent {
    @Input() infoTile!: InfoTile;
    DifferenceType = DifferenceType;
    @Input() displayDifference = false;

    constructor() { }
}
