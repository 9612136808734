import { SelectableOption } from '../options/selectable-option';
import { SelectedPeerFilters } from './selected-peer-filters';
import { SliderData } from './slider-data';

export class APIPeerFilterData {
    selectedAdvancedFilters = new Array<SliderData>();
    dlos: string[];
    organisationTypes: string[];
    regions: string[];
    showOnlyDetailedCosts: boolean;
    stock: SliderData;
    isDynamic: boolean;
    peers: number[];
    id: number;
    isDefault: boolean;

    private filter(option: SelectableOption<string>): boolean {
        // tslint:disable-next-line:triple-equals
        return option.isSelected && option.value != '0';
    }

    constructor(selectablePeerFilters?: SelectedPeerFilters) {
        if (selectablePeerFilters) {
            this.organisationTypes = selectablePeerFilters.organisationTypes.filter(this.filter).map(val => val.value);
            this.stock = new SliderData(selectablePeerFilters.stock.maxValue, selectablePeerFilters.stock.minValue);
            this.regions = selectablePeerFilters.regions.filter(this.filter).map(val => val.value);
            this.dlos = selectablePeerFilters.dlo.filter(this.filter).map(val => val.value);
            this.showOnlyDetailedCosts = selectablePeerFilters.showOnlyDetailedCosts;

            selectablePeerFilters.advancedFilters.forEach((value, key) => {
                if (value.maxValue !== value.options.ceil || value.minValue !== value.options.floor) {
                    this.selectedAdvancedFilters.push(new SliderData(value.maxValue, value.minValue, key));
                }
            });

            this.isDynamic = true;
        } else {
            this.isDynamic = false;
        }
    }
}
