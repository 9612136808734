import { Tile } from './tile';

export class Dashboard {
    chartLayoutId: number;
    columns: number;
    id: number;
    rows: number;
    title: string;
    charts: Tile[];
}
