<div class="container-fluid dashboard" id="responsive-repairs-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="responsive-repairs-dashboard-body">
        <div id="dashboard-export-wrapper">

            <div class="col-lg-7">
                <div class="dashboard-contents col-left">
                    <div class="tiles-container">
                            <ranked-combo-tile (openEditEvent)="openEditModal($event)"
                                (closeEditEvent)="closeEditModal($event)" #RRDashChart1></ranked-combo-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="dashboard-contents col-right">
                    <div class="tiles-container">
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #RRDashChart2></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #RRDashChart3></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #RRDashChart4></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #RRDashChart5></icon-info-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>