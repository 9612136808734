import { ComponentOptionsComponent } from './component-options/component-options.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartTypeOptionsComponent } from './chart-type-options/chart-type-options.component';
import { SectionedComponentOptionsComponent } from './sectioned-component-options/sectioned-component-options.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbTooltipModule
    ],
    declarations: [
        ChartTypeOptionsComponent,
        ComponentOptionsComponent,
        SectionedComponentOptionsComponent
    ],
    exports: [
        ChartTypeOptionsComponent,
        ComponentOptionsComponent,
        SectionedComponentOptionsComponent
    ]
})
export class OptionsModule { }
