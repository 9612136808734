import { Component, EventEmitter, Output } from '@angular/core';
import { BaseTile } from '../base-tile/base-tile';
import { TileMetaData } from '../../../../models/tile-metadata';
import { EditTileOptions } from '../../../../models/edit-tile-options';
import { Tile } from '../../../../models/dashboard/tile';
import { ChartType } from '../../../../models/enum/chart-type';
import { HideableOption } from '../../../../models/options/hideable-option';
import { AvailablePi } from '../../../../models/dashboard/available-pi';
import { ComponentCharts } from '../../../../models/component-charts';
import { Chart } from '../../../../highcharts/chart';
import { InfoTile } from '../../../../models/info-tile/info-tile';

@Component({
    selector: 'ranked-combo-tile',
    templateUrl: './ranked-combo-tile.component.html'
})
export class RankedComboTileComponent extends BaseTile {
    largeInfoTile!: Tile;
    largeInfoTileChart!: InfoTile;
    secondTile!: Tile;
    secondTileChart!: Chart;
    thirdTile!: Tile;
    thirdTileChart!: Chart;

    @Output() override openEditEvent: EventEmitter<BaseTile> = new EventEmitter<BaseTile>();

    constructor() {
        super();
    }

    public getComponentsInTile(): Array<TileMetaData> {
        const infoMetaData = new TileMetaData();
        infoMetaData.chartType = ChartType[ChartType.infoTile];
        infoMetaData.calcRef = this.largeInfoTile.selected.calcRef;
        infoMetaData.dashboardItemRef = this.largeInfoTile.dashboardItemRef;

        const secondTileMetaData = new TileMetaData();
        secondTileMetaData.chartType = this.secondTile.selected.selectedKpiChartType;
        secondTileMetaData.calcRef = infoMetaData.calcRef;
        secondTileMetaData.dashboardItemRef = this.secondTile.dashboardItemRef;

        const thirdTileMetaData = new TileMetaData();
        thirdTileMetaData.chartType = this.thirdTile.selected.selectedKpiChartType;
        thirdTileMetaData.calcRef = infoMetaData.calcRef;
        thirdTileMetaData.dashboardItemRef = this.thirdTile.dashboardItemRef;

        return new Array<TileMetaData>(infoMetaData, secondTileMetaData, thirdTileMetaData);
    }

    public initializeModel(...model: Array<Tile>) {
        if (model && model.length > 0) {
            this.largeInfoTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('a'))!;
            this.largeInfoTileChart = new InfoTile(this.largeInfoTile);

            this.secondTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('b'))!;
            if (this.secondTile.data) this.secondTileChart = new Chart(this.secondTile.data);

            this.thirdTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('c'))!;
            if (this.thirdTile.data) this.thirdTileChart = new Chart(this.thirdTile.data);
        }
    }

    public updateModel(...tileData: Array<TileMetaData>) {
        const infoData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('a'));

        if (infoData) {
            const selected = this.largeInfoTile.available.find(available => available.calcRef === infoData.calcRef);

            if (selected) {
                this.largeInfoTile.selected = selected;
                this.largeInfoTile.data = infoData.data;
                this.largeInfoTileChart = new InfoTile(this.largeInfoTile);
            }
        }

        const secondTileData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('b'));

        if (secondTileData) {
             const selected = this.secondTile.available.find(available => available.calcRef === secondTileData.calcRef);

            if (selected) {
                this.secondTile.selected = selected;
                this.secondTile.selected.selectedKpiChartType = secondTileData.chartType;
                this.secondTile.data = secondTileData.data;
                this.secondTileChart = new Chart(secondTileData.data);
            }
        }

        const thirdTileData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('c'));

        if (thirdTileData) {
            const selected = this.thirdTile.available.find(available => available.calcRef === thirdTileData.calcRef);

            if (selected) {
                this.thirdTile.selected = selected;
                this.thirdTile.selected.selectedKpiChartType = thirdTileData.chartType;
                this.thirdTile.data = thirdTileData.data;
                this.thirdTileChart = new Chart(thirdTileData.data);
            }
        }
    }

    public getEditOptions(): EditTileOptions {
        const editOptions = new EditTileOptions();

        editOptions.piOptions = this.largeInfoTile.available.map((availableChart: AvailablePi, index: number) => {
            return new HideableOption<number>(availableChart.calcRef === this.largeInfoTile.selected.calcRef, availableChart.name,
                                              availableChart.calcRef, true, availableChart.noDataMessage);
        });

        const metas = this.getComponentsInTile();

        let metaData = metas.find(tmd => tmd.dashboardItemRef.toLocaleLowerCase().endsWith('b'));

        let componentCharts = new ComponentCharts();
        componentCharts.chartTypes = this.secondTile.selected.availableChartTypes.split(', ').map(act => ChartType[act]);
        componentCharts.selectedChartType = ChartType[metaData!.chartType];
        componentCharts.dashboardItemRef = metaData!.dashboardItemRef;
        editOptions.chartsTypes.push(componentCharts);


        metaData = metas.find(tmd => tmd.dashboardItemRef.toLocaleLowerCase().endsWith('c'));
        componentCharts = new ComponentCharts();
        componentCharts.chartTypes = this.thirdTile.selected.availableChartTypes.split(', ').map(act => ChartType[act]);
        componentCharts.selectedChartType = ChartType[metaData!.chartType];
        componentCharts.dashboardItemRef = metaData!.dashboardItemRef;
        editOptions.chartsTypes.push(componentCharts);

        return editOptions;
    }
}
