import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ServiceMessageService } from "../../../services/service-message.service";
import { SpinnerService } from "../../spinner/spinner.service";

@Component({
    selector: "admin",
    templateUrl: "./admin.component.html"
})
export class AdminComponent implements AfterViewInit {
    currentMessage = "";
    newMessage: string;

    constructor(private serviceMessageService: ServiceMessageService, private spinnerService: SpinnerService) {}

    ngAfterViewInit() {
        this.serviceMessageService.currentServiceMessage.subscribe((message: string) => {
            this.currentMessage = message;
        });
    }

    deleteServiceMessage() {
      this.spinnerService.show('app-spinner');

      this.serviceMessageService.deleteServiceMessage().subscribe(() => {
        this.currentMessage = '';
        this.spinnerService.hide('app-spinner');
      }, () => {
          this.spinnerService.hide('app-spinner');
      });
    }

    addServiceMessage() {
      this.spinnerService.show('app-spinner');

      this.serviceMessageService.addServiceMessage(this.newMessage).subscribe(() => {
        this.currentMessage = this.newMessage;
        this.newMessage = '';
        this.spinnerService.hide('app-spinner');
      }, () => {
          this.spinnerService.hide('app-spinner');
      });
    }
}
