import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { LayoutService } from '../../../services/layout.service';
import { ChartsService } from '../../../services/charts.service';
import { CombinedStreamService } from '../../../services/combined-stream.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../../../services/export.service';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { DualChartTileComponent } from '../../tiles/dashboard-tiles/dual-chart-tile/dual-chart-tile.component';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'tsm-dashboard',
    templateUrl: './tsm-dashboard.component.html'
})
export class TSMDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.TSM;
    dashboardHTMLId = 'tsm-dashboard-body';
    portraitExport = true;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    @ViewChild('TSMDashChart1') leftTile1!: DualChartTileComponent;
    @ViewChild('TSMDashChart2') leftTile2!: DualChartTileComponent;
    @ViewChild('TSMDashChart3') leftTile3!: DualChartTileComponent;
    @ViewChild('TSMDashChart4') rightTile1!: DualChartTileComponent;
    @ViewChild('TSMDashChart5') rightTile2!: DualChartTileComponent;
    @ViewChild('TSMDashChart6') rightTile3!: DualChartTileComponent;

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['TSMDashChart1', this.leftTile1],
            ['TSMDashChart2', this.leftTile2],
            ['TSMDashChart3', this.leftTile3],
            ['TSMDashChart4', this.rightTile1],
            ['TSMDashChart5', this.rightTile2],
            ['TSMDashChart6', this.rightTile3]
        ]);

        this.Init();
    }
}
