import { ComponentCharts } from './component-charts';
import { HideableOption } from './options/hideable-option';

export class EditTileOptions {
    piOptions = new Array<HideableOption<number>>();
    chartsTypes = new Array<ComponentCharts>();
}

export class ReportEditTileOptions extends EditTileOptions {
    sectionId: number;
    chartIndex: number;
}

export class CombinationTileOptions extends EditTileOptions {
    secondChartChartsTypes = new Array<ComponentCharts>();
}
