import { Injectable } from '@angular/core';
import { HeadersService } from './headers.service';
import { HttpClient } from '@angular/common/http';
import { ResponseType } from '../models/enum/response-type';
import { Activity } from '../models/export/activities';
import { PdfExportType } from '../models/export/pdf-export-type';
import { JsReportPage } from '../models/export/js-report-page';
import { CombinedStreamService } from './combined-stream.service';
import { OrganisationService } from './organisation.service';
import { Observable, of, Subject } from 'rxjs';
import * as domtoimage from 'dom-to-image';
import { JsReportService } from './js-report.service';
import { SpinnerService } from '../components/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ExportService {
    constructor(private headersService: HeadersService, private httpClient: HttpClient, private combinedService: CombinedStreamService, private organisationService: OrganisationService,
        private jsReportService: JsReportService, private spinnerService: SpinnerService, private toastr: ToastrService) {

    }

    private exportDashboardAsPDF(dashboardTitle: string, dashboardHtml: string, portraitExport?: boolean): Observable<any> {
        const pdfBody = {
            page: dashboardHtml
        };

        return this.exportAsPDF(dashboardTitle, pdfBody, portraitExport ? PdfExportType.DashboardPortrait : PdfExportType.Dashboard);
    }

    exportMultiPageDashboardAsPDF(dashboardName: string, pdfDashboard: JsReportPage[]): any {
        if(!this.organisationService.currentLandlord) return;

        const pdfBody = {
            organisationName: this.organisationService.currentLandlord.name,
            dashboardName: dashboardName,
            pages: pdfDashboard
        };

        return this.exportAsPDF(dashboardName, pdfBody, PdfExportType.MultiPageDashboard);
    }

    exportReportAsPDF(reportTitle: string, pdfReport: JsReportPage[]): Observable<any> {
        if(!this.organisationService.currentLandlord) return of();

        const pdfBody = {
            organisationName: this.organisationService.currentLandlord.name,
            reportTitle: reportTitle,
            pages: pdfReport
        };

        return this.exportAsPDF(reportTitle, pdfBody, PdfExportType.Report);
    }

    exportChartAsPDF(chartHtml: string, chartTitle: string): Observable<any> {
        const pdfBody = {
            chart: chartHtml
        };

        return this.exportAsPDF(chartTitle, pdfBody, PdfExportType.SingleChart);
    }

    exportAsPDF(title: string, pdfBody: any, exportType: PdfExportType) {
        const headers = this.headersService.getHeaders();
        const url = `/api/Exports/Pdf/${title}`;
        const pdfExport = {
            pdfBody: pdfBody,
            pdfExportType: exportType
        };

        return this.httpClient.post(url, pdfExport, { headers: headers, responseType: ResponseType.Blob });
    }

    exportDataset(sections: string[]): Observable<any> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();
        const url = `/api/Exports/ExcelDataSet/${apiData.organisationWKey}/${apiData.periods}?filterId=${apiData.filterId}`;
        const dataSetExport = {
            sections: sections
        };

        return this.httpClient.post(url, dataSetExport, { headers: apiData.headers, responseType: ResponseType.Blob });
    }

    exportExcelOther(infoComponentWKeys: number[]): Observable<any> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        const url = `/api/Exports/ExcelOther/${apiData.organisationWKey}/${infoComponentWKeys.join()}/${apiData.periods}?filterId=${apiData.filterId}`;
        return this.httpClient.get(url, { headers: apiData.headers, responseType: ResponseType.Blob });
    }

    getActivitiesForExport(): Observable<Activity[]> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey && apiData.periods) {
            const url = `/api/Activities/ByOrg/${apiData.organisationWKey}/${apiData.periods}/${apiData.hasAccessToCore}`;
            return this.httpClient.get<Activity[]>(url, { headers: apiData.headers });
        }

        return of();
    }

    public exportPeersListAsXls(filterId: number, fileName: string): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = `/api/Exports/PeerListExcel/${filterId}/${fileName}`;
        return this.httpClient.get(url, { headers: headers, responseType: ResponseType.Blob });
    }

    public exportDashboardAsPng(dashboardName: string): Subject<void> {
        const doc = document.getElementById('dashboard-export-wrapper') as Node;
        const self = this;
        const subscription = new Subject<void>();
        
        domtoimage.default.toBlob(doc).then((blob) => {
            self.jsReportService.startDownload(`${dashboardName}.png`, blob);
            self.spinnerService.hide('app-spinner');
            subscription.next();
        }).catch(function (error) {
            self.toastr.error(error, 'Error');
            subscription.next();
        });

        return subscription;
    }

    public exportExcel(fileName: string, ...calcRefs: Array<number>): Subject<void> {
        const subscription = new Subject<void>();
        this.exportExcelOther(calcRefs).subscribe((res: Blob) => {
            this.jsReportService.startDownload(fileName, res);
            subscription.next();
        }, () => {
            subscription.next();
        });

        return subscription;
    }

    public exportDashboardPdf(dashboardName: string, portraitExport: boolean, dashboardHtmlId: string): Subject<void> {
        const subscription = new Subject<void>();
        const bodyHtml = document.getElementById(dashboardHtmlId);
        const cleanHtml = this.jsReportService.sanitiseHtml(bodyHtml!.innerHTML);

        this.exportDashboardAsPDF(dashboardName, cleanHtml, portraitExport).subscribe((res: Blob) => {
            this.jsReportService.startDownload(`${dashboardName}.pdf`, res);
            subscription.next();
        }, () => {
            subscription.next();
            this.toastr.error('Please try again later', 'There was a problem generating your pdf');
        });

        return subscription;
    }
}
