<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="header">
                <div class="top-row">
                    <h1>Datasets</h1>
                </div>
                <div class="header-buttons">
                    <button (click)="navigationService.toggleComponentByName('DateRangeComponent')">
                        <i class="hm-icon-date-range"></i> {{dateRangeText}}
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-group"></i> {{peerGroupType}} Peer Group
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-count"></i> Peers In Group {{peerGroupCount}}
                    </button>
                </div>
                <div class="sub-title">
                    Select the sets you wish to download in Excel format.
                </div>
            </div>
        </div>
    </div>
    <div class="row dashboard">
        <div class="analysis-body">
            <div class="col-12">

                <div class="search-bar">
                    <input id="search" type="search" placeholder="Search datasets" [(ngModel)]="searchTerm" (ngModelChange)="filterItems()" />
                </div>

                <div class="data-sets-table-wrapper">
                    <table>
                        <thead>
                            <th id="dataset-checked" (click)="setSortCriteria('checked')">
                                <input type="checkbox" [checked]="areAllActivities(true)" (change)="toggleAllActivities($event)" />
                                <i *ngIf="sortCriteria == 'checked'" class="fas fa-caret-down"></i>
                                <i *ngIf="sortCriteria == '-checked'" class="fas fa-caret-up"></i>
                                <i *ngIf="!sortCriteria.includes('checked')" class="fas fa-sort"></i>
                            </th>
                            <th id="dataset-name" (click)="setSortCriteria('name')">Dataset name
                                <i *ngIf="sortCriteria == 'name'" class="fas fa-caret-down"></i>
                                <i *ngIf="sortCriteria == '-name'" class="fas fa-caret-up"></i>
                                <i *ngIf="!sortCriteria.includes('name')" class="fas fa-sort"></i>
                            </th>
                            <th id="dataset-section-name" (click)="setSortCriteria('sectionName')">Section name
                                <i *ngIf="sortCriteria == 'sectionName'" class="fas fa-caret-down"></i>
                                <i *ngIf="sortCriteria == '-sectionName'" class="fas fa-caret-up"></i>
                                <i *ngIf="!sortCriteria.includes('sectionName')" class="fas fa-sort"></i>
                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let activity of activities" [class.hide]="!activity.isVisible">
                                <td class="checkbox">
                                    <input type="checkbox" [(ngModel)]="activity.isSelected" [disabled]="!activity.isEnabled">
                                </td>
                                <td class="name"(click)="activity.isSelected = !activity.isSelected" [class.disabled]="!activity.isEnabled">
                                    {{activity.name}}
                                    <i placement="right" ngbTooltip="{{activity.noDataMessage}}" *ngIf="!activity.isEnabled"
                                        class="hm-icon-help"></i>
                                </td>
                                <td class="name" [class.disabled]="!activity.isEnabled">
                                    {{activity.sectionName}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="buttons-container">
                    <button [class.disabled]="areAllActivities(false)" [disabled]="areAllActivities(false)" (click)="download()">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>