<div class="container-fluid dashboard" id="sector-scorecard-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="sector-scorecard-dashboard-body">
        <div id="dashboard-export-wrapper">

            <div class="col-lg-6">
                <div class="col-left dashboard-contents">
                    <div class="tiles-container">
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart1></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart3></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart5></small-chart-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-right dashboard-contents">
                    <div class="tiles-container">
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart2></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart4></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #SSDashChart6></small-chart-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>