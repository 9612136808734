<div class="container-fluid dashboard" id="quarterly-performance-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()" [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="quarterly-performance-dashboard-body">
            <div id="dashboard-export-wrapper">
        <div class="col-lg-6">
            <div class="col-left dashboard-contents">
                <div class="tiles-container">
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart1></small-chart-tile>
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart3></small-chart-tile>
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart5></small-chart-tile>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="col-right dashboard-contents">
                <div class="tiles-container">
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart2></small-chart-tile>
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart4></small-chart-tile>
                    <small-chart-tile (openEditEvent)="openEditModal($event)" #QPDashChart6></small-chart-tile>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions"></edit-tile-modal>