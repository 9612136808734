import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SelectedPeerFilters } from '../../../../models/peer-filters/selected-peer-filters';
import { ToastrService } from 'ngx-toastr';
import { PeerGroupType } from '../../../../models/enum/peer-group-type';
import { SliderModel } from '../../../../models/slider';
import { NavigationService } from '../../../../services/navigation.service';
import { SelectableOption } from '../../../../models/options/selectable-option';
import { PeersService } from '../../../../services/peers.service';
import { SliderComponent } from '../../../slider/slider.component';
import { first } from 'rxjs';

@Component({
    selector: 'dynamic-peer-group',
    templateUrl: './dynamic-peer-group.component.html'
})
export class DynamicPeerGroupComponent implements OnInit {
    dynamicFilters: SelectedPeerFilters;
    advancedBody!: HTMLElement;
    standardChevron!: HTMLElement;
    standard!: HTMLElement;
    advancedChevron!: HTMLElement;
    reInitialisedAdvancedSliders = false;
    showAdvanced = false;

    @ViewChildren(SliderComponent) sliderComponents!: QueryList<SliderComponent>;

    constructor(private peersService: PeersService, private toastr: ToastrService, private navigationService: NavigationService) {
    }

    ngOnInit() {
        this.advancedBody = document.getElementById('advanced-filters-body')!;
        this.standardChevron = document.getElementById('standard-chevron')!;
        this.standard = document.getElementById('standard-filters-body')!;
        this.advancedChevron = document.getElementById('advanced-chevron')!;

        this.dynamicFilters = this.peersService.currentFilters;
    }

    toggleSectionVisibility(event: MouseEvent) {
        const target = <HTMLInputElement>event.currentTarget;
        target.classList.add('hide');

        if (target.id === 'advanced-chevron') {
            this.reInitAdvancedSliders();

            this.showAdvanced = true;
            this.advancedBody.classList.remove('hide');
            this.standardChevron.classList.remove('hide');
            this.standard.classList.add('hide');
        } else {
            this.reInitStockSlider();

            this.showAdvanced = false;
            this.standard.classList.remove('hide');
            this.advancedChevron.classList.remove('hide');
            this.advancedBody.classList.add('hide');
        }
    }

    getFilter(filterKey: number): SliderModel {
        return this.dynamicFilters.advancedFilters.get(filterKey)!;
    }

    apply(closeWindow: boolean) {
        if (this.noStandardOptionsChosen()) return;

        this.peersService.setOrganisationFilters(PeerGroupType.Dynamic);
        this.toastr.success('These options have been applied to your charts', 'Success!');

        if (closeWindow) this.close();
    }

    cancel() {
        this.peersService.resetFilters();
        this.dynamicFilters = this.peersService.currentFilters;
    }

    close() {
        this.navigationService.toggleComponentByName('PeerSelectionComponent');
    }

    noneSelected(options: SelectableOption<string>[]) {
        return !options || options.every(option => !option.isSelected);
    }

    canChangeTab() {
        return (!this.noneSelected(this.dynamicFilters.dlo) && !this.noneSelected(this.dynamicFilters.organisationTypes) && !this.noneSelected(this.dynamicFilters.regions));
    }

    noStandardOptionsChosen(): boolean {
        return !this.dynamicFilters
            || this.noneSelected(this.dynamicFilters.organisationTypes)
            || this.noneSelected(this.dynamicFilters.regions)
            || this.noneSelected(this.dynamicFilters.dlo);
    }

    reInitStockSlider() {
        const sc = this.sliderComponents.find(sliderComp => sliderComp.model.name === '');

        if(sc) {
            sc.reInitSlider();
            this.dynamicFilters.stock = sc!.model;
        }
    }

    reInitAdvancedSliders(): void {
        if (this.reInitialisedAdvancedSliders) return;

        this.dynamicFilters.advancedFilters.forEach((sliderModel, key) => {
            const sc = this.sliderComponents.find(sliderComp => sliderComp.model.name === sliderModel.name);

            sc!.reInitSlider();

            this.dynamicFilters.advancedFilters.set(key, sc!.model);
        });

        this.reInitialisedAdvancedSliders = true;

    }
}
