export class Coordinates {
    private buffer = 1.5;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    _x: number;
    _y: number;

    
    public get y() {
        return this._y;
    }

    public set y(value: number) {
        this._y = value;
    }

    public get x() {
        return this._x;
    }

    public set x(value: number) {
        this._x = value;
    }

    get xUpper(): number {
        return this.x + this.buffer;
    }

    get yUpper(): number {
        return this.y + this.buffer;
    }

    get yLower(): number {
        return this.y - this.buffer;
    }

    get xLower(): number {
        return this.x - this.buffer;
    }

    public addBufferIfCoordinatesOverlap(coordinate: Coordinates): boolean {
        if ((this.x !== null && coordinate.x !== null) && (this.y !== null && coordinate.y !== null) &&
            (this.x > coordinate.xLower && this.x < coordinate.xUpper) && (this.y > coordinate.yLower && this.y < coordinate.yUpper)) {

            if (this.x >= coordinate.xLower && this.x <= coordinate.x) this.x = coordinate.x - this.buffer;
            else this.x = coordinate.x + this.buffer;

            if (this.y >= coordinate.yLower && this.y <= coordinate.y) this.y = coordinate.y - this.buffer;
            else this.y = coordinate.y + this.buffer;

            return true;
        }

        return false;
    }
}
