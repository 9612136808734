import { Injectable } from '@angular/core';
import { PeerGroup } from '../models/peer-group';
import { HeadersService } from './headers.service';
import { HttpClient } from '@angular/common/http';
import { PeerGroupType } from '../models/enum/peer-group-type';
import { PeerFilters } from '../models/peer-filters/peer-filters';
import { SelectedPeerFilters } from '../models/peer-filters/selected-peer-filters';
import { OrganisationService } from './organisation.service';
import { SpinnerService } from '../components/spinner/spinner.service';
import { SliderModel } from '../models/slider';
import { ToastrService } from 'ngx-toastr';
import { Filter } from '../models/filter';
import { DateRangeService } from './date-range.service';
import { APIPeerFilterData } from '../models/peer-filters/api-peer-filter-data';
import { first, map, Observable, of, Subject, take } from 'rxjs';

@Injectable()
export class PeersService {
    private _dynamicOptions: SelectedPeerFilters;
    private _filter?: Filter;
    private _allPeers = new Array<PeerGroup>();
    private _filterName: string = '';

    // The boolean value here is whether a full refresh is needed of a dashboard/report/analyse
    // this is because the available PIs for landlords differ and we need to convey whether to get
    // the available PIs through the initial page setup API calls.
    public peersChanged = new Subject<boolean>();
    public peersLoaded = new Subject<PeerGroupType>();
    private _peerGroupType = PeerGroupType.Bespoke;
    private _dynamicOptionsClone!: SelectedPeerFilters;
    private _allPeersClone: PeerGroup[] = [];
    public defaultFilter?: APIPeerFilterData;

    constructor(private headersService: HeadersService, private httpClient: HttpClient, private spinnerService: SpinnerService,
        private toastr: ToastrService, private organisationService: OrganisationService, private dateRangeService: DateRangeService) {
    }

    fetchOrganisations(): Observable<void> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Organisations/Peers';
        
        return this.httpClient.get<PeerGroup[]>(url, { headers: headers }).pipe(map((peers: PeerGroup[]) => {

            peers.forEach((peer: PeerGroup) => {
                peer.isVisibleInList = true;
                peer.includedInGroup = true;
            });

            this._allPeers = peers;
            this._allPeersClone = JSON.parse(JSON.stringify(this._allPeers));
            this.peersLoaded.next(PeerGroupType.Bespoke);
        }));
    }

    get peerGroupType(): PeerGroupType {
        return this._peerGroupType;
    }

    get peers(): PeerGroup[] {
        return this._allPeers;
    }

    get masterPeerList(): PeerGroup[] {
        return this._allPeersClone;
    }

    get currentPeerCount(): number {
        return this._filter?.peerCount ?? 0;
    }

    fetchDynamicPeerGroupFilters(): Observable<void> {
        const headers = this.headersService.getHeaders();
        const url = '/api/DynamicPeers';

        return this.httpClient.get<PeerFilters>(url, { headers: headers }).pipe(map((filters) => {
            this._dynamicOptions = new SelectedPeerFilters(filters);
            this._dynamicOptionsClone = this._dynamicOptions.copyFilters();
            this.peersLoaded.next(PeerGroupType.Dynamic);
        }));
    }

    get currentFilter(): Filter | undefined {
        return this._filter;
    }

    get currentFilters(): SelectedPeerFilters {
        return this._dynamicOptions;
    }

    public resetFilters(): void {
        this._allPeers = JSON.parse(JSON.stringify(this._allPeersClone));
        this._dynamicOptions = this._dynamicOptionsClone.copyFilters();
    }

    public setOrganisationFilters(filterType: PeerGroupType) {
        this.filterName = 'Unsaved Peer Group';
        let filterAPIData: APIPeerFilterData;

        if (filterType === PeerGroupType.Dynamic) filterAPIData = new APIPeerFilterData(this._dynamicOptions);
        else {
            filterAPIData = new APIPeerFilterData();
            filterAPIData.peers = this._allPeers.filter((peer: PeerGroup) => peer.includedInGroup).map((peer: PeerGroup) => peer.organisationWkey);
        }

        this.applyFilterData(filterAPIData, filterType);
    }

    private applyFilterData(filterAPIData: APIPeerFilterData, filterType: PeerGroupType) {
        const headers = this.headersService.getHeaders();
        const url = '/api/Filters';
        this.spinnerService.show('app-spinner');
        this.httpClient.post<Filter>(url, filterAPIData, { headers: headers }).pipe(first()).subscribe((filterRes: Filter) => {
            this.finaliseFilter(filterType, filterRes, false);
            this.spinnerService.hide('app-spinner');
        }, () => {
            this.toastr.error('Please try applying your dynamic filters again', 'Something went wrong');
            this.spinnerService.hide('app-spinner');
        });
    }

    private finaliseFilter(filterType: PeerGroupType, filterRes: Filter, hardReloadRequired: boolean) {
        this._peerGroupType = filterType;
        this._filter = filterRes;

        if(this._allPeers) {
            this._allPeers.forEach(pg => {
                pg.includedInGroup = this._filter!.peers.includes(pg.organisationWkey);
            });
        }

        this._dynamicOptionsClone = this._dynamicOptions.copyFilters();
        this._allPeersClone = JSON.parse(JSON.stringify(this._allPeers));

        this.peersChanged.next(hardReloadRequired);
    }

    public loadPeerGroup(filterId: number, filterName: string): void {
        const url = `/api/Filters/ById/${filterId}`;
        this.filterName = filterName;
        this.getFilterData(url, true, true);
    }

    public getFilterData(url: string, applyData: boolean, showSpinner: boolean): void {
        const headers = this.headersService.getHeaders();
        if(showSpinner) this.spinnerService.show('app-spinner');
        this.httpClient.get<APIPeerFilterData>(url, { headers: headers }).subscribe((res: APIPeerFilterData) => {
            if (res) {
                if (res.isDefault) this.defaultFilter = res;
                if (res.isDynamic) {
                    this._dynamicOptions.applyAPIPeerFilterData(res);

                    if (applyData) {
                        this.applyFilterData(res, PeerGroupType.Dynamic);
                        this.toastr.success('Your peer group was loaded successfully', 'Success');

                    } else {
                        const dummyFilter = this.buildDummyFilter(res);
                        this.finaliseFilter(PeerGroupType.Dynamic, dummyFilter, true);
                    }
                } else {
                    const dummyFilter = this.buildDummyFilter(res);

                    // if you're applying data it means that this has come from a saved or applied filter as opposed to a default filter
                    // this means a hard reload of dashboards/reports/analyses is NOT required
                    this.finaliseFilter(PeerGroupType.Bespoke, dummyFilter, !applyData);
                }
            } else {
                this.hardResetAllFilters();
                this.peersChanged.next(true);
            }

            if(showSpinner) this.spinnerService.hide('app-spinner');
        }, () => {
            if(showSpinner) this.spinnerService.hide('app-spinner');
            this.toastr.error('Please try loading your peer group again', 'Error');
        });
    }

    private hardResetAllFilters(): void {

        this._dynamicOptions.dlo.forEach(dlo => dlo.isSelected = true);
        this._dynamicOptions.organisationTypes.forEach(ot => ot.isSelected = true);
        this._dynamicOptions.regions.forEach(reg => reg.isSelected = true);
        this._dynamicOptions.stock.minValue = this._dynamicOptions.stock.options.floor!;
        this._dynamicOptions.stock.maxValue = this._dynamicOptions.stock.options.ceil!;
        this._dynamicOptions.showOnlyDetailedCosts = false;

        this._dynamicOptions.advancedFilters.forEach((slider: SliderModel) => {
            slider.maxValue = slider.options.ceil!;
            slider.minValue = slider.options.floor!;
        });

        this._allPeers.forEach(peerGroup => peerGroup.includedInGroup = true);
        this._dynamicOptionsClone = this._dynamicOptions.copyFilters();
        this._allPeersClone = JSON.parse(JSON.stringify(this._allPeers));
    }

    private buildDummyFilter(res: APIPeerFilterData): Filter {
        const dummyFilter = new Filter();
        dummyFilter.peerCount = res.peers.length;
        dummyFilter.peers = res.peers;
        dummyFilter.id = res.id;
        return dummyFilter;
    }

    public getDefaultPeers(defaultPeerGroupName: string): void {
        if (!this.organisationService.currentLandlord) return; 

        const url = `/api/Filters/HouseMarkDefault/${this.organisationService.currentLandlord.wKey}`;
        this.filterName = defaultPeerGroupName;
        this.getFilterData(url, true, true);
    }

    public fetchDefaultPeers(): Observable<unknown> {
        this.dateRangeService.dateRangeLoaded.subscribe(() => {

            const url = `/api/Filters/Default/${this.organisationService.currentLandlord!.wKey}`;

            this.getFilterData(url, false, false);
        });

        this.dateRangeService.dateRangeChanged.subscribe(() => {
            this.peersChanged.next(true);
        });

        if (this.organisationService.currentLandlord) {
            const url = `/api/Filters/Default/${this.organisationService.currentLandlord.wKey}`;
            this.getFilterData(url, false, false);
        }

        return of(1);
    }

    public hasUnsavedChanges(peerGroupType: PeerGroupType): boolean {
        if (peerGroupType === PeerGroupType.Dynamic) {

            if (this._dynamicOptions.stock.minValue !== this._dynamicOptionsClone.stock.minValue) return true;
            if (this._dynamicOptions.stock.maxValue !== this._dynamicOptionsClone.stock.maxValue) return true;
            if (this._dynamicOptions.showOnlyDetailedCosts !== this._dynamicOptionsClone.showOnlyDetailedCosts) return true;

            let difference = false;

            this._dynamicOptions.dlo.forEach(dlo => {
                const cloneval = this._dynamicOptionsClone.dlo.find(dloClone => dloClone.value === dlo.value);

                if (cloneval && dlo.isSelected !== cloneval.isSelected) difference = true;
            });

            this._dynamicOptions.regions.forEach(region => {
                const cloneval = this._dynamicOptionsClone.regions.find(regionClone => regionClone.value === region.value);

                if (cloneval && region.isSelected !== cloneval.isSelected) difference = true;
            });

            this._dynamicOptions.organisationTypes.forEach(orgType => {
                const cloneval = this._dynamicOptionsClone.organisationTypes.find(orgTypeClone => orgTypeClone.value === orgType.value);

                if (cloneval && orgType.isSelected !== cloneval.isSelected) difference = true;
            });

            this._dynamicOptions.advancedFilters.forEach((val: SliderModel, key: number) => {
                const cloneval = this._dynamicOptionsClone.advancedFilters.get(key);

                if (val.maxValue !== cloneval!.maxValue || val.minValue !== cloneval!.minValue) {
                    difference = true;
                }
            });

            return difference;
        } else {
            const leftOverPeers = this._allPeers.filter(peer => -1 ===
                this._allPeersClone.findIndex(pg => pg.organisationWkey === peer.organisationWkey && pg.includedInGroup === peer.includedInGroup));

            return leftOverPeers.length > 0;
        }
    }

    set filterName(name: string) {
        this._filterName = name;
    }

    get filterName(): string {
        return !this._filterName ? 'HouseMark defined peer group' : this._filterName;
    }

    public setPeerGroupAsDefault(filterId: number, organisationWKey: number): Observable<number> {
        const headers = this.headersService.getHeaders();
        const url = `/api/organisationdefaults/filters/${filterId}/${organisationWKey}`;

        return this.httpClient.put<number>(url, null, { headers: headers });
    }

    public deleteDefaultPeerGroup(organisationWKey: number): Observable<number> {
        const headers = this.headersService.getHeaders();
        const url = `/api/organisationdefaults/filters/${organisationWKey}`;

        return this.httpClient.delete<number>(url, { headers: headers });
    }
}
