import { TileMetaData } from './tile-metadata';
import { BaseTile } from '../components/tiles/dashboard-tiles/base-tile/base-tile';

export class TilesRequest {
    tiles: TileMetaData[] = new Array<TileMetaData>();

    constructor(componentMap?: Map<string, BaseTile>) {
        if (componentMap) {
            componentMap.forEach((component) => {
                const tiles = component.getComponentsInTile();
                tiles.forEach(tile => {
                    this.tiles.push(tile);
                });
            });
        }
    }
}
