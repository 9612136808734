import { NgModule } from '@angular/core';
import * as more from 'highcharts/highcharts-more';
import * as solidguage from 'highcharts/modules/solid-gauge';
import * as treemap from 'highcharts/modules/treemap';
import * as drilldown from 'highcharts/modules/drilldown';
import * as accessibility from 'highcharts/modules/accessibility';
import * as Highcharts from 'highcharts';
import { ChartComponent } from './chart.component';

import * as HighchartsGroupedCategories from './group-cat';

@NgModule({
    declarations: [
        ChartComponent
    ],
    exports: [
        ChartComponent
    ]
})
export class ChartModule {
    highchartsModules: any[] = [ more, solidguage, HighchartsGroupedCategories, treemap, drilldown, accessibility ];

    constructor() {
        this.highchartsModules.forEach(module => {
            if (typeof module !== 'object') module(Highcharts);
        });
    }
}
