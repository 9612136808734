import { Input, Component, EventEmitter, Output } from '@angular/core';
import { ReportDialogueSection } from '../../../models/report-dialogue-section';
import { ReportDialogueComponent } from '../../../models/report-dialogue-component';
import { ExportType } from '../../../models/enum/export-type';

@Component({
    selector: 'download-report-dialogue',
    templateUrl: './download-report-dialogue.component.html'
})
export class DownloadReportDialogueComponent {
    @Input() dialogueItems: ReportDialogueSection[] = new Array<ReportDialogueSection>();
    @Output() closeModal = new EventEmitter<any>();
    @Output() downloadReport: EventEmitter<ExportType> = new EventEmitter<any>();

    constructor() {
    }

    close() {
        this.closeModal.emit();
    }

    clickSection(section: ReportDialogueSection) {
        let newVal = false;
        if (section.isSelected) newVal = true;
        else newVal = false;

        section.components.forEach(comp => {
            comp.isSelected = newVal;
        });
    }

    clickComponent(section: ReportDialogueSection, component: ReportDialogueComponent) {
        if (component.isSelected === true) {
            section.isSelected = true;
        } else if (section.components.every(comp => !comp.isSelected)) {
            section.isSelected = false;
        }
    }

    exportAsPDF() {
        this.downloadReport.emit(ExportType.Pdf);
    }

    exportAsExcel() {
        this.downloadReport.emit(ExportType.Excel);
    }
}
