<div class="dropdown-save-modal simple-modal">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Save {{context}}</h4>
                </div>
                <div class="body">
                    <p>Give your {{context}} a name. We recommend something descriptive</p>

                    <input [(ngModel)]="newItemName" type="search" />
                    <div *ngIf="itemAlreadyExists()" class="item-already-exists">A {{context}} with this name already
                        exists</div>

                    <ng-container *ngIf="items">
                        <div class="existing-items">Existing {{context}}s</div>
                        <div class="list-wrapper">
                            <ul class="list-unstyled">
                                <li [class.selected]="item.isSelected"
                                    *ngFor="let item of items">{{item.text}}</li>
                            </ul>
                        </div>
                    </ng-container>

                    <div class="button-wrapper">
                        <button [class.disabled]="!canSave()" (click)="save.emit(newItemName)">Save</button>
                        <button class="dark-button" (click)="closeModal.emit()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>