<div class="component-choice">
    <div class="component-list">
        <ng-container *ngFor="let section of availableSections">
                <div class="title" (click)="toggleSection(section.function)">
                    <i class="hm-icon-folder"></i>{{section.function}}
                </div>
                <div *ngFor="let item of section.piOptions" [class.hide]="!item.isVisible || shouldHideSection(section.function)" class="list-item">
                    <div id="{{group}}-{{item.value}}" class="select-input" [class.no-data]="item.noDataMessage !== ''">
                            <input [checked]="item.isSelected" [value]="item.value" (change)="update(item.value)" type="radio" [name]="group"> {{item.text}}
                    </div>
                    <i placement="right" ngbTooltip="{{item.noDataMessage}}" *ngIf="item.noDataMessage !== ''" class="hm-icon-help"></i>
                </div>
        </ng-container>
    </div>
</div>