import { NgModule } from '@angular/core';
import { ChartTileModule } from '../tiles/chart-tile/chart-tile.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ResponsiveRepairsDashboardComponent } from './responsive-repairs/responsive-repairs.component';
import { DashboardTilesModule } from '../tiles/dashboard-tiles/dashboard-tiles.module';
import { ChartModule } from '../../highcharts/chart.module';
import { OptionsModule } from '../options/options.module';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { ExportService } from '../../services/export.service';
import { SharedModule } from '../shared/shared.module';
import { SectorScorecardComponent } from './sector-scorecard/sector-scorecard.component';
import { PipesModule } from '../../pipes/pipes.module';
import { EditTileModalComponent } from './edit-tile-modal/edit-tile-modal.component';
import { AnnualSummaryDashboardComponent } from './annual-summary/annual-summary-dashboard.component';
import { TenantSatisfactionDashboardComponent } from './tenant-satisfaction/tenant-satisfaction-dashboard.component';
import { GlobalAccountsDashboardComponent } from './global-accounts/global-accounts-dashboard.component';
import { QuarterlyPerformanceDashboardComponent } from './quarterly-performance/quarterly-performance.component';
import { JsReportService } from '../../services/js-report.service';
import { VfmDashboardComponent } from './vfm/vfm-dashboard.component';
import { NgbTooltipModule } from '../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { IconInfoTileComponent } from '../tiles/dashboard-tiles/icon-info-tile/icon-info-tile.component';
import { SmallIconInfoTileComponent } from '../tiles/dashboard-tiles/small-icon-info-tile/small-icon-info-tile.component';
import { DashboardSectionComponent } from '../tiles/dashboard-tiles/dashboard-section/dashboard-section.component';
import { RankedComboTileComponent } from '../tiles/dashboard-tiles/ranked-combo-tile/ranked-combo-tile.component';
import { SmallChartTileComponent } from '../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DualChartTileComponent } from '../tiles/dashboard-tiles/dual-chart-tile/dual-chart-tile.component';
import { TemplateHostDirective } from './bespoke/component-containers/dashboard-hosts/template-host.directive';
import { BespokeDashboardComponent } from './bespoke/bespoke-dashboard/bespoke-dashboard.component';
import { ColumnDashboardComponent } from './bespoke/component-containers/dashboard-hosts/column-dashboard/column-dashboard.component';
import { TilesColumnContainerComponent } from './bespoke/component-containers/dashboard-hosts/tiles-column-container/tiles-column-container.component';
import { EditBespokeModalComponent } from './bespoke/edit-bespoke-modal/edit-bespoke-modal.component';
import { ESGComponent } from './esg/esg.component';
import { TSMDashboardComponent } from './tsm/tsm-dashboard.component';

@NgModule({
    imports: [
        CommonModule,
        ChartTileModule,
        FormsModule,
        DashboardTilesModule,
        ChartModule,
        SharedModule,
        PipesModule,
        OptionsModule,
        NgbTooltipModule
    ],
    declarations: [
        ResponsiveRepairsDashboardComponent,
        DashboardHeaderComponent,
        SectorScorecardComponent,
        EditTileModalComponent,
        AnnualSummaryDashboardComponent,
        TenantSatisfactionDashboardComponent,
        GlobalAccountsDashboardComponent,
        QuarterlyPerformanceDashboardComponent,
        VfmDashboardComponent,
        BespokeDashboardComponent,
        TemplateHostDirective,
        ColumnDashboardComponent,
        TilesColumnContainerComponent,
        EditBespokeModalComponent,
        ESGComponent,
        TSMDashboardComponent
    ],
    providers: [
        ExportService,
        JsReportService
    ],
    entryComponents: [
        ColumnDashboardComponent,
        TilesColumnContainerComponent,
        IconInfoTileComponent,
        SmallIconInfoTileComponent,
        DashboardSectionComponent,
        RankedComboTileComponent,
        SmallChartTileComponent,
        DualChartTileComponent
    ]
})
export class DashboardsModule { }
