<div class="edit-item-modal edit-modal" id="edit-item-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content add-section">
                <div class="edit-modal-header">
                    <h5>Add Section</h5>
                </div>
                <div class="body">
                    <div class="component-choice">
                        <div class="component-list">
                            <div *ngFor="let item of options" [class.disabled]="!item.isSelectable" class="list-item">
                                <div class="select-input">
                                    <input [checked]="item.isSelected" [value]="item.value"
                                        (change)="update(item.value)" type="radio" name="radiogroup"> {{item.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-wrapper">
                    <div class="footer">
                        <button (click)="add()">Add</button>
                        <button (click)="cancel()" class="dark-button">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>