import { Injectable } from '@angular/core';
import { DateRangeService } from './date-range.service';
import { PeersService } from './peers.service';
import { JsReportPage } from '../models/export/js-report-page';
import { ReportDialogueSection } from '../models/report-dialogue-section';
import { PeerGroupType } from '../models/enum/peer-group-type';
import { JsReportPageState } from '../models/reports/js-report-page-state';
import { ReportDialogueComponent } from '../models/report-dialogue-component';
import { ReportSection } from '../models/reports/report-section';
import { ChartType } from '../models/enum/chart-type';

@Injectable()
export class JsReportService {
    currentLayoutSections: ReportSection[];

    breakdownChartTypes = [ChartType.annualStackedTrend, ChartType.tree, ChartType.pie, ChartType.summaryStackedColumn];

    constructor(private dateRangeService: DateRangeService, private peersService: PeersService) {
    }

    public buildVfmDashboard(mainContents: JsReportPage[], contentsMap: Map<string, string[]>) {
        const fullReport = new Array<JsReportPage>();

        fullReport.push(this.buildVfmContentsPage(contentsMap));
        fullReport.push(this.buildDateRangePage());
        fullReport.push(...this.buildFilterPage());

        mainContents.forEach(mc => mc.page = this.sanitiseHtml(mc.page));

        fullReport.push(...mainContents);

        return fullReport;
    }

    private buildVfmContentsPage(contentsMap: Map<string, string[]>) {
        let page = '<ul class="contents-list">';

        const arr = new Array<string>(200);
        const spacer = arr.join('.');
        const contentLineSpacer = `<div class="content-line-divider">${spacer}</div>`;

        let pageNum = 0;
        contentsMap.forEach((charts: string[], key: string) => {
            pageNum++;
            page += `<li><div class="section-name">${key}</div><div class="contents-page-number">${pageNum}</div>${contentLineSpacer}<ul>`;
            pageNum++;

            charts.forEach((value: string) => {
                page += `<li>${value}</li>`;
            });

            page += `</ul></li>`;
        });

        page += '</ul>';
        return new JsReportPage(this.addMetaDataPageStructure('Contents', page));
    }

    // TODO : Add new type JsReport - Encapsulate most of these functions in there
    public buildFinalReport(mainContents: JsReportPage[], reportDialogSection: ReportDialogueSection[], layoutSections: ReportSection[]): any {
        const fullReport = new Array<JsReportPage>();
        this.currentLayoutSections = layoutSections;

        fullReport.push(...this.buildContentsPage(reportDialogSection));
        fullReport.push(this.buildDateRangePage());
        fullReport.push(...this.buildFilterPage());

        mainContents.forEach(mc => mc.page = this.sanitiseHtml(mc.page));

        fullReport.push(...mainContents);

        return fullReport;
    }

    public sanitiseHtml(element: string): string {
        const whitespaceRemoved = element.replace(/^\s*|\n|<!--[\s\S]*?-->|lg-/gm, '');
        const poundsEscaped = whitespaceRemoved.replace(/£/gm, '&pound;');
        const exportTileHeight2 = poundsEscaped.replace(/tile-height-2/gm, 'tile-height-2 export-tile-2');
        const exportTileHeight8 = exportTileHeight2.replace(/tile-height-8/gm, 'tile-height-8 export-tile-8');
        const exportTileHeight3 = exportTileHeight8.replace(/tile-height-3/gm, 'tile-height-3 export-tile-3');
        const replacedZeroSpaceChar = exportTileHeight3.replace(/​/gm, '&ZeroWidthSpace;');

        return replacedZeroSpaceChar;
    }

    public startDownload(fileName: string, file: Blob) {
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveBlob(file, fileName);
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }

    private buildDateRangePage(): JsReportPage {
        let html = '<div class="supporting-text">This report uses data for the following periods. Note that the data can be sourced from various different modules, ';
        html += 'and the period may differ between modules. As a default HouseMark will provide you with the most recent data for your organisation.</div>';
        html += '<ul class="contents-list">';

        this.dateRangeService.currentSections.sections.forEach(section => {
            const selectedOptionText = section.options.find(opt => opt.value === section.selectedOption)!.text;
            let supportingText = '';
            if (section.extraOption && section.extraOption.isSelected) {
                if (section.text === 'STAR') {
                    supportingText = ' - Allow data up to 2 years old';
                } else {
                    supportingText = ' - Allow data from previous year';
                }
            }
            html += `<li>${section.text} - ${selectedOptionText}${supportingText}</li>`;
        });

        html += '</ul>';

        return new JsReportPage(this.addMetaDataPageStructure('Date Range', html));
    }

    private addMetaDataPageStructure(pageName: string, pageContent: string) {
        return `<div class="report-page">
                <div class="meta-data-page">
                <span class="header">${pageName}</span>
                ${pageContent}
                </div>
                </div>`;
    }

    private buildFilterPage(): JsReportPage[] {
        if (this.peersService.peerGroupType === PeerGroupType.Bespoke) {
            let html = '<div class="supporting-text">The peers for this report have been manually selected and include the following organisations: </div>';

            const selectedPeers = this.peersService.peers.filter(peer => peer.includedInGroup).map(peer => peer.organisationName);
            const chunkSize = 170;
            const chunkCount = Math.ceil(selectedPeers.length / chunkSize);
            const pages = new Array<JsReportPage>();

            for (let i = 0; i < chunkCount; i++) {
                const chunk = selectedPeers.splice(0, chunkSize);

                html += `<div class="contents-list">${chunk.join(', ')}</div>`;

                pages.push(new JsReportPage(this.addMetaDataPageStructure('Peer group', html)));

                html = '';
            }

            return pages;
        }

        return [this.buildDynamicFiltersPage()];
    }

    private buildDynamicFiltersPage(): JsReportPage {
        const peers = this.peersService.currentFilters;
        let html = '<div class="supporting-text">This report uses the following peer group parameters. ';
        html += 'The organisations you are compared to within this report are only those that fall within these parameters and have provided valid data for the date range(s) selected.</div>';
        html += '<ul class="contents-list">';

        html += `<li>Stock - Between ${peers.stock.minValue} and ${peers.stock.maxValue}</li>`;

        if (peers.regions.find(reg => reg.text.toLocaleLowerCase() === 'all')!.isSelected) {
            html += `<li>Regions - All regions</li>`;
        } else {
            html += `<li>Regions - ${peers.regions.filter(reg => reg.isSelected).map(reg => reg.text).join(', ')}</li>`;
        }

        if (peers.organisationTypes.find(ot => ot.text.toLocaleLowerCase() === 'all')!.isSelected) {
            html += `<li>Organisation Types - All types</li>`;
        } else {
            html += `<li>Organisation Types - ${peers.organisationTypes.filter(ot => ot.isSelected).map(ot => ot.text).join(', ')}</li>`;
        }

        if (peers.dlo.find(dlo => dlo.text.toLocaleLowerCase() === 'all')!.isSelected) {
            html += `<li>DLO - Yes and No</li>`;
        } else {
            html += `<li>DLO - ${peers.dlo.filter(dlo => dlo.isSelected).map(dlo => dlo.text).join(', ')}</li>`;
        }

        html += `<li>Is Dynamic - ${this.peersService.peerGroupType === PeerGroupType.Dynamic ? 'Yes' : 'No'}</li>`;
        html += '</ul>';

        return new JsReportPage(this.addMetaDataPageStructure('Peer group', html));
    }

    private buildContentsPage(reportDialogSections: ReportDialogueSection[]): JsReportPage[] {
        const selectedSections = reportDialogSections.filter(rds => rds.isSelected && rds.components.some(comp => comp.isSelected));

        const pageState = new JsReportPageState('<ul class="contents-list">');

        for (let i = 0; i < selectedSections.length; i++) {
            this.buildSectionsHtml(selectedSections, i, pageState);
        }

        pageState.html += '</ul>';
        pageState.pages.push(new JsReportPage(this.addMetaDataPageStructure('Contents', pageState.html)));
        return pageState.pages;
    }

    private buildSectionsHtml(selectedSections: ReportDialogueSection[], i: number, pageState: JsReportPageState) {
        const section = selectedSections[i];
        const selectedComponents = section.components.filter(comp => comp.isSelected);
        const reportLayoutSection = this.currentLayoutSections.find(sect => sect.id === section.id);        
        const arr = new Array<string>(200);
        const spacer = arr.join('.');
        const contentLineSpacer = `<div class="content-line-divider">${spacer}</div>`;

        pageState.pageNum++;
        pageState.currentListItemCount++;
        pageState.html += `<li><div class="section-name">${section.sectionName}</div><div class="contents-page-number">${pageState.pageNum}</div>${contentLineSpacer}<ul>`;

        this.buildComponentSubItem(selectedComponents, pageState, reportLayoutSection!);

        if (i + 1 !== selectedSections.length && this.shouldAddNewContentsPage(selectedSections[i + 1], pageState.currentListItemCount)) {
            this.closeExistingPageAndStartNew(pageState);
        }
    }

    private closeExistingPageAndStartNew(pageState: JsReportPageState) {
        pageState.html += '</ul>';
        pageState.pages.push(new JsReportPage(this.addMetaDataPageStructure('Contents', pageState.html)));
        pageState.currentListItemCount = 0;
        pageState.html = '<ul class="contents-list">';
    }

    private buildComponentSubItem(selectedComponents: ReportDialogueComponent[], pageState: JsReportPageState, reportSection: ReportSection) {
        const chunkSize = 3;
        const chunksCount = Math.ceil(selectedComponents.length / chunkSize);
        pageState.pageNum += chunksCount;

        for (let j = 0; j < chunksCount; j++) {
            const startIndex = j * chunkSize;
            const endIndex = startIndex + 3;
            const chunk = selectedComponents.slice(startIndex, endIndex);

            pageState.currentListItemCount += chunk.length;
            chunk.forEach((rdc: ReportDialogueComponent) => {
                const currentLayoutFromComponent = reportSection.selectedComponents[rdc.index];

                let fullName = rdc.componentName;
                if (this.breakdownChartTypes.includes(ChartType[currentLayoutFromComponent.selectedKpiChartType])) {
                    fullName += ' (breakdown)';
                }

                pageState.html += `<li>${fullName}</li>`;
            });
        }

        pageState.html += '</ul></li>';
    }

    private shouldAddNewContentsPage(reportDialogSection: ReportDialogueSection, currentItemCount: number): boolean {
        const itemCountLimit = 30;

        // we add 1 for the section name being a list item in the <ul>
        return reportDialogSection.components.filter(comp => comp.isSelected).length + 1 + currentItemCount >= itemCountLimit;
    }

    public getExportCalcRefs(modalSections: ReportDialogueSection[]): number[] {
        const calcRefs = new Array<number>();
        modalSections.forEach(rds => {
            if (rds.isSelected) {
                rds.components = rds.components.filter(rdc => rdc.isSelected);
                if (rds.components.length > 0) {
                    calcRefs.push(...rds.components.map(rdc => rdc.calcRef));
                }
            }
        });

        return calcRefs;
    }
}

