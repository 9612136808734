import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { OrganisationService } from './services/organisation.service';
import { Organisation } from './models/organisation';
import { NavigationService } from './services/navigation.service';
import { DashboardSelectionComponent } from './components/dashboard-selection/dashboard-selection.component';
import { ReportsSelectionComponent } from './components/reports-selection/reports-selection.component';
import { OrganisationSelectionComponent } from './components/organisation-selection/organisation-selection.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { BaseNavigationComponent } from './components/shared/base-navigation/base-navigation';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { HeadersService } from './services/headers.service';
import { UserData } from './models/identity/user-data';
import { PeerSelectionComponent } from './components/sidebar/peer-selection/peer-selection.component';
import { MyAccountService } from './services/my-account.service';
import { ServiceMessageService } from './services/service-message.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

type navigationStop = NavigationEnd | NavigationCancel | NavigationError;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'app';
    currentOrganisationName = '';
    componentLookup = new Map<string, BaseNavigationComponent>();
    storage: Storage;

    @ViewChild(DashboardSelectionComponent) dashboardSelectionComponent: DashboardSelectionComponent;
    @ViewChild(ReportsSelectionComponent) reportsSelectionComponent: ReportsSelectionComponent;
    @ViewChild(OrganisationSelectionComponent) organisationSelectionComponent: OrganisationSelectionComponent;
    @ViewChild(MyAccountComponent) myAccountComponent: MyAccountComponent;
    @ViewChild(PeerSelectionComponent) peerSelectionComponent: PeerSelectionComponent;

    constructor(private oidcSecurityService: OidcSecurityService, private navigationService: NavigationService, private router: Router,
        public organisationService: OrganisationService, private headersService: HeadersService, private appInsightsService: ApplicationInsights,
        private myAccountService: MyAccountService, private serviceMessageService: ServiceMessageService, private toastr: ToastrService) {
        this.storage = sessionStorage;

        oidcSecurityService.isAuthenticated$.subscribe((authorizationResult: AuthenticatedResult) => {
            this.onAuthorizationResultComplete(authorizationResult);
        });

        appInsightsService.loadAppInsights();

        router.events.pipe(
            filter((e: Event): e is NavigationStart => e instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            appInsightsService.startTrackPage(event.url);
            navigationService.closeAllTabs();
        });

        router.events.pipe(
            filter(
                (e: Event): e is navigationStop => (
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
            ))
        ).subscribe((event: NavigationEnd | NavigationCancel | NavigationError) => {
                appInsightsService.stopTrackPage(event.url);
            });
    }

    logOff() {
        this.oidcSecurityService.logoff();
    }

    onAuthorizationResultComplete(authResult: AuthenticatedResult) {
        if (!authResult.isAuthenticated) {
            this.router.navigate(['/unauthorized']);
        }

        this.oidcSecurityService.getUserData().subscribe((data: UserData) => {
            Object.setPrototypeOf(data, UserData.prototype);
            if (!data || !data.hasAccess()) {
                this.router.navigate(['/unauthorized']);
            }

            if (data.sub) {
                this.appInsightsService.clearAuthenticatedUserContext();
                this.appInsightsService.setAuthenticatedUserContext(data.sub, data.preferred_username, true);
            }

            this.oidcSecurityService.getAccessToken().subscribe((token) => {
                this.headersService.setHeaders(token);
                const redirectPath = this.storage.getItem('redirectUrl');
                this.storage.removeItem('redirectUrl');

                if (!redirectPath) return;

                this.router.navigate([redirectPath]);
            });
            
        });
    }

    ngOnInit() {
        this.organisationService.landlordChanged.subscribe((organisation: Organisation) => {

            if (this.currentOrganisationName !== '') {
                const orgSelectItem = document.getElementById('organisation-selection-item');
                orgSelectItem!.click();
            }

            this.currentOrganisationName = organisation.name;

            this.myAccountService.updateDefaultsDisplayedToUser(organisation.wKey);
        });

        this.serviceMessageService.currentServiceMessage.pipe(
            filter(message => message ? true : false)
        ).subscribe((message: string) => {
            this.toastr.info(message, '', { tapToDismiss: true, disableTimeOut: true, positionClass: 'toast-top-center'  } );
        });
    }

    ngAfterViewInit() {
        this.componentLookup.set('dashboardSelection', this.dashboardSelectionComponent);
        this.componentLookup.set('reportsSelectionComponent', this.reportsSelectionComponent);
        this.componentLookup.set('organisationSelection', this.organisationSelectionComponent);
        this.componentLookup.set('myAccount', this.myAccountComponent);
    }

    public toggleTab(event: MouseEvent, componentLookupKey: string): void {
        const classList = (<HTMLInputElement>event.target).classList;
        if (classList.contains('dropdown-toggle') || classList.contains('dropdown-full-width-content')) {
            const component = this.componentLookup.get(componentLookupKey);
            this.navigationService.toggleComponent(component!);
        }
    }

    isPageOpen(componentName: string): boolean {
        const component = this.componentLookup.get(componentName);
        return component !== undefined && component.isShowing;
    }

    ensureSlidersAreSetupInPeerSelection() {
        this.peerSelectionComponent.ensureSlidersAreSetup();
    }
}
