import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
    private _registeredComponents = new Set<CanShowAndHideTemplate>();

    public register(...components: Array<CanShowAndHideTemplate>) {
        components.forEach(component => {
            this._registeredComponents.add(component);
        });
    }

    public unregister(...components: Array<CanShowAndHideTemplate>) {
        components.forEach(component => {
            if (!this._registeredComponents.has(component)) this._registeredComponents.add(component);
        });
    }

    public toggleComponent(component: CanShowAndHideTemplate) {
        this._registeredComponents.forEach(rc => {
            if (rc === component && !rc.isShowing) rc.show();
            else rc.hide();
        });
    }

    public toggleComponentByName(componentName: string) {
        this._registeredComponents.forEach(rc => {
            if (rc.name === componentName && !rc.isShowing) rc.show();
            else rc.hide();
        });
    }

    public isComponentShowing(componentName: string): boolean {
        let showing = false;
        this._registeredComponents.forEach(component => {
            if (component.name === componentName) showing = component.isShowing;
        });

        return showing;
    }

    public closeAllTabs(): void {
        this._registeredComponents.forEach(component => {
            component.hide();
        });
    }
}

export interface CanHideTemplate {
    hide(): void;
}

export interface CanShowTemplate {
    show(): void;
}

export interface CanShowAndHideTemplate extends CanShowTemplate, CanHideTemplate {
    name: string;
    isShowing: boolean;
}
