<div class="ranking" [ngClass]="[infoTile.quartileClass]">
    <div class="ranking-number">
        <span class="ranking-value"></span>
        <i class="fas fa-exclamation"></i>
    </div>

    <div class="ranking-hover" *ngIf="infoTile.quartileSummary">
        <div class="ranking-explanation">
            <span>{{infoTile.quartileSummary.quartileRankSummary}}</span>
            
            <span *ngFor="let condition of infoTile.quartileSummary.quartileConditions">
                {{condition}}
            </span>
        </div>
        <div class="arrow-down"></div>
    </div>
</div>

<div class="body">
    <div class="title">
        {{infoTile.title}}
    </div>
    <div class="icon-tile-content" *ngIf="infoTile.latestValue">
        <div class="value">
            <i [ngClass]="infoTile.icon"></i>
            <span class="number">{{infoTile.latestValue}}</span>
            <span class="descriptor"> {{infoTile.description}}</span>
        </div>
        <div class="difference" *ngIf="displayDifference">
            <div *ngIf="infoTile.differenceType === DifferenceType.UpFrom">
                <i class="fas fa-sort-up"></i>
                <span class="difference-value">Up from {{ infoTile.previousValue }}</span>
            </div>
            <div *ngIf="infoTile.differenceType === DifferenceType.DownFrom">
                <i class="fas fa-sort-down"></i>
                <span class="difference-value">Down from {{ infoTile.previousValue }}</span>
            </div>
            <div *ngIf="infoTile.differenceType === DifferenceType.NoChange">
                <i class="fas fa-minus"></i>
                <span class="difference-value">No change from previous year</span>
            </div>
            <div class="null-previous-year" *ngIf="infoTile.differenceType === DifferenceType.NoDataPrevious">
                <span class="difference-value">No data available from previous year</span>
            </div>
        </div>
    </div>
    <div class="no-data" *ngIf="!infoTile.latestValue">
        No data available. Your organisation has not provided data for this measure
    </div>
</div>