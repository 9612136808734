import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SimpleLoadOption } from '../../../models/options/simple-load-option';

@Component({
    selector: 'simple-load-item-modal',
    templateUrl: './simple-load-item-modal.component.html'
})
export class SimpleLoadItemModalComponent implements OnInit {

    @Output() closeModal = new EventEmitter<any>();
    @Output() load = new EventEmitter<any>();
    @Input() items: Array<SimpleLoadOption<number>> = [];
    @Input() context: string = '';
    constructor() { }

    ngOnInit() {
        this.items.forEach(item => item.isSelected = false);
    }

    loadButtonDisabled() {
        this.items.every(item => item.isSelected === false);
    }

    selectItem(value: number) {
        this.items.forEach(item => item.isSelected = item.value === value);
    }

}
