import { ReportDialogueComponent } from './report-dialogue-component';


export class ReportDialogueSection {
    sectionName: string;
    isSelected = true;
    components: ReportDialogueComponent[];
    hideComponents = false;
    id: number;
}
