import { PeerGroupType } from '../enum/peer-group-type';
import { LayoutType } from '../enum/layout-type';

export abstract class MyAccountListItem {
    id: number = 0;
    _name: string = '';
    abstract get type(): (PeerGroupType | LayoutType);
    creationDate: Date | null;
    isVisible = true;
    isDefault = false;
    isOwnedByUser = false;
    isHouseMarkDefinedDefault: boolean = false;

    abstract get name();
    abstract set name(value: string);
}

export class PeerGroupListItem extends MyAccountListItem {
    get type(): PeerGroupType {
        return this.isDynamic ? PeerGroupType.Dynamic : PeerGroupType.Bespoke;
    }

    get name(): string {
        return this.isDefault && !this.isOwnedByUser ? 'Default peer group created by your organisation' : this._name;
    }

    set name(newName: string) {
        this._name = newName;
    }

    constructor(public filterId: number, public isDynamic: boolean) {
        super();
    }
}

export class LayoutListItem extends MyAccountListItem {
    get type(): LayoutType {
        return LayoutType[this.layoutType];
    }

    get name(): string {
        return this.isDefault && !this.isOwnedByUser ? 'Default layout created by your organisation' : this._name;
    }

    set name(newName: string) {
        this._name = newName;
    }

    constructor(public layoutId: number, public layoutType: string, public layoutName: string) {
        super();
    }
}
