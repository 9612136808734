import { Component, ViewChildren, ChangeDetectorRef, QueryList } from "@angular/core";
import { ContainerComponent } from "../../../../../../interfaces/container-component";
import { TemplateHostDirective } from "../template-host.directive";
import { ColumnPosition } from "../../../../../../models/enum/column-position";
import { DynamicTile } from "../../../../../../models/dashboard/bespoke/dynamic-tile";
import { Subject } from "rxjs";
import { TileMetaData } from "../../../../../../models/tile-metadata";
import { BespokeDashboardTile, BaseBespokeDashboardTile } from "../../../../../../interfaces/bespoke-dashboard-tile";
import { Tile } from "../../../../../../models/dashboard/tile";
import { AvailablePi } from "../../../../../../models/dashboard/available-pi";
import { BaseTile } from "src/app/components/tiles/dashboard-tiles/base-tile/base-tile";

@Component({
    selector: 'column-components-container',
    templateUrl: './tiles-column-container.component.html'
})
export class TilesColumnContainerComponent implements ContainerComponent {    
    @ViewChildren(TemplateHostDirective) templateHosts!: QueryList<TemplateHostDirective>;
    columnClass: string = '';
    component = TilesColumnContainerComponent;
    dummyArray = new Array();
    itemRefToTileMap = new Map<string, BespokeDashboardTile>();

    constructor(private changeDetector: ChangeDetectorRef) { }

    buildComponents(columnPosition: ColumnPosition, tiles: Array<DynamicTile>): void {
        this.columnClass = columnPosition === ColumnPosition.Left ? 'col-left' : 'col-right';
        this.dummyArray = new Array(tiles.length);
        this.changeDetector.detectChanges();

        tiles.forEach((tile: DynamicTile, tileIndex: number) => {
            const templateHost =  this.templateHosts.find((directive: TemplateHostDirective, index: number) => tileIndex === index)!;

            const componentRef = templateHost.viewContainerRef.createComponent(tile.type);
            componentRef.instance.dashboardItemRefs = tile.itemRefs;

            const tiles = new Array<Tile>();
            tile.itemRefs.forEach((dashboardItemRef: string) => {
                const newTile = new Tile();
                newTile.selected = new AvailablePi();
                newTile.selected.selectedKpiChartType = 'column';
                newTile.selected.calcRef = 0;
                newTile.dashboardItemRef = dashboardItemRef;
                newTile.data = null;

                tiles.push(newTile);
            });

            let firstItemRef = tile.itemRefs[0];

            if(firstItemRef.endsWith('a') || firstItemRef.endsWith('b')) {
                firstItemRef = firstItemRef.substring(0, firstItemRef.length-1);
            }

            this.itemRefToTileMap.set(firstItemRef, componentRef.instance);
            componentRef.instance.initializeModel(...tiles);            
        });
    }

    getEditEvents(): Array<Subject<BaseTile>> {
        const editEvents = new Array<Subject<BaseTile>>();

        this.itemRefToTileMap.forEach((tile: BaseBespokeDashboardTile) => {
            editEvents.push(tile.openEditEvent);
        });
        
        return editEvents;
    }

    getTileMetaData(): Array<TileMetaData> {
        const metaDatas = new Array<TileMetaData>();

        this.itemRefToTileMap.forEach((tile: BespokeDashboardTile, key: string) =>{
            metaDatas.push(...tile.getComponentsInTile());
        });

        return metaDatas;
    }

    setTileData(tiles: Array<Tile>): void {
        this.itemRefToTileMap.forEach((bespokeTile: BespokeDashboardTile, mapKey: string) => {
            const tileBeingUpdated = tiles.filter(tile => tile.dashboardItemRef.startsWith(mapKey));

            if(!tileBeingUpdated || tileBeingUpdated.length == 0) {
                bespokeTile.clearTile();
                return;
            } 

            bespokeTile.updateFromBespokeDashboard(...tileBeingUpdated);
        });
    }

    clearTileData(): void {
        this.itemRefToTileMap.forEach((tile: BespokeDashboardTile, key: string) =>{
            tile.clearTile();
        });
    }
}

