import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IconInfoTileComponent } from './icon-info-tile/icon-info-tile.component';
import { ChartModule } from '../../../highcharts/chart.module';
import { OptionsModule } from '../../options/options.module';
import { SmallChartTileComponent } from './small-chart-tile/small-chart-tile.component';
import { RankedComboTileComponent } from './ranked-combo-tile/ranked-combo-tile.component';
import { LargeInfoTileModule } from '../large-info-tile/large-info-tile.module';
import { DualChartTileComponent } from './dual-chart-tile/dual-chart-tile.component';
import { SmallIconInfoTileComponent } from './small-icon-info-tile/small-icon-info-tile.component';
import { DashboardSectionComponent } from './dashboard-section/dashboard-section.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ChartModule,
        OptionsModule,
        LargeInfoTileModule
    ],
    declarations: [
        IconInfoTileComponent,
        SmallChartTileComponent,
        RankedComboTileComponent,
        DualChartTileComponent,
        SmallIconInfoTileComponent,
        DashboardSectionComponent
    ],
    exports: [
        IconInfoTileComponent,
        SmallChartTileComponent,
        RankedComboTileComponent,
        DualChartTileComponent,
        DashboardSectionComponent
    ]
})
export class DashboardTilesModule { }
