<div class="tile tile-height-2">
    <div class="info-tile info-icon-tile icon-hover">

        <div class="icons-hover-wrapper">
            <div class="icons-hover-wrapper-inner bottom">
                <i class="far fa-edit" (click)="openEdit()"></i>
            </div>
        </div>

        <ng-container *ngIf="model && data">
            <small-icon-info-tile [infoTile]="data" [displayDifference]="true"></small-icon-info-tile>
        </ng-container>
    </div>
</div>
