export class DateOption {
    value: number;
    text: string;
}

export class PeerDateRange {
    title: string;
    dateOptions: DateOption[];
    id: number;
    selectedFrom: number;
    selectedTo: number;
    error = false;
    disabled = true;

    constructor(id: number, title: string) {
        this.id = id;
        this.title = title;
        this.dateOptions = new Array<DateOption>();
    }
}
