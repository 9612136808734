import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SectionChartIndex } from '../../../models/reports/section-chart-index';

@Component({
    selector: 'delete-report-modal',
    templateUrl: './delete-report-item-modal.component.html'
})
export class DeleteReportItemModalComponent {
    @Input() model?: SectionChartIndex;
    @Output() closeModal = new EventEmitter<any>();
    @Output() deleteItem = new EventEmitter<SectionChartIndex>();

    constructor() { }

    delete() {
        this.deleteItem.emit(this.model);
    }

    cancel() {
        this.closeModal.emit();
    }
}
