<div class="edit-item-modal edit-modal" id="edit-item-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="edit-modal-header">
                    <h5>Select PI and chart type</h5>
                    <div class="header-search">
                        <input class="search" placeholder="Search performance indicators" [ngModel]="filterText" (ngModelChange)="filterPis($event)">
                        <i class="hm-icon-search"></i>
                    </div>
                </div>
                <div class="body">
                    <div class="choice-wrapper pi-choice-wrapper" [class.full-width]="isInfoTile()">
                        <component-options [availableComponents]="model.piOptions" (selectionChanged)="selectComponent($event)"></component-options>
                    </div>

                    <div class="chart-choices" [class.add-overflow]="largeModal()" *ngIf="!isInfoTile()">
                        <div class="choice-wrapper" *ngFor="let ct of model.chartsTypes; let i = index">
                            <span class="title">Select {{ i+1 | ordinal}} chart</span>
                            <div class="chart-type-choice">
                                <chart-type-options [componentCharts]="ct" [chartIndex]="i" (selectionChanged)="selectChartType($event)"></chart-type-options>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-wrapper">
                    <div class="footer">
                        <div [class.disabled]="disableApply()">
                            <button (click)="applyChoices()">Apply</button>
                        </div>
                        <button (click)="cancel()" class="dark-button">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>