import { Component } from '@angular/core';
import { BaseNavigationComponent } from '../../shared/base-navigation/base-navigation';
import { NavigationService } from '../../../services/navigation.service';
import { OrganisationService } from '../../../services/organisation.service';
import { environment } from '../../../../environments/environment';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sidebar-help',
    templateUrl: './help.component.html'
})
export class HelpComponent extends BaseNavigationComponent {
    helpLinks = environment.help_links;
    constructor(navigationService: NavigationService, organisationService: OrganisationService) {
        super(navigationService, 'HelpComponent', organisationService);
    }
}
