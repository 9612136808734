import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const clone = req.clone({ 
            headers: req.headers
               .set('Pragma', 'no-cache')
               .set('Cache-Control', 'no-cache')
               .set('Expires', '0')
               .set('If-Modified-Since', '0')
         });

        return next.handle(clone);
    }

}
