import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { LayoutService } from '../../../services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { ChartsService } from '../../../services/charts.service';
import { PeersService } from '../../../services/peers.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { SmallChartTileComponent } from '../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'sector-scorecard-dashboard',
    templateUrl: './sector-scorecard.component.html'
})
export class SectorScorecardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.SectorScorecard;
    dashboardHTMLId = 'sector-scorecard-dashboard-body';
    portraitExport = false;

    @ViewChild('SSDashChart1') left1Tile!: SmallChartTileComponent;
    @ViewChild('SSDashChart2') left2Tile!: SmallChartTileComponent;
    @ViewChild('SSDashChart3') left3Tile!: SmallChartTileComponent;
    @ViewChild('SSDashChart4') right1Tile!: SmallChartTileComponent;
    @ViewChild('SSDashChart5') right2Tile!: SmallChartTileComponent;
    @ViewChild('SSDashChart6') right3Tile!: SmallChartTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['SSDashChart1', this.left1Tile],
            ['SSDashChart2', this.left2Tile],
            ['SSDashChart3', this.left3Tile],
            ['SSDashChart4', this.right1Tile],
            ['SSDashChart5', this.right2Tile],
            ['SSDashChart6', this.right3Tile]
        ]);

        this.Init();
    }

}
