import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class HeadersService {
    headers: HttpHeaders;
    headersSet = new Subject<void>();

    constructor() {
        this.setHeaders();
    }

    public getHeaders(): HttpHeaders {
        return this.headers;
    }

    public setHeaders(token?: string) {
        this.headers = new HttpHeaders().set('Accept', 'application/json')
            .set('Access-Control-Allow-Origin', 'true')
            .set('Content-Type', 'application/json');

        if (token) {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.set('Authorization', tokenValue);
            this.headersSet.next();
        }
    }
}
