import { Component } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { BaseNavigationComponent } from '../shared/base-navigation/base-navigation';
import { OrganisationService } from '../../services/organisation.service';
import { Router } from '@angular/router';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: 'dashboard-selection',
    templateUrl: './dashboard-selection.component.html'
})
export class DashboardSelectionComponent extends BaseNavigationComponent {
    constructor(navigationService: NavigationService, organisationService: OrganisationService, private router: Router,
        private layoutService: LayoutService) {
        super(navigationService, 'DashboardSelectionComponent', organisationService);
    }

    navigate(route: string) {
        this.layoutService.dashboardLayoutId = null;
        this.router.navigateByUrl(route);
    }
}
