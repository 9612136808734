<div id="report">
    <report-header (openReorderModal)="openReorderModal()" (openDownloadModal)="openDownloadModal()" [reportName]="reportName" [reportId]="reportId"></report-header>
    <div id="report-body" class="report-body">
        <div *ngIf="reportLayout">
            <div *ngFor="let section of reportLayout.sections" [class.hide]="noChartsSelected(section.id)" class="report-section">
                <div class="container-fluid">
                    <div class="dashboard-body">
                        <div class="col-12">
                            <div class="section-name">
                                <span>{{section.name}}</span>
                                <i class="hm-icon-arrow-left" [class.hide]="!isSectionHidden(section.id)" (click)="toggleSection(section.id)"></i>
                                <i class="hm-icon-arrow" [class.hide]="isSectionHidden(section.id)" (click)="toggleSection(section.id)"></i> 
                            </div>

                            <div class="report-charts-container" id="section-{{section.id}}">
                                <div *ngFor="let component of section.selectedComponents; index as i" id="{{section.id}}-{{i}}">
                                    <chart-tile [options]="component.data" [section]="section.id" [index]="i" [definition]="component.definition" (openDelete)="openDeleteModal($event)"
                                        (openEdit)="openEditModal($event)" [units]="component.units"></chart-tile>
                                </div>
                                <button class="add-chart" (click)="addChart(section.id)">Add chart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="report-section" *ngIf="!allSectionsPopulated()">
                <div class="container-fluid">
                    <div class="col-12">
                        <div class="add-section">
                            <button class="add-section" (click)="openAddSection()">Add section</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-report-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit($event)" [model]="editOptions"></edit-report-modal>
<delete-report-modal *ngIf="showDeleteModal" [model]="deleteModel" (deleteItem)="deleteItem($event)" (closeModal)="closeDeleteModal()"></delete-report-modal>
<add-report-section-modal *ngIf="showAddSectionModal" [options]="sectionOptions" (closeAddSectionModal)="closeAddSection()" (addSection)="addSection($event)" ></add-report-section-modal>
<reorder-report-dialogue *ngIf="showReorderModal" [dialogueItems]="modalSections" (reorder)="saveOrder()" (closeModal)="closeReorderModal()"></reorder-report-dialogue>
<download-report-dialogue *ngIf="showDownloadModal" [dialogueItems]="modalSections" (closeModal)="closeDownloadModal()" (downloadReport)="downloadReport($event)" ></download-report-dialogue>
