import { NgModule } from '@angular/core';
import { MyAccountComponent } from './my-account.component';
import { ManageLayoutsComponent } from './manage-layouts/manage-layouts.component';
import { ManagePeerGroupsComponent } from './manage-peer-groups/manage-peer-groups.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from './admin/admin.component';
import { ServiceMessageService } from '../../services/service-message.service';


@NgModule({
    declarations: [
        MyAccountComponent,
        ManageLayoutsComponent,
        ManagePeerGroupsComponent,
        AdminComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbTooltipModule
    ],
    exports: [
        MyAccountComponent
    ],
    providers: [
        ServiceMessageService
    ]
})
export class MyAccountModule { }
