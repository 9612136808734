import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';
import { SpinnerService } from './spinner.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        SpinnerService
    ],
    declarations: [
        SpinnerComponent
    ],
    exports: [
        SpinnerComponent
    ]
})
export class SpinnerModule {

}
