<div class="tile tile-height-3">
    <div class="small-chart-tile info-icon-tile icon-hover">
        <div class="icons-hover-wrapper">
            <div class="icons-hover-wrapper-inner bottom">
                <i class="far fa-edit" (click)="edit()"></i>
            </div>
        </div>

        <highcharts-chart *ngIf="!noData && chart" [chart]="chart" class="tile-chart"></highcharts-chart>
        <small-icon-info-tile *ngIf="infoTile" [infoTile]="infoTile" [displayDifference]="true"></small-icon-info-tile>
    </div>
</div>