import { Injectable } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

@Injectable()
export class SpinnerService {
    spinnerComponents = new Set<SpinnerComponent>();
    spinnerComponentCallCount = new Map<string, number>();

    _register(spinner: SpinnerComponent) {
        this.spinnerComponents.add(spinner);

        if (!this.spinnerComponentCallCount.has(spinner.name)) {
            this.spinnerComponentCallCount.set(spinner.name, 0);
        }
    }

    _unregister(spinner: SpinnerComponent) {
        this.spinnerComponents.delete(spinner);

        if (this.spinnerComponentCallCount.has(spinner.name)) {
            this.spinnerComponentCallCount.delete(spinner.name);
        }
    }

    show(name: string, title?: string, body?: string) {
        const countMapValue = this.spinnerComponentCallCount.get(name);

        if(countMapValue === undefined) return;

        this.spinnerComponents.forEach(component => {
            if (component.name === name) {

                if (title) {
                    component.title = title;
                    component.body = body;
                }

                component.show = true;
                const count = countMapValue + 1;
                this.spinnerComponentCallCount.set(name, count);
            }
        });
    }

    hide(name: string, clearText?: boolean) {
        this.spinnerComponents.forEach(component => {
            if (component.name === name) {
                let count = this.spinnerComponentCallCount.get(name) ?? 0;

                if (clearText === true) component.clearText();
                if (count > 0) {
                    count -= 1;
                    this.spinnerComponentCallCount.set(name, count);
                }

                if (count === 0) {
                    component.show = false;
                }
            }
        });
    }
}
