import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ComponentRef,
    AfterViewInit,
} from '@angular/core';
import { TemplateHostDirective } from '../component-containers/dashboard-hosts/template-host.directive';
import { BespokeDashboardProvider } from '../component-containers/providers/bespoke-dashboard.provider';
import { DashboardType } from '../../../../models/enum/dashboard-type';
import { DashboardStyle } from '../../../../models/enum/dashboard-style';
import { ToastrService } from 'ngx-toastr';
import { LayoutService } from '../../../../services/layout.service';
import { BespokeDashboardLayout } from '../../../../models/dashboardLayout';
import { AnalyseTileSection } from '../../../../models/analyse/analyse-tile-section';
import { EditAnalyseTileSection } from '../../../../models/analyse/edit-analyse-tile-section';
import { filter, merge, Observable, Subscription, take } from 'rxjs';
import { PeersService } from '../../../../services/peers.service';
import { BespokeDashboard } from '../../../../models/dashboard/bespoke/bespoke-dashboard';
import { ChartsService } from '../../../../services/charts.service';
import { SpinnerService } from '../../../spinner/spinner.service';
import { Tile } from '../../../../models/dashboard/tile';
import { AvailablePi } from '../../../../models/dashboard/available-pi';
import { EditAnalyseTileComponent } from '../../../../models/analyse/edit-analyse-tile-component';
import { BespokeDashboardTile } from '../../../../interfaces/bespoke-dashboard-tile';
import { PeerGroupType } from '../../../../models/enum/peer-group-type';
import { DateRangeService } from '../../../../services/date-range.service';
import { MyAccountService } from '../../../../services/my-account.service';
import { UserLayout } from '../../../../models/user-preferences/user-layout';
import { UserPeerGroups } from '../../../../models/user-preferences/user-peer-groups';
import { LayoutType } from '../../../../models/enum/layout-type';
import { SimpleLoadOption } from '../../../../models/options/simple-load-option';
import { TilesRequest } from '../../../../models/tiles-request';
import { ChartType } from '../../../../models/enum/chart-type';
import { TileMetaData } from '../../../../models/tile-metadata';
import { NavigationService } from '../../../../services/navigation.service';
import { ExportService } from '../../../../services/export.service';
import { DashboardId } from '../../../../models/enum/dashboard-id';
import { SmallChartTileComponent } from '../../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DualChartTileComponent } from '../../../tiles/dashboard-tiles/dual-chart-tile/dual-chart-tile.component';
import { BaseTile } from 'src/app/components/tiles/dashboard-tiles/base-tile/base-tile';

@Component({
    selector: 'bespoke-dashboard',
    templateUrl: './bespoke-dashboard.component.html',
})
export class BespokeDashboardComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(TemplateHostDirective) templateHost?: TemplateHostDirective;
    private bespokeDashboardProvider = new BespokeDashboardProvider();
    public dashboardName = 'Bespoke dashboard';
    public dashboardHTMLId = 'bespoke-dashboard-body';
    private dashboardStyle = DashboardStyle.smallCharts;
    private dashboardType = DashboardType.column;
    public showDownloadModal!: boolean;
    public editTileOptions!: Array<EditAnalyseTileSection>;
    public showEdit = false;
    private mergedEvents!: Observable<BaseTile>;
    bespokeDashboardLayout!: BespokeDashboardLayout;
    private peersSubscription!: Subscription;
    private tileBeingEdited: BespokeDashboardTile | null = null;
    public showChangeStyleModal = false;
    private mergedEventsSubscription!: Subscription;
    public DashboardStyle = DashboardStyle;
    private currentDashboardRef!: ComponentRef<BespokeDashboard>;
    private dashboardId = DashboardId.Bespoke;
    peerGroupCount!: number;
    peerGroupType!: string;
    dateRangeText: any;
    dateRangeSubscription!: Subscription;
    dashboardsList!: any[];
    loadLayoutSubscription!: Subscription;

    constructor(
        private layoutService: LayoutService,
        public toastr: ToastrService,
        private peersService: PeersService,
        private chartsService: ChartsService,
        private spinnerService: SpinnerService,
        private dateRangeService: DateRangeService,
        private myAccountService: MyAccountService,
        public navigationService: NavigationService,
        private exportService: ExportService
    ) {}

    ngOnInit() {
        this.peerGroupCount = this.peersService.currentPeerCount;
        this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];

        this.peersSubscription = this.peersService.peersChanged.subscribe(
            () => {
                this.peerGroupCount = this.peersService.currentPeerCount;
                this.peerGroupType =
                    PeerGroupType[this.peersService.peerGroupType];
            }
        );

        this.dateRangeText =
            this.dateRangeService.getDateRangeHeaderDisplayText();

        this.dateRangeSubscription =
            this.dateRangeService.dateRangeLoadedOrChanged.subscribe(() => {
                this.dateRangeText =
                    this.dateRangeService.getDateRangeHeaderDisplayText();
            });
        this.peersSubscription = this.peersService.peersChanged.subscribe(
            (fullReload: boolean) => {
                if (fullReload) return this.getDashboardLayout(fullReload);

                return this.updateAllTiles();
            }
        );

        this.myAccountService.peerGroupsLoaded.subscribe(
            (userPreferences: UserPeerGroups) => {
                this.dashboardsList = userPreferences.layouts
                    .filter(
                        (layout) =>
                            layout.layoutId === this.dashboardId &&
                            layout.type === LayoutType.Dashboard
                    )
                    .map(
                        (layout) =>
                            new SimpleLoadOption<number>(
                                false,
                                layout.name,
                                layout.id,
                                layout.isOwnedByUser
                            )
                    );
            }
        );

        this.loadLayoutSubscription = this.layoutService.dashboardLayoutChange
            .pipe(filter((id) => id !== null))
            .subscribe(() => {
                this.getDashboardLayout(true);
            });
    }

    ngAfterViewInit(): void {
        this.renderDashboard();

        this.getDashboardLayout(false);
    }

    private renderDashboard(): void {
        if (!this.templateHost) return;
        if (this.currentDashboardRef) this.currentDashboardRef.destroy();

        const dashboard = this.bespokeDashboardProvider.dashboardTypes.get(
            this.dashboardType
        );

        if (!dashboard) return;

        const componentRef =
            this.templateHost.viewContainerRef.createComponent(dashboard);

        this.currentDashboardRef = componentRef;

        const tiles = this.bespokeDashboardProvider.getDashboardStyle(
            this.dashboardStyle
        );

        if (!tiles) return;

        componentRef.instance.componentsBuilt$.pipe(take(1)).subscribe(() => {
            this.subscribeToEditEvents(componentRef.instance);
        });

        componentRef.instance.buildComponents(tiles);
    }

    ngOnDestroy() {
        this.peersSubscription.unsubscribe();
        this.mergedEventsSubscription.unsubscribe();
        this.loadLayoutSubscription.unsubscribe();
    }

    private getDashboardLayout(clearTiles: boolean): void {
        const layoutId = this.layoutService.dashboardLayoutId;

        this.spinnerService.show('app-spinner');
        this.layoutService
            .getBespokeDashboardLayout(layoutId!)
            .subscribe({
                next: (bespokeDashboardLayout: BespokeDashboardLayout) => {
                    this.bespokeDashboardLayout = bespokeDashboardLayout;

                    const dashboardLayout =
                        DashboardStyle[bespokeDashboardLayout.dashboardStyle];
                    if (dashboardLayout !== this.dashboardStyle) {
                        this.dashboardStyle =
                            DashboardStyle[
                                this.bespokeDashboardLayout.dashboardStyle
                            ];
                        this.dashboardType =
                            DashboardType[
                                this.bespokeDashboardLayout.dashboardType
                            ];

                        this.renderDashboard();
                    }

                    this.editTileOptions =
                        bespokeDashboardLayout.availableComponents.map(
                            (ats: AnalyseTileSection) =>
                                new EditAnalyseTileSection(ats)
                        );

                    this.updateChartTypesBasedOnDashboardStyle();

                    if (this.bespokeDashboardLayout.tiles) {
                        const tiles = this.buildTiles(
                            null,
                            this.bespokeDashboardLayout.tiles
                        );
                        this.currentDashboardRef.instance.setTileData(tiles);
                    } else if (clearTiles) {
                        this.currentDashboardRef.instance.clearTileData();
                    }
                },
                error: () => {
                    this.toastr.error('Failed to load bespoke dashboard');
                },
            })
            .add(() => {
                this.spinnerService.hide('app-spinner');
            });
    }

    private subscribeToEditEvents(component: BespokeDashboard): void {
        if (this.mergedEventsSubscription)
            this.mergedEventsSubscription.unsubscribe();

        const allEditEvents = component.getEditEvents();

        this.mergedEvents = merge(...allEditEvents);

        this.mergedEventsSubscription = this.mergedEvents.subscribe(
            (res: BaseTile) => {
                const components = res.getComponentsInTile();
                this.tileBeingEdited = res as BespokeDashboardTile;

                if (
                    !components ||
                    (components.length > 0 && components[0].calcRef == 0)
                ) {
                    const piOptions = this.editTileOptions[0].piOptions.find(
                        (pi) => pi.isSelectable
                    );

                    if (piOptions) piOptions.isSelected = true;
                } else {
                    for (let i = 0; i < this.editTileOptions.length; i++) {
                        const section = this.editTileOptions[i];
                        const kpi = section.piOptions.find(
                            (pi) => pi.value == components[0].calcRef
                        );

                        if (kpi) {
                            kpi.isSelected = true;
                            break;
                        }
                    }
                }

                this.showEdit = true;
            }
        );
    }

    public applyEdit(chosenComponent: EditAnalyseTileComponent): void {
        this.spinnerService.show('app-spinner');

        if (!this.tileBeingEdited) return;

        const tiles = this.tileBeingEdited.getComponentsInTile();
        const tr = new TilesRequest();
        tr.tiles = tiles;

        if (this.tileBeingEdited.Type === SmallChartTileComponent) {
            tiles[0].calcRef = chosenComponent.calcRef;
            tiles[0].chartType = ChartType[chosenComponent.selectedChartType];
        } else if (this.tileBeingEdited.Type === DualChartTileComponent) {
            tiles[0].calcRef = chosenComponent.calcRef;
            tiles[1].calcRef = chosenComponent.calcRef;
            tiles[1].chartType = ChartType[chosenComponent.selectedChartType];
        }

        this.chartsService.getDataForTiles(tr).subscribe(
            (responseTileRequest: TilesRequest) => {
                const tiles = this.buildTiles(
                    chosenComponent,
                    responseTileRequest.tiles
                );
                this.tileBeingEdited!.updateFromBespokeDashboard(...tiles);

                this.tileBeingEdited = null;
                this.closeEditModal();
                this.spinnerService.hide('app-spinner');
            },
            () => {
                this.spinnerService.hide('app-spinner');
                this.toastr.error(
                    'There was a problem loading your chart',
                    'Error'
                );
            }
        );
    }

    private updateAllTiles(): void {
        const tr = this.currentDashboardRef.instance.getTileRequest();
        tr.tiles = tr.tiles.filter((tile) => tile.calcRef !== 0);

        this.chartsService.getDataForTiles(tr).subscribe(
            (responseTileRequest: TilesRequest) => {
                const tiles = this.buildTiles(null, responseTileRequest.tiles);
                this.currentDashboardRef.instance.setTileData(tiles);

                this.spinnerService.hide('app-spinner');
            },
            () => {
                this.spinnerService.hide('app-spinner');
                this.toastr.error(
                    'There was a problem loading your chart',
                    'Error'
                );
            }
        );
    }

    private buildTiles(
        component: EditAnalyseTileComponent | null,
        tileMetaDatas: Array<TileMetaData>
    ): Array<Tile> {
        const tiles = new Array<Tile>();

        tileMetaDatas.forEach((tmd) => {
            const tile = new Tile();
            tile.selected = new AvailablePi();
            tile.selected.calcRef = tmd.calcRef;
            tile.selected.selectedKpiChartType = tmd.chartType;
            tile.dashboardItemRef = tmd.dashboardItemRef;
            tile.data = tmd.data;

            if (component) {
                tile.selected.calcRef = component.calcRef;
                tile.selected.name = component.name;
                tile.selected.units = component.units;
                tile.selected.icon = component.icon;
            } else if (
                tile.selected.selectedKpiChartType ===
                ChartType[ChartType.infoTile]
            ) {
                this.findAndUpdateInfoTileTileMetaData(tile, tmd);
            }

            tiles.push(tile);
        });

        return tiles;
    }

    private findAndUpdateInfoTileTileMetaData(
        tile: Tile,
        tmd: TileMetaData
    ): void {
        for (let i = 0; i < this.editTileOptions.length; i++) {
            const section = this.editTileOptions[i];

            const component = section.components.find(
                (comp) => comp.calcRef === tmd.calcRef
            );

            if (component) {
                tile.selected.name = component.name;
                tile.selected.units = component.units;
                tile.selected.icon = component.icon;
                break;
            }
        }
    }

    public openDownloadModal(): void {
        this.showDownloadModal = true;
    }

    public closeDownloadModal(): void {
        this.showDownloadModal = false;
    }

    public closeEditModal(): void {
        this.showEdit = false;
    }

    public openChangeStyleModal(): void {
        this.showChangeStyleModal = true;
    }

    public closeChangeStyleModal(): void {
        this.showChangeStyleModal = false;
    }

    public changeStyle(style: DashboardStyle): void {
        this.dashboardStyle = style;
        this.dashboardType = DashboardType.column;
        this.renderDashboard();
        this.showChangeStyleModal = false;

        this.updateChartTypesBasedOnDashboardStyle();
    }

    private updateChartTypesBasedOnDashboardStyle(): void {
        const updateCall =
            this.dashboardStyle === DashboardStyle.dualCharts
                ? this.removeInfoTileToChartTypes
                : this.addInfoTileToChartTypes;

        this.editTileOptions.forEach((eats) =>
            eats.components.forEach((component) => {
                updateCall(component.chartTypes);
            })
        );
    }

    private addInfoTileToChartTypes(chartTypes: ChartType[]): void {
        if (chartTypes.indexOf(ChartType.infoTile) < 0)
            chartTypes.push(ChartType.infoTile);
    }

    private removeInfoTileToChartTypes(chartTypes: ChartType[]): void {
        const indexOfChartType = chartTypes.indexOf(ChartType.infoTile);

        if (indexOfChartType > -1)
            chartTypes.splice(chartTypes.indexOf(ChartType.infoTile), 1);
    }

    public changeToQuadrantDashboard(): void {}

    public saveDashboardLayout(name: string): void {
        this.spinnerService.show('app-spinner');

        const tileRequest = this.currentDashboardRef.instance.getTileRequest();

        tileRequest.tiles = tileRequest.tiles.filter(
            (tile) => tile.calcRef > 0
        );

        this.layoutService
            .saveBespokeDashboardLayout(
                tileRequest,
                this.getCurrentDashboardType(),
                this.dashboardStyle,
                name,
                this.dashboardId
            )
            .subscribe(
                (layout: UserLayout) => {
                    this.myAccountService.addToLayoutList(
                        layout,
                        this.dashboardName
                    );
                    this.toastr.success(
                        `Your dashboard layout, ${layout.name}, was successfully saved`,
                        'Success'
                    );
                    this.spinnerService.hide('app-spinner');
                },
                () => this.spinnerService.hide('app-spinner')
            );
    }

    private getCurrentDashboardType(): DashboardType {
        return this.dashboardStyle === DashboardStyle.sections
            ? DashboardType.quadrant
            : DashboardType.column;
    }

    public loadDashboardLayout(layoutId: number | null): void {
        this.layoutService.dashboardLayoutId = layoutId;
        this.getDashboardLayout(true);
    }

    public exportAsPDF(): void {
        this.spinnerService.show('app-spinner');

        const portraitExport =
            this.dashboardStyle === DashboardStyle.dualCharts;
        this.exportService
            .exportDashboardPdf(
                this.dashboardName,
                portraitExport,
                this.dashboardHTMLId
            )
            .subscribe(() => {
                this.closeDownloadModal();
                this.spinnerService.hide('app-spinner');
            });
    }

    public exportAsExcel(): void {
        const tileRequest = this.currentDashboardRef.instance.getTileRequest();

        const calcRefs = tileRequest.tiles.map((tile) => tile.calcRef);

        this.spinnerService.show('app-spinner');

        this.exportService
            .exportExcel('Export.xlsx', ...calcRefs)
            .subscribe(() => {
                this.spinnerService.hide('app-spinner');
                this.closeDownloadModal();
            });
    }

    public exportAsPng(): void {
        this.spinnerService.show('app-spinner');

        this.exportService
            .exportDashboardAsPng(this.dashboardName)
            .subscribe(() => {
                this.closeDownloadModal();
                this.spinnerService.hide('app-spinner');
            });
    }
}
