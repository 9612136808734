import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { PeersService } from '../../../services/peers.service';
import { PeerGroupType } from '../../../models/enum/peer-group-type';
import { DateRangeService } from '../../../services/date-range.service';
import { NavigationService } from '../../../services/navigation.service';
import { LayoutService } from '../../../services/layout.service';
import { MyAccountService } from '../../../services/my-account.service';
import { LayoutType } from '../../../models/enum/layout-type';
import { SimpleLoadOption } from '../../../models/options/simple-load-option';
import { UserPeerGroups } from '../../../models/user-preferences/user-peer-groups';
import { Report } from '../../../models/enum/report';
import { DateRangeSection } from '../../../models/enum/date-range-section';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'report-header',
    templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent implements OnInit, OnDestroy {
    peerSubscription!: Subscription;
    dateRangeSubscription!: Subscription;
    dateRangeText: string = '';

    peerGroupType: string = '';
    peerGroupCount: number = 0;
    @Input() reportName: string = '';
    @Input() reportId = 0;
    @Output() openReorderModal = new EventEmitter<any>();
    @Output() openDownloadModal = new EventEmitter<any>();

    showDownloadModal = false;
    reportLayoutsList: SimpleLoadOption<number>[] = [];
    supplementalTextLineOne: string = '';
    supplementalTextLineTwo: string = '';

    constructor(private peersService: PeersService, private dateRangeService: DateRangeService, private route: ActivatedRoute,
        public navigationService: NavigationService, private layoutService: LayoutService, private myAccountService: MyAccountService) {
    }

    ngOnInit() {
        this.peerGroupCount = this.peersService.currentPeerCount;
        this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];

        this.peerSubscription = this.peersService.peersChanged.subscribe(() => {
            this.peerGroupCount = this.peersService.currentPeerCount;
            this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];
        });

        this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();

        this.dateRangeSubscription = this.dateRangeService.dateRangeLoadedOrChanged.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
            this.updateSupplementalText(this.reportId);
        });

        this.myAccountService.peerGroupsLoaded.subscribe((userPreferences: UserPeerGroups) => {
            this.reportLayoutsList = userPreferences.layouts.filter(layout => layout.layoutId === this.reportId && layout.type === LayoutType.Report).
            map(layout => new SimpleLoadOption<number>(false, layout.name, layout.id, layout.isOwnedByUser));
        });

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.updateSupplementalText(params['id']);
            }
        });

        this.updateSupplementalText(this.reportId);
    }

    updateSupplementalText(reportId: number) {
        if(reportId == Report.FullAnnualReport) {
            const performanceData = this.dateRangeService.currentSections.sections.find(section => section.infoComponentId === DateRangeSection.PerformanceData)

            if(performanceData) {
                if(!performanceData.selectedOption.toString().endsWith('12')) {
                    const option = performanceData.options.find(opt => opt.isSelected)!.text;
                    this.supplementalTextLineOne = `This report is currently presenting more recent performance data for ${option}`;
                    this.supplementalTextLineTwo = `If you would like your performance data period to match your annual cost period, please change your date selection`;
                    return;
                }
            }
        }

        this.supplementalTextLineOne = '';
        this.supplementalTextLineTwo = '';
    }

    ngOnDestroy(): void {
        this.dateRangeSubscription.unsubscribe();
        this.peerSubscription.unsubscribe();
    }

    loadReportLayout(userLayoutId: number) {
        this.layoutService.reportLayoutId = userLayoutId;
        this.layoutService.loadReportLayout.next();
    }

    saveReportLayout(name: string) {
        this.layoutService.saveReportLayout.next(name);
    }
}
