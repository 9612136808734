import { Injectable } from "@angular/core";
import { HeadersService } from "./headers.service";
import { Observable, BehaviorSubject, first, skip } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ServiceMessageService {
    private url = `/api/servicemessage`;
    constructor(private headersService: HeadersService, private httpClient: HttpClient) { }
    currentServiceMessage = new BehaviorSubject('');

    fetchServiceMessage(): Observable<string> {
        const headers = this.headersService.getHeaders();

        this.httpClient.get<string>(this.url, { headers: headers }).subscribe((serviceMessage: string) => {
            this.currentServiceMessage.next(serviceMessage);
        });

        return this.currentServiceMessage.pipe(skip(1), first());
    }

    addServiceMessage(messageToAdd: string): Observable<void> {
        let headers = this.headersService.getHeaders();

        return this.httpClient.put<void>(this.url, { message: messageToAdd },{ headers: headers });
    }

    deleteServiceMessage(): Observable<void> {
        const headers = this.headersService.getHeaders();

        return this.httpClient.delete<void>(this.url, { headers: headers });
    }
}