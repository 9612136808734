// tslint:disable:no-bitwise
export enum ChartType {
    none = 0,
    boxPlot = 0x01,
    column = 0x02,
    summaryStackedColumn = 0x04,
    annualTrend = 0x08,
    annualStackedTrend = 0x10,
    tree = 0x20,
    pie = 0x40,
    table = 0x80,
    scatter= 0x100,
    stackedBar = 0x200,
    costBreakdown = 0x400,
    visualTable = 0x800,
    complexGauge = 0x1000,
    infoTile = 0x2000,
    drillBar = 0x4000,
    drillStackedBar = 0x8000,
    quarterlyTrend = 0x10000,
    quarterlyStackedTrend = 0x20000
}
