import { Component, OnInit } from '@angular/core';
import { DateRangeService } from '../../../services/date-range.service';
import { SectionsViewModel, SectionViewModel } from '../../../models/section';
import { NavigationService } from '../../../services/navigation.service';
import { BaseNavigationComponent } from '../../shared/base-navigation/base-navigation';
import { OrganisationService } from '../../../services/organisation.service';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'sidebar-date-range',
    templateUrl: './date-range.component.html'
})
export class DateRangeComponent extends BaseNavigationComponent implements OnInit {
    viewModel: SectionsViewModel | undefined;
    isAuthed = false;

    constructor(private dateRangeService: DateRangeService, navigationService: NavigationService, organisationService: OrganisationService, private toastrService: ToastrService) {
        super(navigationService, 'DateRangeComponent', organisationService);
     }

    ngOnInit() {
        this.dateRangeService.dateRangeLoaded.subscribe(() => {
            this.viewModel = this.dateRangeService.currentSections;
        });
    }

    apply() {
        this.dateRangeService.dateRangeChanged.next();
        this.close();
        this.toastrService.success('These dates have been applied', 'Success!');
    }

    changeSelection(value: number, section: SectionViewModel) {
        section.options.forEach(opt => {
            opt.isSelected = opt.value === value;

            if (opt.isSelected) section.selectedOption = value;
        });
    }

    reset() {
        if(!this.viewModel) return;
        
        this.viewModel.sections.forEach(sect => {
            sect.options.forEach(opt => opt.isSelected = false);

            const lastOption = sect.options[0];
            lastOption.isSelected = true;

            sect.selectedOption = lastOption.value;
        });

        this.dateRangeService.dateRangeChanged.next();
        this.close();
    }
    
    openNexus() {
        window.open(environment.nexus_url, "_blank");
    }

    closePage() {
        this.navigationService.closeAllTabs();
    }
}
