<div class="col-lg-12">
    <div class="section-name" (click)="toggleSection()">
        <span>{{index}}. {{sectionName}}</span>
        <div>
            <i class="hm-icon-arrow-left" [class.hide]="!isSectionHidden()" ></i>
            <i class="hm-icon-arrow" [class.hide]="isSectionHidden()"></i>
        </div>
    </div>
    <div class="row hide" #SectionContent>
        <div class="col-lg-6 dashboard-contents">
            <div class="tile tile-height-3">
                <div class="small-chart-tile info-icon-tile icon-hover">
                    <div class="icons-hover-wrapper">
                        <div class="icons-hover-wrapper-inner bottom">
                                <i class="far fa-edit" (click)="openEditWith('cost')"></i>
                        </div>
                    </div>
                    
                    <highcharts-chart *ngIf="!noCostData && costChart" [chart]="costChart" class="tile-chart"></highcharts-chart>

                    <div *ngIf="noCostData" class="no-data">
                        <div class="title">{{costTitle}}</div>
                        <div class="content">
                            <div>No data available. Your organisation has not provided data for this measure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 dashboard-contents">
            <div class="tile tile-height-3">
                <div class="small-chart-tile info-icon-tile icon-hover ">
                    <div class="icons-hover-wrapper">
                        <div class="icons-hover-wrapper-inner bottom">
                                <i class="far fa-edit" (click)="openEditWith('performance')"></i>
                        </div>
                    </div>

                    <highcharts-chart *ngIf="!noPerformanceData && performanceChart" [chart]="performanceChart" class="tile-chart"></highcharts-chart>

                    <div *ngIf="noPerformanceData" class="no-data">
                        <div class="title">{{performanceTitle}}</div>
                        <div class="content">
                            <div>No data available. Your organisation has not provided data for this measure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>