import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.stsServer,
        redirectUrl: environment.redirect_url,
        postLogoutRedirectUri: environment.post_logout_redirect_uri,
        clientId: environment.client_id,
        scope: environment.scope,
        responseType: environment.response_type,
        silentRenewUrl: environment.silent_renew_url,
        startCheckSession: environment.start_checksession,
        silentRenew: environment.silent_renew,
        logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
        unauthorizedRoute: environment.unauthorized_route,
        forbiddenRoute: environment.forbidden_route,
        maxIdTokenIatOffsetAllowedInSeconds: environment.max_id_token_iat_offset_allowed_in_seconds,
        triggerAuthorizationResultEvent: true
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}