import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReportEditTileOptions } from '../../../models/edit-tile-options';
import { ChartType } from '../../../models/enum/chart-type';
import { ReportChosenOptions } from '../../../models/chosen-options';
import { ComponentCharts } from '../../../models/component-charts';
import { EditAnalyseTileSection } from '../../../models/analyse/edit-analyse-tile-section';
import { EditAnalyseTileComponent } from '../../../models/analyse/edit-analyse-tile-component';
import { HideableOption } from '../../../models/options/hideable-option';

@Component({
    selector: 'edit-report-modal',
    templateUrl: './edit-report-modal.component.html'
})
export class EditReportModalComponent implements OnInit {
    @Input() model!: ReportEditTileOptions;
    @Output() closeModal = new EventEmitter<any>();
    @Output() applyEdit = new EventEmitter<ReportChosenOptions>();

    selectedComponent!: number;
    selectedChartType!: ChartType;
    currentChartTypes!: ComponentCharts;
    filterText: string = '';

    constructor() { }

    ngOnInit() {
        let selectedPi = this.model.piOptions.find(pi => pi.isSelected);

        if (!selectedPi) {
            selectedPi = this.model.piOptions[0];
            selectedPi.isSelected = true;
        }

        this.selectedComponent = selectedPi.value;
        this.currentChartTypes = this.model.chartsTypes.find(ct => ct.calcRef === selectedPi!.value)!;
        this.selectedChartType = this.currentChartTypes.selectedChartType;
    }

    cancel() {
        this.closeModal.emit();
    }

    applyChoices(): void {
        this.applyEdit.emit(new ReportChosenOptions(this.selectedComponent,
            this.currentChartTypes.selectedChartType,
            this.model.sectionId,
            this.model.chartIndex));
    }

    selectChartType(value: { chartType: ChartType }): void {
        this.currentChartTypes.selectedChartType = value.chartType;
    }

    selectComponent(id: number): void {
        this.selectedComponent = id;

        this.model.piOptions.forEach(option => option.isSelected = option.value === id);

        const currentChartTypeSelected = this.currentChartTypes.selectedChartType;

        this.currentChartTypes = this.model.chartsTypes.find(ct => ct.calcRef === id)!;

        if (this.currentChartTypes.chartTypes.find(ct => ct === currentChartTypeSelected)) {
            this.currentChartTypes.selectedChartType = currentChartTypeSelected;
        } else if (this.currentChartTypes.chartTypes.length > 0) {
            this.currentChartTypes.selectedChartType = this.currentChartTypes.chartTypes[0];
        }
    }

    filterPis(filter: string) {
        this.model.piOptions.forEach((pi: HideableOption<number>) => {
            pi.isVisible = !filter || filter.length < 3 || pi.text.toLowerCase().includes(filter.toLowerCase());
        });
    }

    public disableApply(): boolean {
        return this.model.piOptions.every(pi => !pi.isSelectable);
    }
}
