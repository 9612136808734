<nav class="navigation-sidebar">
    <ul class="list-unstyled flex-column">
        <li class="sidebar-item" [class.active]="isPageOpen('DateRangeComponent')" data-toggle-target="sidebar-date-range" (click)="toggleTab('DateRangeComponent')">
            <a class="nav-link" role="button">
                <i class="hm-icon-date-range sidebar-icon" aria-hidden="true"></i>Date range
            </a>
        </li>
        <li class="sidebar-item" [class.active]="isPageOpen('PeerSelectionComponent')" (click)="toggleTab('PeerSelectionComponent')">
            <a class="nav-link" role="button">
                <i class="hm-icon-peer-group sidebar-icon" aria-hidden="true"></i>Peer selection
            </a>
        </li>
        <li class="sidebar-item" [class.active]="isPageOpen('HelpComponent')" (click)="toggleTab('HelpComponent')">
            <a class="nav-link" role="button">
                <i class="hm-icon-help sidebar-icon" aria-hidden="true"></i>Help
            </a>
        </li>
    </ul>

    <ul class="list-unstyled flex-column">
        <li class="sidebar-item sign-out" (click)="signOut()">
            <a class="nav-link" role="button">
                <i class="hm-icon-signout sidebar-icon" aria-hidden="true"></i>Logout
            </a>
        </li>
    </ul>
</nav>