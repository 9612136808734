<div class="container-fluid dashboard" id="global-accounts-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="global-accounts-dashboard-body">
        <div id="dashboard-export-wrapper">
            <div class="col-lg-6">
                <div class="col-left dashboard-contents">
                    <div class="tiles-container portrait">
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart1></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart3></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart5></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart7></small-chart-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-right dashboard-contents">
                    <div class="tiles-container portrait">
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart2></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart4></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart6></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #GADashChart8></small-chart-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>