import { CanShowAndHideTemplate, NavigationService } from '../../../services/navigation.service';
import { OrganisationService } from '../../../services/organisation.service';


export class BaseNavigationComponent implements CanShowAndHideTemplate {
    name: string;
    showTemplate = false;

    constructor(public navigationService: NavigationService, protected componentName: string, protected organisationService: OrganisationService) {
        navigationService.register(this);
        this.name = componentName;
    }

    show() {
        this.showTemplate = true;
    }
    hide() {
        this.showTemplate = false;
    }

    get isShowing(): boolean {
        return this.showTemplate === true;
    }

    restrictNavigationItem(): boolean {

        if (!this.organisationService.currentLandlord) return true;

        return this.organisationService.restrictedOrgTypes.includes(this.organisationService.currentLandlord.type.toLocaleLowerCase());
    }

    close() {
        this.navigationService.closeAllTabs();
    }

}
