import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { PeerSelectionComponent } from './peer-selection/peer-selection.component';
import { BespokePeerGroupComponent } from './peer-selection/bespoke-peer-group/bespoke-peer-group.component';
import { DynamicPeerGroupComponent } from './peer-selection/dynamic-peer-group/dynamic-peer-group.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SliderComponent } from '../slider/slider.component';
import { CheckboxOptionsComponent } from '../checkbox-options/checkbox-options.component';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../../pipes/pipes.module';
import { HelpComponent } from './help/help.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgxSliderModule,
        SharedModule,
        PipesModule,
        NgbTooltipModule,
        ReactiveFormsModule
    ],
    declarations: [
        SidebarComponent,
        PeerSelectionComponent,
        BespokePeerGroupComponent,
        DynamicPeerGroupComponent,
        SliderComponent,
        CheckboxOptionsComponent,
        HelpComponent
    ],
    exports: [
        SidebarComponent,
        PeerSelectionComponent,
        BespokePeerGroupComponent,
        DynamicPeerGroupComponent,
        HelpComponent
    ]
})
export class SidebarModule { }
