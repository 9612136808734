import { Options } from "@angular-slider/ngx-slider";

export class SliderModel {
    minValue: number;
    maxValue: number;
    options: Options;
    name: string;

    constructor(name: string, min: number, max: number, lowerBoundValue: number, upperBoundValue: number) {
        this.name = name;

        if (lowerBoundValue) this.minValue = lowerBoundValue;
        else this.minValue = 0;

        if (upperBoundValue > max) this.maxValue = max;
        else this.maxValue = upperBoundValue;

        this.options = {
            floor: min ? min : 0,
            ceil: max,
            step: 1,
            noSwitching: true,
        };
    }
}
