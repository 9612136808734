import { Component, EventEmitter, Output } from '@angular/core';
import { TileMetaData } from '../../../../models/tile-metadata';
import { EditTileOptions } from '../../../../models/edit-tile-options';
import { Tile } from '../../../../models/dashboard/tile';
import { ChartType } from '../../../../models/enum/chart-type';
import { HideableOption } from '../../../../models/options/hideable-option';
import { AvailablePi } from '../../../../models/dashboard/available-pi';
import { ComponentCharts } from '../../../../models/component-charts';
import { Chart } from '../../../../highcharts/chart';
import { InfoTile } from '../../../../models/info-tile/info-tile';
import { BaseBespokeDashboardTile } from '../../../../interfaces/bespoke-dashboard-tile';
import { BaseTile } from '../base-tile/base-tile';

@Component({
    selector: 'dual-chart-tile',
    templateUrl: './dual-chart-tile.component.html'
})
export class DualChartTileComponent extends BaseBespokeDashboardTile {
    Type = DualChartTileComponent;
    infoTile!: Tile;
    infoTileChart?: InfoTile;
    chartTile!: Tile;
    chartData?: Chart;


    @Output() override openEditEvent: EventEmitter<BaseTile> = new EventEmitter<BaseTile>();

    constructor() {
        super();
    }

    public getComponentsInTile(): Array<TileMetaData> {
        if(!this.infoTile || !this.infoTile.selected) { 
            return [];
        }

        const infoMetaData = new TileMetaData();
        infoMetaData.chartType = ChartType[ChartType.infoTile];
        infoMetaData.calcRef = this.infoTile.selected.calcRef;
        infoMetaData.dashboardItemRef = this.infoTile.dashboardItemRef;
        
        const secondTileMetaData = new TileMetaData();
        secondTileMetaData.chartType = this.chartTile.selected.selectedKpiChartType;
        secondTileMetaData.calcRef = infoMetaData.calcRef;
        secondTileMetaData.dashboardItemRef = this.chartTile.dashboardItemRef;

        return new Array<TileMetaData>(infoMetaData, secondTileMetaData);
    }

    public initializeModel(...model: Array<Tile>) {
        if (model && model.length > 0) {
            this.infoTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('a'))!;

            if (this.infoTile.data) {
                this.infoTileChart = new InfoTile(this.infoTile);
            }

            this.chartTile = model.find(m => m.dashboardItemRef.toLocaleLowerCase().endsWith('b'))!;

            if (this.chartTile.data?.title) {
                this.chartTile.data.title.text = '';
                this.chartData = new Chart(this.chartTile.data);
            }
        }
    }

    public updateModel(...tileData: Array<TileMetaData>) {
        const infoData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('a'));

        if (infoData) {
            const selected = this.infoTile.available.find(available => available.calcRef === infoData.calcRef);

            if (selected) {
                this.infoTile.selected = selected;
                this.infoTile.data = infoData.data;
                this.infoTileChart = new InfoTile(this.infoTile);
            }
        }

        const secondTileData = tileData.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('b'));

        if (secondTileData) {
            const selected = this.chartTile.available.find(available => available.calcRef === secondTileData.calcRef);

            if (selected) {
                this.chartTile.selected = selected;
                this.chartTile.selected.selectedKpiChartType = secondTileData.chartType;

                secondTileData.data.title!.text = '';
                this.chartTile.data = secondTileData.data;
                this.chartData = new Chart(secondTileData.data);
            }
        }
    }

    public getEditOptions(): EditTileOptions {
        const editOptions = new EditTileOptions();

        editOptions.piOptions = this.infoTile.available.map((availableChart: AvailablePi) => {

            return new HideableOption<number>(availableChart.calcRef === this.infoTile.selected.calcRef,
                                              availableChart.name,
                                              availableChart.calcRef,
                                              true,
                                              availableChart.noDataMessage);                                              
        });

        const metas = this.getComponentsInTile();

        const metaData = metas.find(tmd => tmd.dashboardItemRef.toLocaleLowerCase().endsWith('b'))!;
        const componentCharts = new ComponentCharts();
        componentCharts.chartTypes = this.chartTile.selected.availableChartTypes.split(', ').map(act => ChartType[act]);
        componentCharts.selectedChartType = ChartType[metaData.chartType];
        componentCharts.dashboardItemRef = metaData.dashboardItemRef;
        editOptions.chartsTypes.push(componentCharts);

        return editOptions;
    }

    updateFromBespokeDashboard(...tiles: Array<Tile>) {
        if(!tiles) return;

        const infoData = tiles.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('a'));

        if (infoData) {
            this.infoTileChart = new InfoTile(infoData);
            this.infoTile = infoData;
        }

        const secondTileData = tiles.find(tile => tile.dashboardItemRef.toLocaleLowerCase().endsWith('b'));

        if (secondTileData && secondTileData.data) {
            this.chartTile = secondTileData;

            if(secondTileData.selectedChartType !== ChartType.infoTile && secondTileData.data.title) {
                secondTileData.data.title.text = '';
                this.chartData = new Chart(secondTileData.data);
            } else {
                this.chartData = undefined;
            }
        }
    }

    clearTile(): void {
        this.infoTileChart = undefined;
        this.chartData = undefined;
    }
}
