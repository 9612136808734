import { EditTileOptions } from "../models/edit-tile-options";
import { Tile } from "../models/dashboard/tile";
import { TileMetaData } from "../models/tile-metadata";
import { BaseTile, IBaseTile } from "../components/tiles/dashboard-tiles/base-tile/base-tile";
import { EventEmitter, Type } from "@angular/core";

export interface BespokeDashboardTile extends IBaseTile {
    dashboardItemRefs: string[];
    getEditOptions(): EditTileOptions;
    updateFromBespokeDashboard(...tiles: Array<Tile>): void;
    getComponentsInTile(): Array<TileMetaData>;
    clearTile(): void;
    Type: Type<IBaseTile>;
}

export abstract class BaseBespokeDashboardTile extends BaseTile implements BespokeDashboardTile {
    abstract updateFromBespokeDashboard(...tiles: Tile[]): void;
    abstract clearTile(): void;
    abstract Type: Type<IBaseTile>;
}