import { FontData } from 'svg.js';

export class FontDataModel implements FontData {
    constructor(size?: number, weight?: string) {
        if (size) this.size = size;
        if (weight) this.weight = weight;
    }

    family = 'roboto';
    size?: number;
    anchor?: string;
    leading?: number;
    weight?= 'bold';
    style?= 'font-family: roboto';
}
