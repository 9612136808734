import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EditTileOptions } from '../../../models/edit-tile-options';
import { ChartType } from '../../../models/enum/chart-type';
import { HideableOption } from '../../../models/options/hideable-option';

@Component({
    selector: 'edit-tile-modal',
    templateUrl: './edit-tile-modal.component.html'
})
export class EditTileModalComponent implements OnInit {
    @Input() model: EditTileOptions;
    @Output() closeModal = new EventEmitter<any>();
    @Output() applyEdit = new EventEmitter<any>();

    filterText: string;
    selectedComponent: number;
    selectedChartTypes = new Map<number, ChartType>();

    constructor() { }

    ngOnInit() {
    }

    public cancel() {
        this.closeModal.emit();
    }

    public applyChoices() {
        this.applyEdit.emit();
    }

    public selectChartType(value: { chartType: ChartType, index: number }): void {
        this.selectedChartTypes.set(value.index, value.chartType);
    }

    public selectComponent(value: number): void {
        this.model.piOptions.forEach(val => {
            val.isSelected = val.value === value;
        });
    }

    public filterPis(filter: string) {
        this.model.piOptions.forEach((pi: HideableOption<number>) => {
            pi.isVisible = !filter || filter.length < 3 || pi.text.toLowerCase().includes(filter.toLowerCase());
        });
    }

    public isInfoTile(): boolean {
        return this.model.chartsTypes[0].selectedChartType === ChartType.infoTile;
    }

    public largeModal(): boolean {
        return this.model.chartsTypes.length > 1;
    }

    public disableApply(): boolean {
        return this.model.piOptions.every(pi => !pi.isSelectable);
    }
}
