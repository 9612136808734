<div class="container">
    <div class="row">
        <div class="col-lg-12 unauthorized-body">
            <h2>Unauthorized</h2>
            <p>Your login credentials don't allow you access to this application.</p>
            <p> Please contact your administrator if you believe this is an error</p>
            <div class="logout" (click)="logout()">
                <i class="hm-icon-signout"></i> Sign out
            </div>
        </div>
    </div>
</div>