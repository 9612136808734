<div class="container-fluid dashboard" id="tenant-satisfaction-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="tenant-satisfaction-dashboard-body">
        <div id="dashboard-export-wrapper">

            <div class="col-lg-6">
                <div class="col-left dashboard-contents">
                    <div class="tiles-container dual-charts">
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart1></dual-chart-tile>
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart3></dual-chart-tile>
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart5></dual-chart-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-right dashboard-contents">
                    <div class="tiles-container dual-charts">
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart2></dual-chart-tile>
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart4></dual-chart-tile>
                        <dual-chart-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #TSDashChart6></dual-chart-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>