import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PeersService } from '../../../services/peers.service';
import { DateRangeService } from '../../../services/date-range.service';
import { PeerGroupType } from '../../../models/enum/peer-group-type';
import { ComponentsService } from '../../../services/components.service';
import { ChartsService } from '../../../services/charts.service';
import { ChartType } from '../../../models/enum/chart-type';
import { EditAnalyseTileSection } from '../../../models/analyse/edit-analyse-tile-section';
import { AnalyseTileSection } from '../../../models/analyse/analyse-tile-section';
import { Chart } from '../../../highcharts/chart';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../../../services/export.service';
import { saveSvgAsPng } from 'save-svg-as-png';
import { NavigationService } from '../../../services/navigation.service';
import { AnalyseChosenOptions } from '../../../models/chosen-options';
import { Options } from "../../../highcharts/options";
import { Subscription } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'analyse',
    templateUrl: './analyse.component.html'
})
export class AnalyseComponent implements OnInit, OnDestroy {
    ChartType = ChartType;
    dateRangeLoadSubscription!: Subscription;
    dateRangeChangeSubscription!: Subscription;
    dateRangeText: string = '';
    showDropdown: boolean = false;
    displayLargeChart: boolean = false;
    peerSubscription!: Subscription;
    peerGroupType: string = '';
    peerGroupCount!: number;
    showGuidance = false;
    showEdit = false;
    noData = false;
    editTileOptions: EditAnalyseTileSection[] = [];
    chartData!: Chart;
    units = '';
    currentChartType!: ChartType;
    currentPi!: number;
    title = '';
    secondPi!: number;
    componentIdToUnitMap = new Map<number, string>();

    constructor(private peersService: PeersService, private componentsService: ComponentsService, private dateRangeService: DateRangeService, private chartsService: ChartsService,
        private spinnerService: SpinnerService, private toastr: ToastrService, private exportService: ExportService, public navigationService: NavigationService,
        private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.loadComponents();

        this.peerSubscription = this.peersService.peersChanged.subscribe((hardRefresh: boolean) => {
            if (hardRefresh) this.loadComponents();
            else this.getTileData();

            this.peerGroupCount = this.peersService.currentPeerCount;
            this.peerGroupType = PeerGroupType[this.peersService.peerGroupType];
        });

        this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();

        this.dateRangeChangeSubscription = this.dateRangeService.dateRangeChanged.subscribe(() => {
            this.loadComponents();
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
        });

        this.dateRangeLoadSubscription = this.dateRangeService.dateRangeLoaded.subscribe(() => {
            this.dateRangeText = this.dateRangeService.getDateRangeHeaderDisplayText();
        });
    }

    private loadComponents() {
        this.spinnerService.show('app-spinner');
        this.componentsService.getOrganisationsComponents().subscribe((res: AnalyseTileSection[]) => {
            this.buildAnalysisPiSections(res);
            this.getTileData();
        }, () => {
            this.toastr.error('There was a problem loading this page', 'Error');
        }, () => {
            this.spinnerService.hide('app-spinner');
        });
    }

    private buildAnalysisPiSections(res: AnalyseTileSection[]) {
        this.editTileOptions = res.map((ats: AnalyseTileSection) => {
            const eats = new EditAnalyseTileSection(ats);
            eats.components.forEach(comp => this.componentIdToUnitMap.set(comp.calcRef, comp.units));
            return eats;
        });

        for (let i = 0; i < this.editTileOptions.length; i++) {
            const sect = this.editTileOptions[i];
            const possibleOption = sect.piOptions.find(pi => pi.isSelectable);

            if (possibleOption) {
                this.currentPi = possibleOption.value;
                possibleOption.isSelected = true;
                this.currentChartType = sect.components.find(comp => comp.calcRef === possibleOption.value)!.chartTypes[0];
                break;
            }
        }

        if (!this.currentPi) {
            const editOption = this.editTileOptions[0];
            editOption.piOptions[0].isSelected = true;
            this.currentPi = editOption.piOptions[0].value;
            this.currentChartType = editOption.components[0].chartTypes[0];
        }
    }

    ngOnDestroy() {
        this.peerSubscription.unsubscribe();
        this.dateRangeLoadSubscription.unsubscribe();
        this.dateRangeChangeSubscription.unsubscribe();
    }

    getGuidance(): string {
        for (let index = 0; index < this.editTileOptions.length; index++) {
            const component = this.editTileOptions[index].components.find(comp => comp.calcRef === this.currentPi);

            if (component) return component.guidance;
        }

        return '';
    }

    getTileData(): any {
        this.spinnerService.show('app-spinner');
        if (this.currentChartType === ChartType.scatter) {
            this.chartsService.getScatterChartData(this.currentPi, this.secondPi).subscribe(
                {
                    next: (res: Options) => this.apiGetCallBack(res), 
                    error: () => this.errorCallBack(), 
                    complete: () => this.completedCallBack()
                });
        } else {
            this.chartsService.getChartData(this.currentChartType, this.currentPi)
                .subscribe({
                    next: (res: Options) => this.apiGetCallBack(res), 
                    error: () => this.errorCallBack(), 
                    complete: () => this.completedCallBack()
                });
        }
    }

    completedCallBack() {
        this.spinnerService.hide('app-spinner');
    }

    apiGetCallBack(res: Options) {
        this.displayLargeChart = !res.noDataAvailable && (res.drilldown !== undefined) && res.chart!.type === 'bar';
        this.changeDetector.detectChanges();

        this.chartData = new Chart(res);
        this.noData = res.noDataAvailable;
        this.title = res.title!.text!;
        this.spinnerService.hide('app-spinner');
    }

    errorCallBack() {
        this.spinnerService.hide('app-spinner');
        this.toastr.error('There was a problem loading your chart', 'Error');
    }

    applyEdit(options: AnalyseChosenOptions) {
        this.spinnerService.show('app-spinner');
        if (options.chartType === ChartType.scatter) {
            this.chartsService.getScatterChartData(options.calcRef, options.secondCalcRef!).subscribe({
                next: (res: Options) => {
                    this.apiEditCallback(res, options);
                }, 
                error: () => this.errorCallBack()
            });
        } else {
            this.chartsService.getChartData(options.chartType, options.calcRef).subscribe({
                next: (res: Options) => {
                    this.apiEditCallback(res, options);
                }, 
                error: () => this.errorCallBack()
            });
        }
    }

    apiEditCallback(res: Options, options: AnalyseChosenOptions) {
        this.displayLargeChart = !res.noDataAvailable && (res.drilldown !== undefined) && res.chart!.type === 'bar';
        this.changeDetector.detectChanges();

        this.chartData = new Chart(res);
        this.noData = res.noDataAvailable;
        this.title = res.title!.text!;
        this.closeEditModal();
        this.units = this.componentIdToUnitMap.get(options.calcRef)!;
        this.currentPi = options.calcRef;
        this.currentChartType = options.chartType;
        this.secondPi = options.secondCalcRef!;
        this.spinnerService.hide('app-spinner');
    }

    openGuidance() {
        this.showGuidance = true;
    }

    closeGuidance() {
        this.showGuidance = false;
    }

    openEditModal() {
        this.showEdit = true;
    }

    closeEditModal() {
        this.showEdit = false;
    }

    public toggleDropDown($event?: any) {
        this.showDropdown = !this.showDropdown;
    }

    saveAsPdf() {
        const svg = document.querySelector('svg.highcharts-root')!;
        let svgString = svg.outerHTML;
        const isIe = navigator.appVersion.toString().indexOf('.NET') > 0;

        if (isIe) {
            const serializer = new XMLSerializer();
            svgString = serializer.serializeToString(svg);
        }

        this.spinnerService.show('app-spinner');
        this.exportService.exportChartAsPDF(svgString, 'Chart').subscribe((res: Blob) => {
            const fileName = `${this.getComponentName()}.pdf`;
            if (isIe) {
                window.navigator.msSaveBlob(res, fileName);
            } else {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(res);
                link.download = fileName;
                link.click();
            }
            this.spinnerService.hide('app-spinner');
        }, () => {
            this.toastr.error('Please try again', 'There was a problem when downloading your chart');
            this.spinnerService.hide('app-spinner');
        });
    }

    saveAsExcel() {
        this.spinnerService.show('app-spinner');

        this.exportService.exportExcel('Export.xlsx', this.currentPi).subscribe(() => {
            this.spinnerService.hide('app-spinner');
        });
    }

    getComponentName(): string {
        let firstPiName: string  = '';
        let secondPiname: string = '';
        this.editTileOptions.forEach(section => {
            const comp = section.components.find(component => component.calcRef === this.currentPi);

            if (comp) firstPiName = comp.name;
            const secondComp = section.components.find(component => component.calcRef === this.secondPi);

            if (secondComp) secondPiname = secondComp.name;
        });

        if (secondPiname) return `${firstPiName}-${secondPiname}`;

        return firstPiName;
    }

    saveAsPng() {
        const svg = document.querySelector('svg.highcharts-root')!;
        const styles = <Attr>svg.attributes['style'];

        if (styles) styles.value = 'font-family: roboto;';

        saveSvgAsPng(document.querySelector('svg.highcharts-root'), `${this.getComponentName()}.png`);
    }

    hideManageDropdown() {
        this.showDropdown = false;
    }

}
