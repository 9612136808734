import { Chart } from './chart';
import * as SVG from 'svg.js';
import * as Highcharts from 'highcharts';

export class Gauge {
    public static RenderMedianPoints(chart: any) {
        const yAxis = chart.yAxis[0];

        this.renderMedianAndQuartiles(yAxis);
    }

    public static adjustText(chart: Chart) {
        if (chart.options!.chart!.type === 'solidgauge') {
            const yAxis = chart.ref!.yAxis[0];

            this.renderMedianAndQuartiles(yAxis);
        }
    }

    private static renderMedianAndQuartiles(yAxis: any) {
        let ndp = 0;

        if (Math.floor(yAxis.dataMax) === yAxis.dataMax) ndp = 0;
        else ndp = yAxis.dataMax.toString().split('.')[1].length || 0;

        yAxis.plotLinesAndBands.forEach((plotBand: any, i: number) => {
            if (plotBand.id === 'median') this.renderText(plotBand, i, yAxis.max, yAxis.min, yAxis.userOptions.units, ndp, this.BuildInitialGroup);
            else {
                const chart = plotBand.axis.chart;
                if (!chart.groups || !chart.groups[i]) this.bindClick(plotBand, ndp, yAxis.userOptions.units);
            }
        });
    }

    private static bindClick(plotBand: any, ndp: number, units: string) {
        const chart = plotBand.axis.chart;
        const id = plotBand.id;
        const groupId = 'quartile-for-' + id;

        const group = chart.renderer.createElement('g').attr({
            class: 'quartile-for-' + id,
            zIndex: 103,
            transform: 'translate(99999, 99999)'
        }).add();

        let val = plotBand.options.from + (plotBand.options.to - plotBand.options.from) / 2;
        val = val.toFixed(ndp);

        const m = chart.renderer.createElement('rect').attr({
            zIndex: 101,
            fill: 'rgba(247, 247, 247, 0.85)',
            stroke: '#7cb5ec',
            width: 100,
            height: 30,
            style: 'pointer-events: none; cursor: pointer; stroke: #7cb5ec;'
        }).add(group);

        Gauge.createHoverTextBlock(chart, group, id, val, units);

        const pathElement = plotBand.svgElem.element as HTMLElement;
        pathElement.setAttribute('for', groupId);
        pathElement.setAttribute('style', 'cursor: pointer');
        plotBand.svgElem.element.addEventListener('mouseenter', (event: MouseEvent) => this.showHoverText(event), false);
        plotBand.svgElem.element.addEventListener('mouseleave', (event: MouseEvent) => this.hideHoverText(event), false);

        return group;
    }

    private static hideHoverText(event: MouseEvent) {
        const elem = event.target as SVGElement;
        const forAttr = elem.getAttribute('for');
        const hoverElement = elem.ownerSVGElement!.querySelector(`.${forAttr}`);
        hoverElement!.setAttribute('transform', `translate(99999, 99999)`);
    }

    private static showHoverText(event: MouseEvent) {
        const elem = event.target as SVGElement;
        const forAttr = elem.getAttribute('for');
        const hoverElement = elem.ownerSVGElement!.querySelector(`.${forAttr}`);
        const chartDom = elem.closest('.highcharts-chart');
        const attr = chartDom!.getAttribute('data-highcharts-chart') ?? '';
        const highchart = Highcharts.charts[+attr];

        const yaxis = highchart!.yAxis[0] as any;

        // plotlines in API spec but not here?
        const plotLine = yaxis.plotLinesAndBands.find((plotline: any) => plotline.svgElem.element === elem);
        const bbox = plotLine.svgElem.getBBox();

        const x = hoverElement!.clientHeight + bbox.x + bbox.width / 2;
        const y =  (bbox.y + bbox.height / 2) - 15;

        hoverElement!.setAttribute('transform', `translate(${x}, ${y})`);
    }

    private static createHoverTextBlock(chart: any, group: any, id: any, val: any, units: string) {
        if (units) {
            if (units === '%') val += units;
            else if (units === '£') val = units + val;
        }

        const renderer = chart.renderer as Highcharts.SVGRenderer;

        const text = renderer.createElement('text').attr({
            zIndex: 102,
            'text-anchor': 'start',
            'style': 'font-weight: bold; pointer-events: none;',
        }).add(group);

        const tspan = chart.renderer.createElement('tspan').attr({
            x: 5,
            y: 20
        }).add(text);

        tspan.attr({ text: `${id}: ${val}` });
    }

    private static renderText(plotBand: any, i: number, maxValue: number, minValue: number, units: string, ndp: number, floatingTextGroupBuild: any) {
        const id = plotBand.id,
            chart = plotBand.axis.chart,
            path = plotBand.svgElem;

        let val = plotBand.options.from + (plotBand.options.to - plotBand.options.from) / 2;

        val = val.toFixed(ndp);
        chart.groups = chart.groups || [];

        path.attr('id', id);

        const plotBandBBox = path.getBBox();

        if (!chart.groups[i]) {
            let valString = val;
            if (units) {
                if (units === '%') valString += units;
                else if (units === '£') valString = units + valString;
            }

            const medianGroup = floatingTextGroupBuild(chart, valString, id);

            const group = this.transformGroup(val, plotBandBBox, maxValue, minValue, medianGroup);
            group.group = medianGroup;

            chart.groups[i] = group;
        } else {
            chart.groups[i].group = this.transformGroup(val, plotBandBBox, maxValue, minValue, chart.groups[i].group);
        }
    }

    private static transformGroup(val: any, plotBandBBox: any, maxValue: number, minValue: number, group: any) {

        const len = group.getBBox().width;
        let x = plotBandBBox.x;
        let y = plotBandBBox.y;

        const range = maxValue - minValue;
        const percentage = (val - minValue) / range * 100;

        if (percentage > 66) {
            x += len + 7;
            y -= 1;
        } else if (percentage > 50) {
            y -= 6;
            // x -= len;
        } else if (percentage > 33) {
            y -= 10;

            x -= len - 5;
        } else {
            x -= len;
        }

        group.attr({
            transform: `translate(${x},${y})`
        });
        return group;
    }

    private static BuildInitialGroup(chart: any, val: string, id: string) {
        const medianXTransform = val.toString().length * 6;

        const group = chart.renderer.createElement('g').add();

        // translate the m, mtext and text to be next to each other
        const m = chart.renderer.createElement('circle').attr({
            zIndex: 1,
            r: '10',
            color: 'black'
        }).add(group);

        const mText = chart.renderer.createElement('text').attr({
            fill: 'white',
            'text-anchor': 'middle',
            text: 'm',
            zIndex: 10,
            y: 3
        }).add(group);

        const bbox = group.getBBox();

        const text = chart.renderer.createElement('text').attr({
            zIndex: 1,
            'text-anchor': 'end',
            text: `${val}`,
            class: 'median-plot',
            y: 4.5,
            x: bbox.width + medianXTransform
        }).add(group);

        return group;
    }
}