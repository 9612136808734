import { Injectable } from '@angular/core';
import { HeadersService } from './headers.service';
import { HttpClient } from '@angular/common/http';
import { Organisation } from '../models/organisation';
import { SpinnerService } from '../components/spinner/spinner.service';
import { OidcSecurityService } from '../../../node_modules/angular-auth-oidc-client';
import { UserData } from '../models/identity/user-data';
import { forkJoin, map, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class OrganisationService {
    private _currentLandlords: Organisation[];
    public landlordChanged = new ReplaySubject<Organisation>(1);
    public restrictedOrgTypes = ['local authority', 'almo', ''];

    constructor(private headersService: HeadersService, private httpClient: HttpClient, private spinnerService: SpinnerService,
        private oidcSecurityService: OidcSecurityService) {
    }

    public getUsersOrganisations(): Observable<void> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Organisations';

        const obs1$ = this.httpClient.get<Organisation[]>(url, { headers: headers });
        const obs2$ = this.oidcSecurityService.getUserData();

        const obs3$ = forkJoin([obs1$, obs2$]).pipe(map((res:[Organisation[], UserData] ) => {
            this._currentLandlords = res[0];
            const userData = res[1];
    
            this._currentLandlords.sort((left: Organisation, right: Organisation) => {
                if (left.name < right.name) return -1;
                else if (left.name > right.name) return 1;

                return 0;
            });

            const foundLandlord = this._currentLandlords.find(org => org.id === userData.organization_id);
            if (foundLandlord) {
                foundLandlord.isCurrentChoice = true;
                this.landlordChanged.next(foundLandlord);
            }
        }));

        return obs3$;
    }

    get currentLandlords(): Organisation[] {
        return this._currentLandlords;
    }

    get currentLandlord(): Organisation | undefined {
        return this._currentLandlords?.find(cl => cl.isCurrentChoice);
    }

    changeLandlordSelection(newWKey: number, oldWKey: number): void {
        const selectedLandlord = this._currentLandlords.find(cl => cl.wKey === newWKey);
        const oldLandlord = this._currentLandlords.find(cl => cl.wKey === oldWKey);

        if(!selectedLandlord || !oldLandlord) return;

        selectedLandlord.isCurrentChoice = true;

        oldLandlord.isCurrentChoice = false;

        this.landlordChanged.next(selectedLandlord);
    }
}
