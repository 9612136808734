<div class="col-7">
    <h3>Update service message</h3>

    <div *ngIf="currentMessage" class="current-service-message">
        <input disabled [(ngModel)]="currentMessage" />
        
        <button id="delete-service-message-button" (click)="deleteServiceMessage()" class="transparent admin-button fas fa-trash"></button>
    </div>

    <div class="add-service-message" *ngIf="!currentMessage">
        <input title="Add message" type="text" [(ngModel)]="newMessage" />

        <button id="add-service-message-button" (click)="addServiceMessage()" class="transparent admin-button fas fa-plus"></button>
    </div>
</div>