import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { BaseTile, IBaseTile } from '../base-tile/base-tile';
import { Tile } from '../../../../models/dashboard/tile';
import { Chart } from '../../../../highcharts/chart';
import { TileMetaData } from '../../../../models/tile-metadata';
import { ChartType } from '../../../../models/enum/chart-type';
import { EditTileOptions } from '../../../../models/edit-tile-options';
import { ComponentCharts } from '../../../../models/component-charts';
import { HideableOption } from '../../../../models/options/hideable-option';
import { AvailablePi } from '../../../../models/dashboard/available-pi';
import { InfoTile } from '../../../../models/info-tile/info-tile';
import {BaseBespokeDashboardTile } from '../../../../interfaces/bespoke-dashboard-tile';

@Component({
    selector: 'small-chart-tile',
    templateUrl: './small-chart-tile.component.html'
})
export class SmallChartTileComponent extends BaseBespokeDashboardTile {
    Type = SmallChartTileComponent;
    chart?: Chart;
    title?: string;
    noData: boolean = false;
    model?: Tile;

    @Output() override openEditEvent: EventEmitter<BaseTile> = new EventEmitter<BaseTile>();

    numberToBorderMap = new Map<string, string>([
        ['1', 'blue-top-border'],
        ['2', 'pink-top-border'],
        ['3', 'dark-teal-top-border'],
        ['4', 'purple-top-border'],
        ['5', 'dark-blue-top-border'],
        ['6', 'light-blue-top-border'],
        ['7', 'blue-top-border'],
        ['8', 'pink-top-border'],
    ]);

    infoTile?: InfoTile;

    constructor(private elementRef: ElementRef) {
        super();
    }

    getElementRef(): ElementRef {
        return this.elementRef;
    }

    getComponentsInTile(): TileMetaData[] {
        if(!this.model || !this.model.selected) {
            return [];
        }

        const metaData = new TileMetaData();
        metaData.chartType = this.model.selected.selectedKpiChartType;
        metaData.calcRef = this.model.selected.calcRef;
        metaData.dashboardItemRef = this.model.dashboardItemRef;

        return new Array<TileMetaData>(metaData);
    }

    initializeModel(model: Tile) {
        if (model) {
            this.model = model;

            if (this.model.data) {
                if (this.model.data.noDataAvailable) {
                    this.title = this.model.data.title!.text ?? '';
                    this.noData = true;
                } else {
                    this.noData = false;
                    this.chart = new Chart(this.model.data);
                }

                const itemRef = this.model.dashboardItemRef;
                const borderStyle = this.numberToBorderMap.get(itemRef.slice(itemRef.length - 1)) ?? '';
                const tileElement = this.elementRef.nativeElement as HTMLElement;

                const smallChartDiv = tileElement.querySelector('.small-chart-tile');
                smallChartDiv!.classList.add(borderStyle);
            }
        }
    }

    updateModel(tileData: TileMetaData) {
        if (!this.model) return;

        const selected = this.model.available.find(a => a.calcRef === tileData.calcRef);

        if (selected) {
            this.model.selected = selected;
            this.model.selected.selectedKpiChartType = tileData.chartType;

            this.model.data = tileData.data;

            if (tileData.data.noDataAvailable) {
                this.title = this.model.data.title?.text;
                this.noData = true;
            } else {
                this.chart = new Chart(this.model.data);
                this.noData = false;
                this.title = undefined;
            }
        }
    }

    edit() {
        this.openEdit();
    }

    getEditOptions(): EditTileOptions {
        const editOptions = new EditTileOptions();

        if(!this.model) return editOptions;

        editOptions.piOptions = this.model.available.map((availableChart: AvailablePi, index: number) => {
            return new HideableOption<number>(availableChart.calcRef === this.model!.selected.calcRef, availableChart.name,
                                              availableChart.calcRef, true, availableChart.noDataMessage);
        });

        this.getComponentsInTile().forEach((tmd: TileMetaData) => {
            const componentCharts = new ComponentCharts();
            componentCharts.chartTypes = this.model!.selected.availableChartTypes.split(', ').map(act => ChartType[act]);
            componentCharts.selectedChartType = ChartType[tmd.chartType];
            componentCharts.dashboardItemRef = tmd.dashboardItemRef;
            editOptions.chartsTypes.push(componentCharts);
        });

        return editOptions;
    }

    updateFromBespokeDashboard(...tiles: Array<Tile>) {
        if(!tiles || tiles.length > 1) return;

        const tile = tiles[0];

        this.model = tile;

        if(!tile.data) {
            this.noData = true;
            this.title = '';
            return;
        }

        if(tile.data.noDataAvailable === true) {
            this.title = tile.selected.name;
            this.noData = true;
            return;
        } 

        if(tile.selected.selectedKpiChartType === ChartType[ChartType.infoTile]) {
            this.infoTile = new InfoTile(tile);
            this.chart = undefined;

            this.removeBorder(tile);
        } else {
            this.chart = new Chart(tile.data);
            this.infoTile = undefined;

            this.addBorder(tile);
        }           
        
        this.noData = false;
        this.title = undefined;   
    }

    private removeBorder(tile: Tile): void {
        const borderStyle = this.getBorderStyle(tile.dashboardItemRef);
        const tileElement = this.elementRef.nativeElement as HTMLElement;

        const smallChartDiv = tileElement.querySelector('.small-chart-tile');

        smallChartDiv!.classList.remove(borderStyle);
    }

    private addBorder(tile: Tile): void {
        const borderStyle = this.getBorderStyle(tile.dashboardItemRef);
        const tileElement = this.elementRef.nativeElement as HTMLElement;

        const smallChartDiv = tileElement.querySelector('.small-chart-tile');
        smallChartDiv!.classList.add(borderStyle);
    }

    private getBorderStyle(dashboardItemRef: string): string {
        const itemRef = dashboardItemRef;
        const borderStyle = this.numberToBorderMap.get(itemRef.slice(itemRef.length - 1));
        return borderStyle ?? '';
    }
    
    clearTile(): void {
        this.infoTile = undefined;
        this.chart = undefined;

        if(this.model && this.model.dashboardItemRef) {
            const borderStyle = this.getBorderStyle(this.model.dashboardItemRef);
            const tileElement = this.elementRef.nativeElement as HTMLElement;
            
            const smallChartDiv = tileElement.querySelector('.small-chart-tile');
            smallChartDiv!.classList.remove(borderStyle);
        }
    }
}
