import { MyAccountListItem } from '../../../models/user-preferences/my-account-list-item';

export abstract class ManageComponent {
    selectedItem?: MyAccountListItem;
    sortCriteria = '';
    searchTerm = '';
    editName = '';
    showEdit = false;
    showDelete = false;
    private _isAdmin = false;

    get isAdmin() {
        return this._isAdmin;
    }

    set isAdmin(value: boolean) {
        this._isAdmin = value;
    }

    abstract type: any;

    setSortCriteria(option: string) {
        if (this.sortCriteria === '') {
            this.sortCriteria = option;
        } else if (this.sortCriteria.includes(option)) {
            if (this.sortCriteria.includes('-')) {
                this.sortCriteria = '';
            } else {
                this.sortCriteria = '-' + option;
            }
        } else {
            this.sortCriteria = option;
        }

        this.sort();
    }

    abstract sort();
    abstract filterList(type?: any);
    abstract saveItem();
    abstract deleteItem();
    abstract itemAlreadyExists();

    showEditModal(item: MyAccountListItem) {
        this.selectedItem = item;
        this.showEdit = true;
    }

    cancel() {
        this.selectedItem = undefined;
        this.showDelete = false;
        this.showEdit = false;
    }

    showDeleteModal(item: MyAccountListItem) {
        this.selectedItem = item;
        this.showDelete = true;
    }

    normalizeString(text: string): string {
        return text.toLocaleLowerCase().trim();
    }

    saveButtonDisabled() {
        return !this.editName || this.itemAlreadyExists();
    }
}
