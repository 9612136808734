import { SelectableOption } from './selectable-option';

export class HideableOption<T> extends SelectableOption<T> {
    isSelectable: boolean;

    constructor(isSelected: boolean, text: string, value: T, public isVisible: boolean, public noDataMessage: string) {
        super(isSelected, text, value);
        this.isVisible = isVisible === undefined ? true : isVisible;
        this.isSelectable = noDataMessage === '';
    }
}
