export class UserData {
    bmaccess: string;
    country: string[];
    feature: string[];
    hm_display_name: string;
    organization: string;
    organization_display: string;
    organization_id: string;
    organization_type: string;
    preferred_username: string;
    product: string[];
    sub: string;

    public hasAccess(): boolean {
        if (this.organization.toLocaleLowerCase() === 'housemark') return true;
        if (this.bmaccess.toLowerCase() === 'noaccess') return false;
        if (this.product.includes('residentinvolvementbenchmarking') && !this.product.includes('planform')) return false;

        return true;
    }
}
