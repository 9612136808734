<div class="tile tile-full-height">
    <div class="combo-tile icon-hover">

        <div class="icons-hover-wrapper icon-hover-left">
            <div class="icons-hover-wrapper-inner top">
                <i class="far fa-edit" (click)="openEdit()"></i>
            </div>
        </div>

        <div class="tile-portion tile-summary combo-icon-tile" *ngIf="largeInfoTile && largeInfoTileChart">
            <large-info-tile [infoTile]="largeInfoTileChart"></large-info-tile>
        </div>

        <ng-container *ngIf="largeInfoTileChart?.quartileClass !== 'no-data'">
            <div class="tile-portion tile-first-chart">
                <highcharts-chart [chart]="secondTileChart" *ngIf="secondTile && secondTileChart" class="tile-chart"></highcharts-chart>
            </div>

            <div class="tile-portion tile-second-chart">
                <highcharts-chart *ngIf="thirdTile && thirdTileChart" class="tile-chart" [chart]="thirdTileChart"></highcharts-chart>
            </div>
        </ng-container>
    </div>
</div>