<div class="component-choice">
    <div class="component-list">
        <div *ngFor="let item of availableComponents" [class.hide]="!item.isVisible" class="list-item">
            <div class="select-input" [class.no-data]="item.noDataMessage !== ''">
                <input id="item-{{item.value}}"  name="item-{{item.value}}" [checked]="item.isSelected" [value]="item.value" (change)="update(item.value)" type="radio" name="radiogroup"> 
                <label for="item-{{item.value}}">{{item.text}}</label>
            </div>
            <i placement="right" ngbTooltip="{{item.noDataMessage}}" *ngIf="item.noDataMessage !== ''" class="hm-icon-help"></i>
        </div>
    </div>
</div>