<div class="bespoke-peer-group-body" id="bespoke-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="top-row">
                <h3>Select peers to include or exclude</h3>
                <div class="buttons-container">
                    <button placement="top" ngbTooltip="This will set the currently selected peers as your peer group"
                        [class.disabled]="includedPeerCount === 0" (click)="apply(true)">Apply
                        <i class="hm-icon-help"></i>
                    </button>
                    <button (click)="reset()" class="grey-button">Discard changes</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-sm-6">
            <div class="row">
                <div class="col-lg-12 table-info-search">
                    <div>
                        <div class="peer-count">
                            <span>Peers included
                                <b class="badge badge-peers">{{includedPeerCount}}</b>
                            </span>
                        </div>
                        <div class="peer-count">
                            <span>Peers excluded
                                <b class="badge badge-peers">{{excludedPeerCount}}</b>
                            </span>
                        </div>
                    </div>
                    <div class="export-button">
                        <button placement="top"
                            ngbTooltip="This export will include the peers from the currently applied peer group"
                            (click)="downloadPeerList()">Export as excel
                            <i class="hm-icon-help"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="table-header">
                    <div id="includedInGroup">
                        <input type="checkbox" [checked]="areAllPeersChecked()" (click)="toggleAllPeers()" />
                        <div (click)="setSortCriteria('includedInGroup')">
                            <i *ngIf="sortCriteria == 'includedInGroup'" class="fas fa-caret-down"></i>
                            <i *ngIf="sortCriteria == '-includedInGroup'" class="fas fa-caret-up"></i>
                            <i *ngIf="!sortCriteria.includes('includedInGroup')" class="fas fa-sort"></i>
                        </div>
                    </div>
                    <div id="peerName" (click)="setSortCriteria('peerName')">Peer name
                        <i *ngIf="sortCriteria == '-peerName'" class="fas fa-caret-down"></i>
                        <i *ngIf="sortCriteria == 'peerName'" class="fas fa-caret-up"></i>
                        <i *ngIf="!sortCriteria.includes('peerName')" class="fas fa-sort"></i>
                    </div>
                    <div id="region" (click)="setSortCriteria('region')">Region
                        <i *ngIf="sortCriteria == 'region'" class="fas fa-caret-down"></i>
                        <i *ngIf="sortCriteria == '-region'" class="fas fa-caret-up"></i>
                        <i *ngIf="!sortCriteria.includes('region')" class="fas fa-sort"></i>
                    </div>
                </div>
                <div class="table-body" *ngIf="peers">
                    <div class="table-row" *ngFor="let peer of peers | IsVisibleInList" (click)="togglePeer(peer)">
                        <div class="includeDiv">
                            <input type="checkbox" [(ngModel)]="peer.includedInGroup" (change)="countPeers()">
                        </div>
                        <div class="peerName">{{peer.organisationName}}</div>
                        <div class="region">
                            <td>{{peer.organisationRegion}}</td>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-6">
            <div class="filters">
                <div class="drop-downs">
                    <input placeholder="Search peers" type="search" class="search" [ngModel]="filterText"
                        (ngModelChange)="filterOrgs($event)">
                    <div class="groups">
                        <div *ngFor="let date of dateSelectorMap | keyvalue; let i = index" class="group">
                            <div class="title">
                                <label id="switch-label-{{date.value.id}}" (click)="date.value.disabled = !date.value.disabled">{{date.value.title}}</label>
                                <div class="switch">
                                   <i *ngIf="i === 0" class="hm-icon-help" placement="top"
                                        ngbTooltip="Enabling this filter will reduce the peers selected in the table, including those with no data"></i>
                                     <button (click)="date.value.disabled = !date.value.disabled" type="button" id="switch-{{date.value.id}}" class="btn btn-toggle"
                                        [class.active]="!date.value.disabled"
                                        attr.aria-pressed="{{!date.value.disabled}}" autocomplete="off">
                                        <div class="handle"></div>
                                    </button>
                                </div>
                            </div>
                            <div class="drop-down" [class.disabled]="date.value.disabled">
                                <div class="range">
                                    <label for="from-{{date.value.id}}">From</label>
                                    <select (change)="validateSelection(date.value)"
                                        [(ngModel)]="date.value.selectedFrom" id="from-{{date.value.id}}"
                                        name="from-{{date.value.id}}">
                                        <option *ngFor="let options of date.value.dateOptions" [value]="options.value">
                                            {{options.text}}</option>
                                    </select>
                                </div>
                                <div class="range">
                                    <label for="to-{{date.value.id}}">To</label>
                                    <select (change)="validateSelection(date.value)" [(ngModel)]="date.value.selectedTo"
                                        id="to-{{date.value.id}}" name="to-{{date.value.id}}">
                                        <option *ngFor="let options of date.value.dateOptions" [value]="options.value">
                                            {{options.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="date.value.error" class="error" role="alert">
                                The selected 'From' date should be the same as or less than the selected 'To' date
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="switch">
                        <div>
                            Filter by applied peer group
                            <button type="button" class="btn btn-toggle active"
                                (click)="filterCurrentlySelectedPeers = !filterCurrentlySelectedPeers"
                                data-toggle="button" aria-pressed="true" autocomplete="off">
                                <div class="handle"></div>
                            </button>
                        </div>
                    </div>
                    <div class="buttons">
                        <button placement="top"
                            ngbTooltip="This will filter the peers in the peer list, but will not take effect until apply has been clicked"
                            id="set-filters" [disabled]="!filtersValid() || !anyFiltersEnabled()" (click)="setFilters()">
                            Set filters
                            <i class="hm-icon-help"></i>
                        </button>
                        <button (click)="resetDateFilters()" class="transparent">
                            Reset filters
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>