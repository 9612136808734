<div class="container-fluid dashboard" id="annual-summary-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>

    <div class="dashboard-body" id="annual-repairs-dashboard-body">
        <div id="dashboard-export-wrapper">
            <div class="col-lg-6">
                <div class="dashboard-contents col-left">
                    <div class="tiles-container">    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart1></icon-info-tile>
                    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart3></icon-info-tile>
                    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart5></icon-info-tile>
                        
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart7></icon-info-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="dashboard-contents col-right">
                    <div class="tiles-container">                        
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart2></icon-info-tile>
                    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart4></icon-info-tile>
                    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart6></icon-info-tile>
                    
                        <icon-info-tile (openEditEvent)="openEditModal($event)"
                            (closeEditEvent)="closeEditModal($event)" #ASDashChart8></icon-info-tile>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>