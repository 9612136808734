export class SliderData {
    upperBound: number;
    lowerBound: number;
    calcRef = 0;

    constructor(upperBound: number, lowerBound: number, calcRef?: number) {
        this.upperBound = upperBound;
        this.lowerBound = lowerBound;
        this.calcRef = calcRef ?? 0;
    }
}
