import { TableData } from '../models/charts/table-data';
import { ElementRef } from '../../../node_modules/@angular/core';
import { Chart } from './chart';
import { FontDataModel } from '../models/SVGjs/font-data';
import * as SVG from 'svg.js';
import { XAxisOptions, SeriesOptions } from 'highcharts';

export interface TableSeriesOption extends SeriesOptions {
    value: number;
}

export class Table {
    static lightFont = new FontDataModel(undefined, 'normal');
    static boldFont = new FontDataModel();
    static titleFont = new FontDataModel(18);

    public static RenderTable(elementRef: HTMLElement, chart: Chart, units: string): void {
        if (!chart) return;

        const title = chart.options.title?.text ?? '';

        const tableData = this.mapDataToRows(chart);

        let heightToFitTo = elementRef.clientHeight;
        if (heightToFitTo === 0) heightToFitTo = 400;
        elementRef.style.height = `${heightToFitTo}px`;
        elementRef.innerHTML = '';

        this.draw(title, units, tableData, elementRef, heightToFitTo);

        return;
    }

    private static mapDataToRows(chart: Chart) {
        const tableData = new Array<TableData>();

        var xAxis = chart.options.xAxis as XAxisOptions;
        
        xAxis.categories!.forEach((category, index) => {
            const series = chart.options.series as Array<TableSeriesOption>;
            
            tableData.push(new TableData(category, series[index].value));
        });

        return tableData;
    }

    private static draw(title: string, units: string, tableData: Array<TableData>, htmlElement: HTMLElement, heightToFitTo: number): void {
        const drawing = SVG(htmlElement).addClass('highcharts-root');
        Table.drawParentSvg(drawing, htmlElement, title);

        if (heightToFitTo < 400) Table.drawSmallerTableContent(drawing, htmlElement, tableData, units);
        else Table.drawTableContent(drawing, htmlElement, tableData, units);
    }


    private static drawSmallerTableContent(drawing: SVG.Doc, htmlElement: HTMLElement, tableData: TableData[], units: string) {
        const group = drawing.nested();
        const tableWidth = 540;
        const spaceRemaining = htmlElement.offsetWidth - tableWidth;
        const margin = spaceRemaining / 2;
        const nameX = 0;
        const valX = 170;
        let initialValY = 50.5;
        let initialLineY = 72.5;

        group.attr('style', 'font-size: 12.5px');        
        group.move(margin, -8);
        // table horizontal line
        group.rect(tableWidth, 2).move(0, 44).fill('#2287c6');
        // table vertical line
        group.rect(1.5, 218).move(150, 47).fill('#9d9d9d');
        
        tableData.forEach(tableRow => {
            Table.drawTableValues(group, initialLineY, tableRow, nameX, initialValY, units, valX);
            initialValY += 27.5;
            initialLineY += 27.5;
        });
    }

    private static drawTableContent(drawing: SVG.Doc, htmlElement: HTMLElement, tableData: TableData[], units: string) {
        const group = drawing.nested();
        const tableWidth = 540;
        const spaceRemaining = htmlElement.offsetWidth - tableWidth;
        const margin = spaceRemaining / 2;
        const nameX = 0;
        const valX = 170;
        let initialValY = 75;
        let initialLineY = 105;

        group.move(margin, 0);
        // table horizontal line
        group.rect(tableWidth, 3).move(0, 57).fill('#2287c6');
        // table vertical line
        group.rect(1.5, 325).move(150, 60).fill('#9d9d9d');
        

        for (let i = 0; i < tableData.length; i++) {
            const tableRow = tableData[i];
            const unit = i > 0 && i < tableData.length - 1 ? units : '';

            Table.drawTableValues(group, initialLineY, tableRow, nameX, initialValY, unit, valX);
            initialValY += 40;
            initialLineY += 40;
        }
    }

    private static drawTableValues(group: SVG.Nested, initialLineY: number, tableRow: TableData, nameX: number, initialValY: number, units: string, valX: number) {
        const name = group.text(tableRow.name);
        let value = tableRow.value.toString();

        group.rect(540, 1.5).move(0, initialLineY).fill('#9d9d9d');       
        name.move(nameX, initialValY).font(this.boldFont);
        
        if (units === '£') {
            value = '£' + tableRow.value;
        } else if (units === '%') {
            value += '%';
        }

        const val = group.text(value);
        val.move(valX, initialValY).font(this.lightFont);
    }

    private static drawParentSvg(drawing: SVG.Doc, htmlElement: HTMLElement, title: string) {
        drawing.viewbox(0, 0, htmlElement.offsetWidth, htmlElement.offsetHeight);
        drawing.attr('preserveAspectRatio', 'xMinYMax');
        // background
        drawing.rect(htmlElement.offsetWidth, htmlElement.offsetHeight).fill('#fff');
        // title
        drawing.text(title).move(10, 5).font(this.titleFont);
    }
}