export enum DateRangeSection {
    PerformanceData = 310001,
    SectorScorecard = 310002,
    BenchmarkingOptions = 310003,
    LandlordOrganisationCharacteristics = 310004,
    UnitStockEntry = 310017,
    NonpayCost = 310019,
    EmployeeApportionments = 310018,
    Development = 310008,
    STAR = 310009,
    HeadlineCostBenchmarking = 310010,
    JointCosts = 310020,
    ReportingPreferences = 310012
}
