<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="header">
                <div class="top-row">
                    <h1>Analyse</h1>
                </div>
                <div class="header-buttons">
                    <button (click)="navigationService.toggleComponentByName('DateRangeComponent')">
                        <i class="hm-icon-date-range"></i> {{dateRangeText}}
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-group"></i> {{peerGroupType}} Peer Group
                    </button>
                    <button (click)="navigationService.toggleComponentByName('PeerSelectionComponent')">
                        <i class="hm-icon-peer-count"></i> Peers In Group {{peerGroupCount}}
                    </button>
                </div>
                <div class="sub-title">
                    Edit the performance indicators, data sections and chart type to see your data change.
                </div>
            </div>
        </div>
    </div>
    <div class="row dashboard">
        <div class="analysis-body" [class.displayLargeChart]="displayLargeChart">
            <div class="col-12">
                <div class="chart icon-hover">
                    <div class="icons-hover-wrapper icon-hover-left">
                        <div class="icons-hover-wrapper-inner top">
                            <i class="far fa-question-circle" (click)="openGuidance()"></i>
                        <i class="far fa-edit" (click)="openEditModal()"></i>
                        </div>
                    </div>


                    <div class="manage-dropdown" (blur)="hideManageDropdown()" tabindex="-1">
                        <div class="icon" [class.active]="showDropdown" (click)="toggleDropDown($event)">
                            <i class="hm-icon-menu"></i>
                        </div>
                        <div *ngIf="showDropdown" class="dropdown-body">
                            <div class="item" (click)="saveAsPng()">
                                <i class="hm-icon-png"></i>
                                <div>Save as PNG</div>
                            </div>
                            <div class="item" (click)="saveAsPdf()">
                                <i class="hm-icon-pdf"></i>
                                <div>Save as PDF</div>
                            </div>
                            <div class="item" (click)="saveAsExcel()">
                                <i class="far fa-file-excel"></i>
                                <div>Save as XLSX</div>
                            </div>
                        </div>
                    </div>

                    <div class="help" *ngIf="showGuidance">
                        <i class="hm-icon-close" (click)="closeGuidance()"></i>
                        <div class="help-title">
                            Definition
                        </div>
                        <div class="help-body" innerHtml="{{getGuidance()}}">
                        </div>
                    </div>
                    <highcharts-chart *ngIf="chartData && !noData" [units]="units" [chart]="chartData"></highcharts-chart>

                    <div *ngIf="noData" class="no-data">
                        <div class="title">{{title}}</div>
                        <div class="content">
                            <div>No data available. Your organisation has not provided data for this measure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-analyse-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit($event)" [model]="editTileOptions"></edit-analyse-modal>