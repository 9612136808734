import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChartType } from '../models/enum/chart-type';
import { TilesRequest } from '../models/tiles-request';
import { CombinedStreamService } from './combined-stream.service';
import { Observable, of } from 'rxjs';
import { Options } from '../highcharts/options';

@Injectable()
export class ChartsService {
    chartTypeToUrlMap = new Map<ChartType, string>(
        [
            [ChartType.boxPlot, 'BoxPlot'],
            [ChartType.column, 'Bar'],
            [ChartType.complexGauge, 'Gauge'],
            [ChartType.costBreakdown, ''],
            [ChartType.infoTile, 'InfoTile'],
            [ChartType.pie, 'Pie'],
            [ChartType.annualTrend, 'AnnualTrend'],
            [ChartType.scatter, 'Scatter'],
            [ChartType.stackedBar, 'StackedBar'],
            [ChartType.annualStackedTrend, 'AnnualStackedTrend'],
            [ChartType.summaryStackedColumn, 'StackedBar'],
            [ChartType.table, 'FiveNumberSummary'],
            [ChartType.tree, 'Treemap'],
            [ChartType.drillBar, 'DrillBar'],
            [ChartType.drillStackedBar, 'DrillStackedBar'],
            [ChartType.quarterlyTrend, 'QuarterlyTrend'],
            [ChartType.quarterlyStackedTrend, 'QuarterlyStackedTrend']
        ]
    );

    constructor(private http: HttpClient, private combinedService: CombinedStreamService) {
    }

    getScatterChartData(infoComponent: number, secondInfoComponent: number) {
        const urlEndPoint = this.chartTypeToUrlMap.get(ChartType.scatter);
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey && apiData.periods) {
            // tslint:disable-next-line:max-line-length
            const url = `/api/Charts/${urlEndPoint}/${apiData.organisationWKey}/${infoComponent}/${apiData.periods}/${secondInfoComponent}?filterId=${apiData.filterId}`;

            return this.http.get<Options>(url, { headers: apiData.headers });
        }

        return of();
    }

    getChartData(chartType: ChartType, infoComponentWKey: number) {
        const urlEndPoint = this.chartTypeToUrlMap.get(chartType);
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        // tslint:disable-next-line:max-line-length
        const url = `/api/Charts/${urlEndPoint}/${apiData.organisationWKey}/${infoComponentWKey}/${apiData.periods}?filterId=${apiData.filterId}`;

        return this.http.get<Options>(url, { headers: apiData.headers });
    }

    getDataForTiles(tiles: TilesRequest): Observable<TilesRequest> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();
        const url = `/api/Charts/Tiles/${apiData.organisationWKey}/${apiData.periods}?filterId=${apiData.filterId}`;
        return this.http.post<TilesRequest>(url, tiles, { headers: apiData.headers });
    }
}
