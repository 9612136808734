<div class="chart-tile icon-hover">
    <div class="icons-hover-wrapper icon-hover-left">
        <div class="icons-hover-wrapper-inner top">
            <i class="far fa-question-circle" (click)="openGuidance()"></i>
            <i class="far fa-edit" (click)="edit()"></i>
            <i class="far fa-trash-alt" (click)="delete()"></i>
        </div>
    </div>

    <div class="help" *ngIf="showGuidance">
        <i class="fas fa-times" (click)="closeGuidance()"></i>
        <div class="help-title">
            Definition
        </div>
        <div class="help-body" [innerHtml]="definition">
        </div>
    </div>
    <div *ngIf="chart && !noData">
        <highcharts-chart [units]="units" [chart]="chart"></highcharts-chart>
    </div>

    <div *ngIf="noData" class="no-data">
        <div class="title">{{title}}</div>
        <div class="content">
            <div>No data available. Your organisation has not provided data for this measure</div>
        </div>
    </div>
</div>