import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DashboardService } from './services/dashboard.service';
import { LayoutService } from './services/layout.service';
import { OrganisationSelectionComponent } from './components/organisation-selection/organisation-selection.component';
import { DashboardSelectionComponent } from './components/dashboard-selection/dashboard-selection.component';
import { ReportsSelectionComponent } from './components/reports-selection/reports-selection.component';
import { DateRangeComponent } from './components/sidebar/date-range/date-range.component';
import { AuthModule, ConfigurationService, EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { SecurityGuardService } from './services/security-guard.service';
import { ChartModule } from './highcharts/chart.module';
import { FormsModule } from '@angular/forms';
import { HeadersService } from './services/headers.service';
import { ChartsService } from './services/charts.service';
import { ReportsModule } from './components/reports/reports.module';
import { ChartTileModule } from './components/tiles/chart-tile/chart-tile.module';
import { SingletonServicesModule } from './services/singleton-services.module';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardsModule } from './components/dashboards/dashboards.module';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { MyAccountModule } from './components/my-account/my-account.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { AnalyseComponent } from './components/analyse/analyse-component/analyse.component';
import { ComponentsService } from './services/components.service';
import { EditAnalyseModalComponent } from './components/analyse/edit-analyse-modal/edit-analyse-modal.component';
import { CommonModule } from '@angular/common';
import { OptionsModule } from './components/options/options.module';
import { DataSetsComponent } from './components/data-sets/data-sets.component';
import { ToastrModule } from 'ngx-toastr';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NgbTooltipModule } from '../../node_modules/@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { NoCacheInterceptor } from './interceptors/no-cache.interceptor';
import { ServiceMessageService } from './services/service-message.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter, of, switchMap } from 'rxjs';
import { AuthConfigModule } from './auth.config.module';
import { SetupService } from './services/setup.service';

export function loadConfig(
    oidcConfigService: ConfigurationService, 
    oidcSecurityService: OidcSecurityService,
    headersService: HeadersService,
    setupService: SetupService) {

    return () => {
        const obs$ = oidcSecurityService
            .checkAuthIncludingServer()
            .pipe(
                switchMap((response) => {
                    oidcConfigService.getOpenIDConfiguration();

                    if(response === null) {
                        return of(false)
                    }

                    if(response.isAuthenticated) { 
                        headersService.setHeaders(response.accessToken);
                    };

                    return of(response.isAuthenticated);
                }),
                switchMap((isAuthenticated: boolean) => {
                    if(!isAuthenticated) oidcSecurityService.authorize();

                    return setupService.ensureAppIsSetUp() ?? of();
                })
            );

        return obs$;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        OrganisationSelectionComponent,
        DashboardSelectionComponent,
        ReportsSelectionComponent,
        DateRangeComponent,
        AnalyseComponent,
        EditAnalyseModalComponent,
        DataSetsComponent,
        UnauthorizedComponent
    ],
    imports: [
        AppRoutingModule,
        AuthConfigModule,
        BrowserAnimationsModule,
        BrowserModule,
        ChartTileModule,
        ChartModule,
        CommonModule,
        DashboardsModule,
        FormsModule,
        HttpClientModule,
        MyAccountModule,
        OptionsModule,
        ReportsModule,
        RouterModule,
        SidebarModule,
        SingletonServicesModule,
        SpinnerModule,
        ToastrModule.forRoot(),
        NgbTooltipModule
    ],
    providers: [
        DashboardService,
        LayoutService,
        OidcSecurityService,
        PublicEventsService,
        ConfigurationService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [ConfigurationService, OidcSecurityService, HeadersService, SetupService],
            multi: true
        },
        SecurityGuardService,
        HeadersService,
        ChartsService,
        ComponentsService,
        { 
            provide: ApplicationInsights, 
            useValue: new ApplicationInsights({ 
                config: {
                    instrumentationKey: environment ? environment.instrumentation_key : ''
                }
            })
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheInterceptor,
            multi: true
        },
        ServiceMessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

declare global {
    export interface Navigator {
        msSaveBlob(blob: Blob, fileName: string): void;
    }
}