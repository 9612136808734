import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewContainerRef, ViewChild } from '@angular/core';
import { Chart } from '../../../highcharts/chart';
import { SectionChartIndex } from '../../../models/reports/section-chart-index';
import { Options } from '../../../highcharts/options';

@Component({
    selector: 'chart-tile',
    templateUrl: './chart-tile.component.html'
})
export class ChartTileComponent implements OnChanges {
    @Input() options!: Options;
    @Input() definition!: string;
    @Input() index!: number;
    @Input() section!: number;
    @Input() units: any;
    @Output() openEdit = new EventEmitter<SectionChartIndex>();
    @Output() openDelete = new EventEmitter<SectionChartIndex>();
    @ViewChild('tableDiv') viewContainer!: ViewContainerRef;

    chart!: Chart;
    showGuidance = false;
    noData = false;
    isTable = false;
    title: string = '';
    id: string = '';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options'].currentValue) {
            this.chart = new Chart(this.options);
            this.noData = this.options.noDataAvailable === true;
            this.title = this.options.title!.text!;
        }
    }

    edit() {
        this.openEdit.emit(new SectionChartIndex(this.section, this.index));
    }

    openGuidance() {
        this.showGuidance = true;
    }

    closeGuidance() {
        this.showGuidance = false;
    }

    delete() {
        this.openDelete.emit(new SectionChartIndex(this.section, this.index));
    }
}
