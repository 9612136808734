import { AfterViewInit, Component, Type, ViewChild } from "@angular/core";
import { BespokeDashboard } from "../../../../../../models/dashboard/bespoke/bespoke-dashboard";
import { ColumnDashboardDefinition } from "../../../../../../models/dashboard/bespoke/column-dashboard-definition";
import { ColumnPosition } from "../../../../../../models/enum/column-position";
import { ContainerComponent } from "../../../../../../interfaces/container-component";
import { TemplateHostDirective } from "../template-host.directive";
import { TilesColumnContainerComponent } from "../tiles-column-container/tiles-column-container.component";
import { DynamicTile } from "../../../../../../models/dashboard/bespoke/dynamic-tile";
import { Subject } from "rxjs";
import { TilesRequest } from "../../../../../../models/tiles-request";
import { TileMetaData } from "../../../../../../models/tile-metadata";
import { Tile } from "../../../../../../models/dashboard/tile";
import { BaseTile } from "src/app/components/tiles/dashboard-tiles/base-tile/base-tile";

@Component({
    selector: 'column-dashboard',
    templateUrl: './column-dashboard.component.html'
})
export class ColumnDashboardComponent implements BespokeDashboard, AfterViewInit {
    @ViewChild('leftSideHost', { read: TemplateHostDirective } ) leftColumn: TemplateHostDirective;
    @ViewChild('rightSideHost', { read: TemplateHostDirective } ) rightColumn: TemplateHostDirective;

    containers = new Array<Type<ContainerComponent>>(TilesColumnContainerComponent, TilesColumnContainerComponent);
    component = ColumnDashboardComponent;
    templateHostsToComponentMap = new Map<TemplateHostDirective, ContainerComponent>();
    columnDefinition!: ColumnDashboardDefinition;

    componentsBuilt$ = new Subject<void>();

    constructor() { }

    ngAfterViewInit() {
        this.resolveComponents(this.leftColumn, ColumnPosition.Left, this.containers[0], this.columnDefinition.leftSide);
        this.resolveComponents(this.rightColumn, ColumnPosition.Right, this.containers[1], this.columnDefinition.rightSide);

        this.componentsBuilt$.next();
    }

    public buildComponents(columnDefinition: ColumnDashboardDefinition): void {
        this.columnDefinition = columnDefinition;    
    }

    private resolveComponents(templateHost: TemplateHostDirective, columnPosition: ColumnPosition, container: Type<ContainerComponent>, tiles: Array<DynamicTile>): void {
        const componentRef = templateHost.viewContainerRef.createComponent(container);

        componentRef.instance.buildComponents(columnPosition, tiles);

        this.templateHostsToComponentMap.set(templateHost, componentRef.instance);
    }

    setTileData(tiles: Array<Tile>): void {
        this.templateHostsToComponentMap.forEach((container: ContainerComponent) => {
            container.setTileData(tiles);
        });
    }

    getEditEvents(): Array<Subject<BaseTile>> {
        const editEvents = new Array<Subject<BaseTile>>();

        this.templateHostsToComponentMap.forEach((containerComponent: ContainerComponent) => {
            const events = containerComponent.getEditEvents();
            editEvents.push(...events);
        });

        return editEvents;
    }

    getTileRequest(): TilesRequest {
        const tileRequest = new TilesRequest();
        tileRequest.tiles = new Array<TileMetaData>();
        this.templateHostsToComponentMap.forEach((containerComponent: ContainerComponent) => {
            const metaDatas = containerComponent.getTileMetaData();
            tileRequest.tiles.push(...metaDatas);
        });

        return tileRequest;
    }

    clearTileData(): void {
        this.templateHostsToComponentMap.forEach((container: ContainerComponent) => {
            container.clearTileData();
        });
    }
}