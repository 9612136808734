import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { IconInfoTileComponent } from '../../tiles/dashboard-tiles/icon-info-tile/icon-info-tile.component';
import { ChartsService } from '../../../services/charts.service';
import { RankedComboTileComponent } from '../../tiles/dashboard-tiles/ranked-combo-tile/ranked-combo-tile.component';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'responsive-repairs-dashboard',
    templateUrl: 'responsive-repairs.component.html'
})
export class ResponsiveRepairsDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.ResponsiveRepairs;
    dashboardHTMLId = 'responsive-repairs-dashboard-body';
    portraitExport = false;

    @ViewChild('RRDashChart1') comboTile!: RankedComboTileComponent;
    @ViewChild('RRDashChart2') info1Tile!: IconInfoTileComponent;
    @ViewChild('RRDashChart3') info2Tile!: IconInfoTileComponent;
    @ViewChild('RRDashChart4') info3Tile!: IconInfoTileComponent;
    @ViewChild('RRDashChart5') info4Tile!: IconInfoTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['RRDashChart1', this.comboTile],
            ['RRDashChart2', this.info1Tile],
            ['RRDashChart3', this.info2Tile],
            ['RRDashChart4', this.info3Tile],
            ['RRDashChart5', this.info4Tile]
        ]);

        this.Init();
    }
}
