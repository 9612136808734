export const environment = {
    production: false,
    stsServer: 'https://devlogin.housemark.co.uk',
    redirect_url: 'https://app-hm-reporting-dev.housemark.co.uk/',
    client_id: 'lexusdev',
    response_type: 'id_token token',
    scope: 'openid profile housemark_profile email lexus_api',
    post_logout_redirect_uri: 'https://app-hm-reporting-dev.housemark.co.uk/',
    start_checksession: false,
    silent_renew: true,
    silent_renew_url:
        'https://app-hm-reporting-dev.housemark.co.uk/silent-renew.html',
    forbidden_route: '/Forbidden',
    unauthorized_route: '/unauthorized',
    max_id_token_iat_offset_allowed_in_seconds: 60,
    instrumentation_key: '062eae9a-38bd-4a65-bc46-a353f576c76f',
    help_links: {
        overview:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter0',
        using_dashboards:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter4',
        viewing_dashboards:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter5',
        editing_dashboards:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter6',
        saving_dashboards:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter7',
        exporting_dashboards:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter9',
        using_reports:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter16',
        viewing_reports:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter17',
        editing_reports:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter18',
        saving_reports:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter19',
        loading_reports:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter20',
        using_datasets:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter22',
        updating_date_range:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter25',
        changing_saving_peer_groups:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter28',
        analyse_function:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter38',
        inidicator_definitions:
            'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter44',
        faq: 'https://members.housemark.co.uk/data/benchmarking/Pages/RunningBenchmarkingReports.aspx#chapter45',
    },

    nexus_url: 'https://eformbeta.housemark.co.uk',
};
