import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { LayoutService } from '../../../services/layout.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ExportService } from '../../../services/export.service';
import { ChartsService } from '../../../services/charts.service';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { DashboardSectionComponent } from '../../tiles/dashboard-tiles/dashboard-section/dashboard-section.component';
import { Coordinates } from '../../../models/coordinates';
import { ManagePeerGroupsComponent } from '../../my-account/manage-peer-groups/manage-peer-groups.component';
import { JsReportService } from '../../../services/js-report.service';
import { JsReportPage } from '../../../models/export/js-report-page';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'vfm',
    templateUrl: './vfm-dashboard.component.html'
})
export class VfmDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.VFMQuadrant;
    currentCoordinates = new Map<DashboardSectionComponent, Coordinates>();
    dashboardHTMLId = 'global-accounts-dashboard-body';
    portraitExport = false;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    @ViewChild('VFMDashChart1') Overheads!: DashboardSectionComponent;
    @ViewChild('VFMDashChart2') housingManagement!: DashboardSectionComponent;
    @ViewChild('VFMDashChart3') responsiveRepairs!: DashboardSectionComponent;
    @ViewChild('VFMDashChart4') voidsAndLettings!: DashboardSectionComponent;
    @ViewChild('VFMDashChart5') buildingSafety!: DashboardSectionComponent;
    @ViewChild('VFMDashChart6') majorRepairs!: DashboardSectionComponent;
    @ViewChild('VFMDashChart7') customerExperience!: DashboardSectionComponent;
    @ViewChild('VFMDashChart8') communityServices!: DashboardSectionComponent;

    ngAfterViewInit() {
        this.componentMap = new Map<string, DashboardSectionComponent>([
            ['VFMDashChart1', this.Overheads],
            ['VFMDashChart2', this.housingManagement],
            ['VFMDashChart3', this.responsiveRepairs],
            ['VFMDashChart4', this.voidsAndLettings],
            ['VFMDashChart5', this.buildingSafety],
            ['VFMDashChart6', this.majorRepairs],
            ['VFMDashChart7', this.customerExperience],
            ['VFMDashChart8', this.communityServices]
        ]);

        this.Init();

        const section = this.componentMap.get('VFMDashChart1') as DashboardSectionComponent;
        section.openSection();
    }

    public drawPoint(tile: DashboardSectionComponent) {
        const coordinates = tile.getPercentiles();
        let componentMapKey = '';

        this.componentMap.forEach((value: BaseTile, key: string) => {
            if (value === tile) componentMapKey = key;
        });

        const pointElement = document.getElementById(componentMapKey)!;

        if (coordinates.x === null || coordinates.y === null) {
            pointElement.classList.add('hide');
            return;
        }

        pointElement.classList.remove('hide');

        this.moveMarker(coordinates, pointElement, tile);
        this.currentCoordinates.set(tile, coordinates);
    }

    private moveMarker(coordinates: Coordinates, pointElement: HTMLElement, currentTile: DashboardSectionComponent) {
        const keys = Array.from(this.currentCoordinates.keys());
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (key !== currentTile) {
                const mapCoordinates = this.currentCoordinates.get(key)!;
                const shouldReset = coordinates.addBufferIfCoordinatesOverlap(mapCoordinates);

                if (shouldReset) i = 0;
            }
        }

        // we need to apply a transition inline otherwise the transition doesn't work at all
        pointElement.style.transition = '300ms';
        pointElement.style.left = `${coordinates.x}%`;
        pointElement.style.top = `${coordinates.y}%`;
    }

    public openSection(event: MouseEvent) {
        const element = event.target as HTMLElement;

        this.componentMap.forEach((s: BaseTile, key: string) => {
            const section = s as DashboardSectionComponent
            if (key === element.id) {
                section.openSection();
                element.classList.add('selected');
            } else {
                section.closeSection();
                const point = document.getElementById(key)!;
                point.classList.remove('selected');
            }
        });
    }

    override error(toastr: ToastrService) {
        toastr.error('Please refresh the page or set filters to try again', 'An error occured when loading the dashboard');
    }

    public override exportPdf(): void {
        const pages = new Array<JsReportPage>();

        const contentsMap = new Map<string, string[]>();
        this.addQuadrantToReport(pages, contentsMap);

        // we start at 2 to account for the vfm quadrant chart page and section divider
        let pageNum = 2;
        this.componentMap.forEach((t: BaseTile) => {
            const tile = t as DashboardSectionComponent;
            pageNum++;

            const sectionHtml = `<div class="report-page report-section-name"><div class="page-number">${pageNum}</div><span>${tile.sectionName}</span></div>`;
            const sectionPage = new JsReportPage(sectionHtml);
            const charts = tile.getChartsHtml();
            contentsMap.set(tile.sectionName, tile.getChartTitles());

            pageNum++;

            let chartsHtml = `<div class="report-page report-section-charts"><div class="page-number">${pageNum}</div>`;
            chartsHtml += charts.join(' ');

            chartsHtml = chartsHtml.replace(/^\s*|\n|<!--[\s\S]*?-->|lg-/gm, '');
            chartsHtml = chartsHtml.replace(/£/gm, '&pound;');

            chartsHtml += '</div>';
            const page = new JsReportPage(chartsHtml);
            pages.push(sectionPage, page);
        });

        const fullPdf = this.jsReportService.buildVfmDashboard(pages, contentsMap);
        const fileName = `${this.dashboardName}.pdf`;

        this.spinnerService.show('app-spinner', `\'${fileName}\' generating`);
        this.exportService.exportMultiPageDashboardAsPDF(this.dashboardName, fullPdf).subscribe((res: Blob) => {
            this.spinnerService.hide('app-spinner', true);

            this.jsReportService.startDownload(fileName, res);
        }, () => this.spinnerService.hide('app-spinner', true));
    }

    private addQuadrantToReport(pages: JsReportPage[], contentsMap: Map<string, string[]>) {
        const quadrant = document.getElementById('quadrant')!.outerHTML;
        const quadrantHtml = `<div class="report-page report-section-charts"><div class="page-number">2</div>${quadrant}${this.getQuadrantKeys()}</div>`;

        const sectionHtml = `<div class="report-page report-section-name"><div class="page-number">1</div><span>VFM quadrant</span></div>`;
        pages.push(new JsReportPage(sectionHtml));
        pages.push(new JsReportPage(quadrantHtml));

        contentsMap.set('VFM quadrant', ['VFM quadrant']);
    }

    private getQuadrantKeys(): string {
        let listHtml = '<div class="quadrant-key"><ul>';
        this.componentMap.forEach((value: BaseTile) => {
            const tile = value as DashboardSectionComponent;
            listHtml +=`<li><div class="quadrant-point">${tile.index}</div>${tile.sectionName}</li>`;
        });

        listHtml += `</ul></div>`;

        return listHtml;
    }
}
