<div class="report-modal" id="reorder-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Reorder report</h4>
                </div>
                <div class="body">
                    <span class="title">Select sections to download</span>
                    <div class="reorder-list" cdkDropList (cdkDropListDropped)="dropSection($event)">
                        <div *ngFor="let section of dialogueItems; let j = index">
                            <div class="reorder-list-item" cdkDrag [class.disabled]="!section.isSelected">
                                <div class="name-wrapper" (click)="section.hideComponents = !section.hideComponents">
                                    <i class="fas fa-chevron-right" *ngIf="section.hideComponents"></i>
                                    <i class="fas fa-chevron-down" *ngIf="!section.hideComponents"></i>
                                    {{section.sectionName}}
                                </div>
                                <div class="icon-wrapper" *ngIf="section.isSelected">
                                    <i class="hm-icon-move" cdkDragHandle></i>
                                    <i class="hm-icon-bin" (click)="toggleSection(section)"></i>
                                </div>
                                <div class="icon-wrapper" *ngIf="!section.isSelected">
                                    <i class="hm-icon-reset" (click)="toggleSection(section)"></i>
                                </div>
                            </div>
                            <div class="nested" *ngIf="!section.hideComponents" cdkDropList (cdkDropListDropped)="dropSubItem($event, j)">
                                <div *ngFor="let component of section.components; let i = index" class="reorder-list-item"
                                    [class.disabled]="!component.isSelected || !section.isSelected" cdkDrag>
                                    <div class="name-wrapper">{{component.componentName}}</div>
                                    <div class="icon-wrapper" *ngIf="component.isSelected && section.isSelected">
                                        <i class="hm-icon-move" cdkDragHandle></i>
                                        <i class="hm-icon-bin" (click)="toggleComponent(component)"></i>
                                    </div>
                                    <div class="icon-wrapper" *ngIf="section.isSelected && !component.isSelected">
                                        <i class="hm-icon-reset" (click)="toggleComponent(component)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button (click)="apply()">Apply</button>

                    <button class="cancel dark-button" (click)="close()">Cancel</button>

                </div>
            </div>
        </div>
    </div>
</div>