import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CombinedStreamService } from './combined-stream.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class ComponentsService {

    constructor(private combinedStreamService: CombinedStreamService, private httpClient: HttpClient) {
    }

    getOrganisationsComponents(): Observable<any> {
        const apiData = this.combinedStreamService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey) {
            return this.httpClient.get(`api/components/Analyse/${apiData.organisationWKey}/${apiData.periods}/${apiData.hasAccessToCore}`, { headers: apiData.headers });
        }

        return of();
    }
}
