import { Component, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
    @Output() triggerSliderInit = new EventEmitter<any>();

    constructor(private navigationService: NavigationService, private oidcSecurityService: OidcSecurityService,
        private appInsightsService: ApplicationInsights
        ) {
    }

    public toggleTab(componentLookupKey: string): void {
        this.navigationService.toggleComponentByName(componentLookupKey);

        if (componentLookupKey === 'PeerSelectionComponent') {
            this.triggerSliderInit.emit();
        }
    }

    public isPageOpen(componentName: string): boolean {
        return this.navigationService.isComponentShowing(componentName);
    }

    public signOut() {
        this.appInsightsService.clearAuthenticatedUserContext();
        this.oidcSecurityService.logoff();
    }
}
