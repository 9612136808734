import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { OidcSecurityService, AuthenticatedResult } from 'angular-auth-oidc-client';
import { SetupService } from './setup.service';
import { UserData } from '../models/identity/user-data';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityGuardService implements CanActivate {

    storage: Storage;
    state?: RouterStateSnapshot;

    constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private setupService: SetupService) {
        this.storage = sessionStorage;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        this.state = state;
        return this.oidcSecurityService.isAuthenticated$.pipe(first(), map((isAuthorized: AuthenticatedResult) => {
            if (isAuthorized.isAuthenticated) {
                this.oidcSecurityService.getUserData().subscribe((res) => {
                    Object.setPrototypeOf(res, UserData.prototype);
                    if (!res || !res.hasAccess()) {
                        return this.router.navigate(['/unauthorized']);
                    }

                    return true;
                });

                return true;
            }

            return false;
        }));
    }
}
