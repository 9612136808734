import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { LayoutService } from '../../../services/layout.service';
import { ChartsService } from '../../../services/charts.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { ExportService } from '../../../services/export.service';
import { IconInfoTileComponent } from '../../tiles/dashboard-tiles/icon-info-tile/icon-info-tile.component';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'annual-summary-dashboard',
    templateUrl: './annual-summary-dashboard.component.html'
})
export class AnnualSummaryDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.AnnualSummary;
    dashboardHTMLId = 'annual-repairs-dashboard-body';
    portraitExport = false;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    @ViewChild('ASDashChart1') leftInfoTile1!: IconInfoTileComponent;
    @ViewChild('ASDashChart2') leftInfoTile2!: IconInfoTileComponent;
    @ViewChild('ASDashChart3') leftInfoTile3!: IconInfoTileComponent;
    @ViewChild('ASDashChart4') leftInfoTile4!: IconInfoTileComponent;
    @ViewChild('ASDashChart5') rightInfoTile1!: IconInfoTileComponent;
    @ViewChild('ASDashChart6') rightInfoTile2!: IconInfoTileComponent;
    @ViewChild('ASDashChart7') rightInfoTile3!: IconInfoTileComponent;
    @ViewChild('ASDashChart8') rightInfoTile4!: IconInfoTileComponent;

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['ASDashChart1', this.leftInfoTile1],
            ['ASDashChart2', this.leftInfoTile2],
            ['ASDashChart3', this.leftInfoTile3],
            ['ASDashChart4', this.leftInfoTile4],
            ['ASDashChart5', this.rightInfoTile1],
            ['ASDashChart6', this.rightInfoTile2],
            ['ASDashChart7', this.rightInfoTile3],
            ['ASDashChart8', this.rightInfoTile4]
        ]);

        this.Init();
    }
}
