export class AvailablePi {
    name: string;
    calcRef: number;
    availableChartTypes: string;
    units: string;
    id: number;
    selectedKpiChartType: string;
    icon: string;
    noDataMessage: string;
}
