// tslint:disable:no-bitwise
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dashboard } from '../models/dashboard/dashboard';
import { CombinedStreamService } from './combined-stream.service';
import { ReportLayout } from '../models/reports/report-layout';
import { ReportNavigationItem } from '../models/reports/report-navigation-item';
import { SpinnerService } from '../components/spinner/spinner.service';
import { LayoutType } from '../models/enum/layout-type';
import { HeadersService } from './headers.service';
import { Subject, of, Observable } from 'rxjs';
import { BespokeDashboardLayout } from '../models/dashboardLayout';
import { TilesRequest } from '../models/tiles-request';
import { DashboardStyle } from '../models/enum/dashboard-style';
import { UserLayout } from '../models/user-preferences/user-layout';
import { DashboardType } from '../models/enum/dashboard-type';

@Injectable()
export class LayoutService {
    private _dashboardLayoutId: number | null = null;

    public get dashboardLayoutId(): number | null {
        return this._dashboardLayoutId;
    }

    public set dashboardLayoutId(value: number | null) {
        this._dashboardLayoutId = value;
    }

    private loadDashboardLayoutSubject = new Subject<number | null>();

    loadDashboardLayout(layoutId: number | null) {
        this.loadDashboardLayoutSubject.next(layoutId);
    }

    get dashboardLayoutChange(): Observable<number | null> {
        return this.loadDashboardLayoutSubject;
    }
    
    public reportLayoutId: number | null = null;
    public loadReportLayout = new Subject<void>();
    public saveDashboardLayout = new Subject<string>();
    public saveReportLayout = new Subject<string>();

    constructor(private combinedService: CombinedStreamService, private httpClient: HttpClient, private spinnerService: SpinnerService, private headersService: HeadersService) {
    }

    getDashboardLayout(dashboardId: number, layoutId?: number): Observable<Dashboard> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey && apiData.periods) {
            const url = `/api/Layouts/Dashboard/${dashboardId}/${apiData.organisationWKey}/${apiData.periods}/${apiData.hasAccessToCore}/${layoutId}?filterId=${apiData.filterId}`;

            return this.httpClient.get<Dashboard>(url, { headers: apiData.headers });
        }

        return of();
    }

    getReportLayout(reportId: number, layoutId: number | null): Observable<ReportLayout> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey && apiData.periods) {
            const url = `/api/Layouts/Report/${reportId}/${apiData.organisationWKey}/${apiData.periods}/${apiData.hasAccessToCore}/${layoutId}?filterId=${apiData.filterId}`;

            return this.httpClient.get<ReportLayout>(url, { headers: apiData.headers });
        }

        return of();
    }

    getAllReportLayouts(): Observable<ReportNavigationItem[]> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.headers && apiData.organisationWKey) {
            const url = `/api/Layouts/Report/All/${apiData.organisationWKey}`;

            return this.httpClient.get<ReportNavigationItem[]>(url, { headers: apiData.headers });
        }

        return of();
    }

    setLayoutAsDefault(userLayoutId: number, organisationKey: number, layoutType: LayoutType, layoutId: number): any {
        const headers = this.headersService.getHeaders();
        const url = `/api/organisationdefaults/layout/${userLayoutId}/${organisationKey}/${layoutType}/${layoutId}`;

        return this.httpClient.put<number>(url, null, { headers: headers });
    }

    deleteDefaultLayout(organisationKey: number, layoutType: LayoutType, layoutId: number): any {
        const headers = this.headersService.getHeaders();
        const url = `/api/organisationdefaults/layout/${organisationKey}/${layoutType}/${layoutId}`;

        return this.httpClient.delete<number>(url, { headers: headers });
    }

    updateLayout(name: string, id: number): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Preferences/layouts';

        return this.httpClient.put(url, { name: name, id: id }, { headers: headers });
    }

    addLayout(name: string, id: number, tilesReq: TilesRequest, layoutType: LayoutType): Observable<UserLayout> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Preferences/layouts';

        return this.httpClient.post<UserLayout>(url, { name: name, id: id, layoutType: LayoutType[layoutType], tiles: tilesReq.tiles }, { headers: headers });
    }
    deleteLayout(id: number): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = `/api/Preferences/layouts/${id}`;

        return this.httpClient.delete(url, { headers: headers });
    }

    getBespokeDashboardLayout(layoutId?: number): Observable<BespokeDashboardLayout> {
        const apiData = this.combinedService.getAPIParamsAndHeaderValues();

        if (apiData.organisationWKey && apiData.periods) {
            const headers = this.headersService.getHeaders();
            const url = `/api/Layouts/BespokeDashboard/${apiData.organisationWKey}/${apiData.periods}/${apiData.hasAccessToCore}/${layoutId}?filterId=${apiData.filterId}`;

            return this.httpClient.get<BespokeDashboardLayout>(url, { headers: headers });
        }

        return of();
    }

    saveBespokeDashboardLayout(tilesRequest: TilesRequest, dashboardType: DashboardType, dashboardStyle: DashboardStyle, name: string, id: number): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = `/api/Preferences/layouts/bespoke`;

        return this.httpClient.post<any>(url, { name: name, tiles: tilesRequest.tiles, dashboardType: dashboardType, dashboardStyle: dashboardStyle,  layoutType: LayoutType.Dashboard, id: id }, { headers: headers});
    }
}
