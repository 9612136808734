<div id="my-account" *ngIf="showTemplate">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <span class="hm-icon-close top-x" (click)="closePage()"></span>
                <div class="header">
                    <div class="top-row">
                        <h1>My Account</h1>
                        <div class="logout" (click)="signout()">
                            <i class="hm-icon-signout"></i> Logout
                        </div>
                    </div>

                    <div class="header-buttons">
                        <button>
                            <i class="hm-icon-org-type"></i> {{organisation?.name}}
                        </button>
                    </div>

                    <ul class="nav nav-tabs">
                        <li class="nav-item" [class.active]="isTabActive(myAccountTab.PeerGroup)" (click)="setPeerGroupTabToShow(myAccountTab.PeerGroup)">
                            <span class="nav-link"> Saved peer groups</span>
                        </li>
                        <li class="nav-item" [class.active]="isTabActive(myAccountTab.DashboardLayout)" (click)="setPeerGroupTabToShow(myAccountTab.DashboardLayout)">
                            <span class="nav-link"> Saved dashboard layouts</span>
                        </li>
                        <li class="nav-item" [class.active]="isTabActive(myAccountTab.ReportLayout)" (click)="setPeerGroupTabToShow(myAccountTab.ReportLayout)">
                            <span class="nav-link"> Saved report layouts</span>
                        </li>
                        <li class="nav-item" *ngIf="showAdminPage | async" [class.active]="isTabActive(myAccountTab.Admin)" (click)="setPeerGroupTabToShow(myAccountTab.Admin)">
                            <span class="nav-link"> Admin</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid " id="my-account-body">
        <div class="row">
            <div class="col-lg-12">
                <div *ngIf="isTabActive(myAccountTab.PeerGroup)">
                    <manage-peer-groups></manage-peer-groups>
                </div>
                <div *ngIf="isTabActive(myAccountTab.DashboardLayout)">
                    <manage-layouts [type]="LayoutType.Dashboard"></manage-layouts>
                </div>
                <div *ngIf="isTabActive(myAccountTab.ReportLayout)">
                    <manage-layouts [type]="LayoutType.Report"></manage-layouts>
                </div>
                <div *ngIf="isTabActive(myAccountTab.Admin)">
                    <admin></admin>
                </div>
            </div>
        </div>
    </div>
</div>