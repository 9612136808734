import { Component, OnInit, Input } from '@angular/core';
import { ManageComponent } from '../manage-base-component/manage.base.component';
import { LayoutType } from '../../../models/enum/layout-type';
import { LayoutListItem } from '../../../models/user-preferences/my-account-list-item';
import { ToastrService } from '../../../../../node_modules/ngx-toastr';
import { MyAccountService } from '../../../services/my-account.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { LayoutService } from '../../../services/layout.service';
import { NavigationService } from '../../../services/navigation.service';
import { OidcSecurityService } from '../../../../../node_modules/angular-auth-oidc-client';
import { UserData } from '../../../models/identity/user-data';
import { OrganisationService } from '../../../services/organisation.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'manage-layouts',
    templateUrl: './manage-layouts.component.html'
})

export class ManageLayoutsComponent extends ManageComponent implements OnInit {
    LayoutType = LayoutType;
    @Input() type;
    groupedItems = new Map<string, LayoutListItem[]>();

    constructor(private myAccountService: MyAccountService, private spinnerService: SpinnerService, private toastr: ToastrService,
        private layoutService: LayoutService, private navigationService: NavigationService, private oidcSecurityService: OidcSecurityService,
        private organisationService: OrganisationService, private router: Router) {
        super();
    }

    ngOnInit() {
        this.myAccountService.peerGroupsLoaded.subscribe(() => {
            this.getListItems();
        });

        this.oidcSecurityService.getUserData().subscribe((data: UserData) => {
            this.isAdmin = data.feature.includes('reportingadmin');
        });
    }

    getListItems() {
        this.myAccountService.userPeerGroups.layouts.filter(layout => layout.layoutType === LayoutType[this.type]).forEach((listItem: LayoutListItem) => {
            const dictionaryValue = this.groupedItems.get(listItem.layoutName);

            if (!dictionaryValue) {
                this.groupedItems.set(listItem.layoutName, new Array<LayoutListItem>(listItem));
            } else {
                dictionaryValue.push(listItem);
            }
        });

        this.filterList();
    }

    updateSearchTerm(term: string) {
        this.searchTerm = term;

        this.filterList();
    }

    filterList() {
        this.groupedItems.forEach(group => {
            group.forEach((mali: LayoutListItem) => {
                mali.isVisible = mali.type === this.type && (this.searchTerm.length < 4 || mali.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()));
            });
        });

        this.sort();
    }

    saveItem(): void {
        this.spinnerService.show('app-spinner');
        this.layoutService.updateLayout(this.editName, this.selectedItem!.id)
            .subscribe({
                next: () => {
                this.selectedItem!.name = this.editName;
                this.toastr.success('Your layout name was updated successfully', 'Success');
                this.cancel();
            }, error: () => {
                this.toastr.error('Something went wrong when trying to save your layout', 'Error');
                this.cancel();
            }, complete: () => {
                this.spinnerService.hide('app-spinner');
            }
        });
    }

    deleteItem(): void {
        this.spinnerService.show('app-spinner');
        this.layoutService.deleteLayout(this.selectedItem!.id)
            .subscribe({
                next: () => {
                const item = this.selectedItem as LayoutListItem;
                if (item.isDefault) {
                    const listItemsOfType = this.groupedItems.get(item.layoutName)!;

                    const housemarkDefault = listItemsOfType.find(listItems => listItems.isHouseMarkDefinedDefault)!;
                    housemarkDefault.isDefault = true;
                    this.loadItem(housemarkDefault);
                }

                const group = this.groupedItems.get(item.layoutName)!;
                const listItem = group.find(items => items === item);
                if (listItem) group.splice(group.indexOf(listItem), 1);

                this.myAccountService.userPeerGroups.layouts.splice(this.myAccountService.userPeerGroups.layouts.indexOf(item), 1);

                this.toastr.success('Your layout was removed successfully', 'Success');
                this.cancel();
            }, error: () => {
                this.toastr.error('Something went wrong when trying to remove your layout', 'Error');
                this.cancel();
            }, complete: () => {
                this.spinnerService.hide('app-spinner');
            }
        });
    }

    loadItem(layoutItem: LayoutListItem): void {
        if (layoutItem.layoutType === LayoutType[LayoutType.Dashboard]) {
            const dashboardName = layoutItem.layoutName.toLocaleLowerCase().split(' ').join('-');

            const route = `/${dashboardName}`;
            this.layoutService.dashboardLayoutId = layoutItem.id;
            if (this.router.url !== route) {
                this.router.navigateByUrl(route);
            } else {
                this.layoutService.loadDashboardLayout(null);
            }
        } else {

            const route = `/report/${layoutItem.layoutId}`;
            this.layoutService.reportLayoutId = layoutItem.id;

            if (this.router.url !== route) this.router.navigateByUrl(route);
            else this.layoutService.loadReportLayout.next();

        }

        this.navigationService.closeAllTabs();
    }

    setAsDefault(layoutListItem: LayoutListItem) {
        this.spinnerService.show('app-spinner');

        if (layoutListItem.isHouseMarkDefinedDefault) {
            this.layoutService.deleteDefaultLayout(this.organisationService.currentLandlord!.wKey, LayoutType[layoutListItem.layoutType], layoutListItem.layoutId).subscribe(() => {
                return this.setAsDefaultSuccess(layoutListItem);
            }, this.setAsDefaultError);
        } else {
            this.layoutService.setLayoutAsDefault(layoutListItem.id, this.organisationService.currentLandlord!.wKey, LayoutType[layoutListItem.layoutType], layoutListItem.layoutId).subscribe(() => {
                return this.setAsDefaultSuccess(layoutListItem);
            }, this.setAsDefaultError);
        }
    }

    setAsDefaultSuccess(layoutListItem: LayoutListItem) {
        this.groupedItems.get(layoutListItem.layoutName)!.forEach(item => item.isDefault = false);

        layoutListItem.isDefault = true;

        this.toastr.success('Your default peer group was updated successfully', 'Success');
        this.spinnerService.hide('app-spinner');
    }

    setAsDefaultError() {
        this.spinnerService.hide('app-spinner');
        this.toastr.error('An error occured whilst setting your default peer group', 'Please try again');
    }

    sort() {
        if (this.sortCriteria === '') {
            this.sortByLayoutName(false);
        } else if (this.sortCriteria !== '') {

            let sortByAscending = false;
            if (this.sortCriteria.startsWith('-')) {
                sortByAscending = true;
            }

            if (this.sortCriteria.includes('name')) {
                this.sortByName(sortByAscending);
            } else if (this.sortCriteria.includes('type')) {
                this.sortByLayoutName(sortByAscending);
            } else if (this.sortCriteria.includes('creationdate')) {
                this.sortByDate(sortByAscending);
            }
        }
    }

    itemAlreadyExists() {
        if (!this.editName || !this.myAccountService.userPeerGroups.layouts) return false;
        const normalisedName = this.normalizeString(this.editName);

        return this.myAccountService.userPeerGroups.layouts.some(item => this.normalizeString(item.name) === normalisedName);
    }

    sortByLayoutName(sortByAscending: boolean) {
        let keysInMap = Array.from(this.groupedItems.keys());

        if (sortByAscending) keysInMap = keysInMap.reverse();

        const tempMap = new Map<string, LayoutListItem[]>();
        keysInMap.forEach(element => {
            const elements = this.groupedItems.get(element)!;
            tempMap.set(element, elements);
        });

        this.groupedItems = tempMap;
    }

    sortByName(sortByAscending: boolean) {

        const tempMap = new Map<string, LayoutListItem[]>();

        this.groupedItems.forEach((items: LayoutListItem[], key: string) => {
            // might have to set the value on the dictionary instead of this
            const sortedItems = _.sortBy(items, [item => item.name.toLocaleLowerCase()]);

            if (sortByAscending) sortedItems.reverse();

            tempMap.set(key, sortedItems);
        });

        tempMap.forEach((val, key) => {
            this.groupedItems.set(key, val);
        });
    }

    sortByDate(sortByAscending: boolean) {
        const tempMap = new Map<string, LayoutListItem[]>();

        this.groupedItems.forEach((items: LayoutListItem[], key: string) => {
            const sortedItems = _.sortBy(items, [item => item.creationDate]);

            if (sortByAscending) sortedItems.reverse();

            tempMap.set(key, sortedItems);
        });

        tempMap.forEach((val, key) => {
            this.groupedItems.set(key, val);
        });
    }
}
