import { SelectableOption } from './selectable-option';

export class IsSelectableOption<T> extends SelectableOption<T> {
    isSelectable: boolean;

    constructor(isSelectable: boolean, isSelected: boolean, text: string, value: T) {
        super(isSelected, text, value);

        this.isSelectable = isSelectable;
    }
}
