import { Component, OnInit } from '@angular/core';
import { NavigationService, CanShowAndHideTemplate } from '../../services/navigation.service';
import { BaseNavigationComponent } from '../shared/base-navigation/base-navigation';
import { LayoutService } from '../../services/layout.service';
import { HeadersService } from '../../services/headers.service';
import { ReportNavigationItem } from '../../models/reports/report-navigation-item';
import { OrganisationService } from '../../services/organisation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'reports-selection',
    templateUrl: './reports-selection.component.html'
})
export class ReportsSelectionComponent extends BaseNavigationComponent implements OnInit {
    reportItems: ReportNavigationItem[];
    constructor(navigationService: NavigationService, private layoutService: LayoutService, organisationService: OrganisationService, private router: Router) {
        super(navigationService, 'ReportsSelectionComponent', organisationService);
    }

    ngOnInit(): void {
        this.organisationService.landlordChanged.subscribe(() => {
            this.layoutService.getAllReportLayouts().subscribe((res: ReportNavigationItem[]) => {
                this.reportItems = res;
            });
        });

        this.layoutService.getAllReportLayouts().subscribe((res: ReportNavigationItem[]) => {
            this.reportItems = res;
        });
    }

    navigate(id: number) {
        this.layoutService.reportLayoutId = null;
        this.router.navigateByUrl(`/report/${id}`);
    }

    restrictReportNavigationItem(reportId: number) {
        if (reportId === 3 || reportId === 4) {
            return this.restrictNavigationItem();
        }

        return false;
    }
}
