import * as Highcharts from 'highcharts';

export class Chart {
    ref?: Highcharts.Chart;

    constructor(public options: Highcharts.Options) {
        // init series array if not set
        if (!this.options.series) {
            this.options.series = [];
        }
    }
}
