<div class="edit-item-modal edit-analyse-modal edit-modal" [class.showTwoLists]="showSecondPiList" id="edit-item-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="content">
                <div class="edit-modal-header">
                    <h5>Select PI and chart type</h5>
                    <div class="header-search">
                        <input class="search" placeholder="Search performance indicators" [ngModel]="filterText" (ngModelChange)="filterPis($event)">
                        <i class="hm-icon-search"></i>
                    </div>
                </div>
                <div class="body">
                    <div class="choices">
                        <div class="choice-wrapper pi-choice-wrapper">
                            <sectioned-component-options [group]="'first'" [searchEvent]="searchSubject.asObservable()" [availableSections]="model" (selectionChanged)="selectComponent($event)"></sectioned-component-options>
                        </div>
                        <div class="choice-wrapper pi-choice-wrapper second-pi-choice" scrollTo *ngIf="showSecondPiList">
                            <div>Select 2nd PI for scatter chart</div>
                            <sectioned-component-options [group]="'second'" [availableSections]="modelCopy" (selectionChanged)="selectSecondComponent($event)"></sectioned-component-options>
                        </div>
                    </div>

                    <div class="chart-choices">
                        <div class="choice-wrapper">
                            <div class="chart-type-choice">
                                <chart-type-options [componentCharts]="currentChartTypes" [chartIndex]="0" (selectionChanged)="selectChartType($event)"></chart-type-options>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-wrapper">
                    <div class="footer">
                        <div [class.disabled]="disableApply()">
                            <button (click)="applyChoices()">Apply</button>
                        </div>
                        <button (click)="cancel()" class="dark-button">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>