import { ChartType } from '../enum/chart-type';
import { AvailablePi } from './available-pi';
import { Options } from '../../highcharts/options';
import { QuartileSummary } from '../../highcharts/quartile-summary';

export class Tile {
    dashboardItemRef: string;
    selected: AvailablePi;
    available: AvailablePi[];
    data: Options | null;
    selectedChartType: ChartType;
    selectedCalcRef: number;
    quartileSummary: QuartileSummary;
}
