import { Injectable } from '@angular/core';
import { HeadersService } from './headers.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../components/spinner/spinner.service';
import { UserPeerGroups } from '../models/user-preferences/user-peer-groups';
import { PeerGroupListItem, LayoutListItem } from '../models/user-preferences/my-account-list-item';
import { UserLayout } from '../models/user-preferences/user-layout';
import { LayoutType } from '../models/enum/layout-type';
import { OrganisationService } from './organisation.service';
import { BehaviorSubject, first, Observable, Subject } from 'rxjs';
import { OrganisationDefaults } from '../models/user-preferences/organisation-defaults';
import { LayoutService } from './layout.service';
import { PeersService } from './peers.service';

@Injectable()
export class MyAccountService {
    public userPeerGroups: UserPeerGroups = new UserPeerGroups();
    public peerGroupsLoaded = new BehaviorSubject<any>(this.userPeerGroups);
    public userPeerGroupsUpdated = new Subject<any>();

    constructor(private headersService: HeadersService, private httpClient: HttpClient, private spinnerService: SpinnerService, private organisationService: OrganisationService,
        private layoutService: LayoutService, private peersService: PeersService) {

        this.organisationService.landlordChanged.pipe(first()).subscribe(() => {
            this.fetchUserPreferences();
        });
    }

    fetchUserPreferences(): void {
        const headers = this.headersService.getHeaders();
        const orgKey = this.organisationService.currentLandlord!.wKey;
        const url = `/api/Preferences/${orgKey}`;

        this.httpClient.get<UserPeerGroups>(url, { headers: headers }).subscribe((res: UserPeerGroups) => {
            this.userPeerGroups = Object.setPrototypeOf(res, UserPeerGroups.prototype);

            this.userPeerGroups.filters.forEach(filterItem => {
                filterItem = Object.setPrototypeOf(filterItem, PeerGroupListItem.prototype);
                filterItem.isVisible = true;
            });

            const currentFilter = this.userPeerGroups.filters.find(filter => filter.isDefault);
            if (currentFilter) {
                this.peersService.filterName = currentFilter.name;
            }

            this.userPeerGroups.layouts = this.userPeerGroups.layouts.filter(layout => {
                return (layout.id !== 3 && layout.id !== 4) ||
                    (this.organisationService.currentLandlord && !this.organisationService.restrictedOrgTypes.includes(this.organisationService.currentLandlord.type.toLocaleLowerCase()));
            });

            this.userPeerGroups.layouts.forEach(layout => {
                layout = Object.setPrototypeOf(layout, LayoutListItem.prototype);
                layout.isVisible = true;
            });

            this.sortFiltersByName();

            this.peerGroupsLoaded.next(this.userPeerGroups);
        });
    }

    public updateDefaultsDisplayedToUser(organisationKey: number): any {
        if (this.userPeerGroups.layouts.length > 0 && this.userPeerGroups.filters.length > 0) {
            const headers = this.headersService.getHeaders();

            this.httpClient.get<OrganisationDefaults>(`/api/organisationdefaults/${organisationKey}`, { headers: headers }).subscribe((res: OrganisationDefaults) => {

                this.updateDefaultFilters(res.filter);
                this.updateDefaultLayouts(res.layouts);

                this.layoutService.loadDashboardLayout(null);
                this.layoutService.dashboardLayoutId = null;

                this.peerGroupsLoaded.next(this.userPeerGroups);
            });
        }
    }

    private updateDefaultLayouts(apiLayouts: LayoutListItem[]) {
        // remove all layouts for previously selected org
        this.userPeerGroups.layouts = this.userPeerGroups.layouts.filter(filter => filter.isOwnedByUser === true || filter.isHouseMarkDefinedDefault);

        // if there are no defaults for the org returned from the API, set HM layout to default
        this.userPeerGroups.layouts.forEach(layout => {
            if (!apiLayouts.some(apiLayout => apiLayout.layoutType === layout.layoutType && layout.layoutId === apiLayout.layoutId)) {
                if (layout.isHouseMarkDefinedDefault) layout.isDefault = true;
                else layout.isDefault = false;
            }
        });

        apiLayouts.forEach(apiLayout => {
            const layoutsOfType = this.userPeerGroups.layouts.filter(layout => layout.layoutType.toLowerCase() === apiLayout.layoutType.toLowerCase() && layout.layoutId === apiLayout.layoutId);
            layoutsOfType.forEach(layoutItem => layoutItem.isDefault = false);

            const existingLayout = layoutsOfType.find(layoutItem => layoutItem.id === apiLayout.id);

            if (existingLayout) {
                existingLayout.isDefault = true;
            } else {
                const apiListItem = Object.setPrototypeOf(apiLayout, LayoutListItem.prototype);

                apiListItem.isDefault = true;
                this.userPeerGroups.layouts.push(apiListItem);
            }
        });
    }

    private updateDefaultFilters(apiFilter: PeerGroupListItem) {
        this.userPeerGroups.filters = this.userPeerGroups.filters.filter(filter => filter.isOwnedByUser === true || filter.isHouseMarkDefinedDefault);

        if (!apiFilter) {
            this.userPeerGroups.filters.forEach(filter => filter.isDefault = filter.isHouseMarkDefinedDefault);
        } else {
            const defaultFilter = this.userPeerGroups.filters.find(filter => filter.id === apiFilter.id);

            this.userPeerGroups.filters.forEach(filter => filter.isDefault = false);

            if (!defaultFilter) {
                apiFilter.isDefault = true;
                this.userPeerGroups.filters.push(apiFilter);
            } else {
                defaultFilter.isDefault = true;
            }
        }

        const currentFilter = this.userPeerGroups.filters.find(filter => filter.isDefault);
        if (currentFilter) {
            this.peersService.filterName = currentFilter.name;
        }
    }

    updatePeerGroupFilter(name: string, id: number): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Preferences/filters';

        return this.httpClient.put(url, { name: name, id: id }, { headers: headers });
    }

    addPeerGroupFilter(name: string, filterId: number): Observable<PeerGroupListItem> {
        const headers = this.headersService.getHeaders();
        const url = '/api/Preferences/filters';

        return this.httpClient.post<PeerGroupListItem>(url, { name: name, filterId: filterId }, { headers: headers });
    }

    addToUserFilterList(item: PeerGroupListItem) {
        this.userPeerGroups.filters.push(item);
        this.peerGroupsLoaded.next(this.userPeerGroups);
    }

    sortFiltersByName() {
        this.userPeerGroups.filters.sort((left: PeerGroupListItem, right: PeerGroupListItem) => {
            if (left.name < right.name) return -1;

            return 1;
        });

        this.userPeerGroups.layouts.sort((left: LayoutListItem, right: LayoutListItem) => {
            if (left.name < right.name) return -1;

            return 1;
        });
    }

    deletePeerGroup(id: number): Observable<any> {
        const headers = this.headersService.getHeaders();
        const url = `/api/Preferences/filters/${id}`;

        return this.httpClient.delete(url, { headers: headers });
    }

    addToLayoutList(layout: UserLayout, layoutName: string) {
        const item = new LayoutListItem(layout.layoutId, layout.layoutType.toString(), layout.name);
        item.name = layout.name;
        item.id = layout.id;
        item.creationDate = layout.creationDate;
        item.isOwnedByUser = true;
        
        this.userPeerGroups.layouts.push(item);
        this.peerGroupsLoaded.next(this.userPeerGroups);
    }

    removeUserFilter(selectedItem: PeerGroupListItem) {
        const indexOfItem = this.userPeerGroups.filters.indexOf(selectedItem);
        this.userPeerGroups.filters.splice(indexOfItem, 1);
        this.peerGroupsLoaded.next(this.userPeerGroups);
    }
}
