import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from '../../../../node_modules/angular-auth-oidc-client';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'unauthorized',
    templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {

    constructor(private oidcSecurityService: OidcSecurityService) { }

    ngOnInit() {
    }

    logout() {
        this.oidcSecurityService.logoff();
    }
}
