import { NgModule } from '@angular/core';
import { PeersService } from './peers.service';
import { DateRangeService } from './date-range.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OrganisationService } from './organisation.service';
import { CombinedStreamService } from './combined-stream.service';
import { NavigationService } from './navigation.service';
import { SetupService } from './setup.service';
import { MyAccountService } from './my-account.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        PeersService,
        DateRangeService,
        OrganisationService,
        CombinedStreamService,
        NavigationService,
        SetupService,
        MyAccountService
    ]
})
export class SingletonServicesModule {}
