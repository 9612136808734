<div class="filters">
    <div class="radio-buttons">
        <span>Filter group types: </span>
        <div class="button-grouping">
            <input id="all-radiobutton" checked [value]="PeerGroupType.All" (change)="filterList(PeerGroupType.All)"
                type="radio" name="radiogroup"> All
            <input id="dynamic-radiobutton" [value]="PeerGroupType.Dynamic" (change)="filterList(PeerGroupType.Dynamic)"
                type="radio" name="radiogroup"> Dynamic <i placement="right" [ngbTooltip]="dynamicTipContent" class="hm-icon-help"></i>
            <input id="bespoke-radiobutton" [value]="PeerGroupType.Bespoke" (change)="filterList(PeerGroupType.Bespoke)"
                type="radio" name="radiogroup"> Bespoke <i placement="right" [ngbTooltip]="bespokeTipContent" class="hm-icon-help"></i>


            <ng-template #dynamicTipContent>
                <p> A dynamic peer group is based uniquely on the filters you have selected. The number of
                    organisations
                    in the peer group can increase / decrease over time if new organisations are added that meet the
                    filter criteria.</p>
                <p> If you save a peer group after amending the filters, but you have not manually added or removed any
                    peers, the group will automatically be saved as ‘dynamic’. </p>
            </ng-template>

            <ng-template #bespokeTipContent>
                <p> A bespoke peer group is based on a manual selection of organisations. This manual selection is
                    fixed
                    and no other organisations will ever be added to the group.
                </p>
                <p> If you manually add or remove peers before saving the peer group, it will automatically be saved as
                    ‘bespoke’.</p>
            </ng-template>
        </div>
    </div>
    <input id="peer-group-search" class="search" placeholder="Search peer groups" type="search" [(ngModel)]="searchTerm"
        (keyup)="filterList()" (blur)="filterList()">
</div>

<div class="table-wrapper">
    <div class="table-header" [class.admin-view]="isAdmin">
        <div id="load-group" (click)="setSortCriteria('name')">Load group
            <i *ngIf="sortCriteria == 'name'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-name'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('name')" class="fas fa-sort"></i>
        </div>
        <div id="group-type" (click)="setSortCriteria('type')">Group type
            <i *ngIf="sortCriteria == 'type'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-type'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('type')" class="fas fa-sort"></i>
        </div>
        <div id="creation-date" (click)="setSortCriteria('creationdate')">Creation date
            <i *ngIf="sortCriteria == 'creationdate'" class="fas fa-caret-down"></i>
            <i *ngIf="sortCriteria == '-creationdate'" class="fas fa-caret-up"></i>
            <i *ngIf="!sortCriteria.includes('creationdate')" class="fas fa-sort"></i>
        </div>
        <div id="edit-delete">
        </div>
        <div id="set-as-default" *ngIf="isAdmin">
            Set as default
        </div>
    </div>
    <div class="table-body" *ngIf="items">
        <div class="table-row" *ngFor="let peer of items" [class.hide]="!peer.isVisible" [class.admin-view]="isAdmin">
            <div class="name" (click)="loadItem(peer)">
                <i *ngIf="!peer.isOwnedByUser" class="fas fa-lock" ngbTooltip="This option can't be amended or deleted as it is either the HouseMark default option or the default set by your local administrator"></i>
                <i class="far fa-folder-open"></i>
                {{peer.name}}
            </div>
            <div class="type">{{peer.isDynamic ? PeerGroupType[PeerGroupType.Dynamic] :
                PeerGroupType[PeerGroupType.Bespoke]}}</div>
            <div class="date">
                {{peer.creationDate | date : 'dd/MM/YYYY'}}
            </div>
            <div class="edit">
                <ng-container *ngIf="peer.isOwnedByUser && !peer.isHouseMarkDefinedDefault">
                    <i class="fas fa-pencil-alt" (click)="showEditModal(peer)"></i>
                    <i class="far fa-trash-alt" (click)="showDeleteModal(peer)"></i>
                </ng-container>
            </div>
            <div class="default" *ngIf="isAdmin">
                <button *ngIf="peer.isDefault" disabled>Default <i class="hm-icon-tick"></i></button>
                <button *ngIf="!peer.isDefault" (click)="setAsDefault(peer)">Set as default</button>
            </div>
        </div>
    </div>
</div>

<div class="manage-peer-group-edit-modal simple-modal" id="manage-peer-group-edit-modal" *ngIf="showEdit">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Rename</h4>
                </div>
                <div class="body">
                    <span>Edit the name of the saved peer group</span>


                    <input [(ngModel)]="editName" type="search" />
                    <div *ngIf="itemAlreadyExists()" class="item-already-exists">A peer group with this name
                        already exists!</div>

                    <div class="button-wrapper">
                        <button [class.disabled]="saveButtonDisabled()" (click)="saveItem()">Save</button>
                        <button class="dark-button" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="manage-layouts-delete-modal simple-modal" id="manage-layouts-delete-modal" *ngIf="showDelete">
    <div class="modal show">
        <div class="modal-dialog">
            <div class="content">
                <div class="modal-header">
                    <h4>Do you want to delete this peer group</h4>
                </div>
                <div class="body">
                    <span>It will be permanently removed. You can recreate this peer group at a later date</span>

                    <div class="button-wrapper">
                        <button (click)="deleteItem()">Delete</button>
                        <button class="dark-button" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>