import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { OrganisationService } from '../../services/organisation.service';
import { Organisation } from '../../models/organisation';
import { NavigationService } from '../../services/navigation.service';
import { BaseNavigationComponent } from '../shared/base-navigation/base-navigation';

@Component({
    selector: 'organisation-selection',
    templateUrl: './organisation-selection.component.html',
})
export class OrganisationSelectionComponent extends BaseNavigationComponent implements OnInit {
    organisations!: Organisation[];
    selectedOrganisation!: Organisation;
    searchTerm: string = '';
    @ViewChild('OrgSearchBox') orgSearchBox!: ElementRef;

    constructor(organisationService: OrganisationService, navigationService: NavigationService, private changeDector: ChangeDetectorRef) {
        super(navigationService, 'OrganisationSelectionComponent', organisationService);
    }

    ngOnInit() {
        this.organisationService.landlordChanged.subscribe((organisation: Organisation) => {
            this.organisations = this.organisationService.currentLandlords;
            this.selectedOrganisation = this.organisations.find(co => co.wKey === organisation.wKey)!;

            this.searchList(undefined);
        });
    }

    protected changeOrganisation(wKey: number) {
        this.organisationService.changeLandlordSelection(wKey, this.selectedOrganisation.wKey);
        this.searchTerm = '';
    }

    protected isOrganisationSelected(wKey: number): boolean {
        if (this.selectedOrganisation) return this.selectedOrganisation.wKey === wKey;

        return false;
    }

    protected searchList(filterTerm?: string) {
        this.organisations.forEach((organisation: Organisation) => {
            organisation.isVisibleInList = !filterTerm ||
                filterTerm.length < 3 ||
                organisation.name.toLowerCase().includes(filterTerm.toLowerCase());
        });
    }

    protected selectIfOnlyOneLandlord() {
        const organisationsVisible = this.organisations.filter(org => org.isVisibleInList);
        if (organisationsVisible.length === 1) this.changeOrganisation(organisationsVisible[0].wKey);
    }

    public override show() {
        super.show();
        this.changeDector.detectChanges();
        const htmlEl = this.orgSearchBox.nativeElement as HTMLElement;
        htmlEl.focus();
    }
}
