import { Component, OnInit, OnDestroy } from '@angular/core';
import { PeerGroupType } from '../../../models/enum/peer-group-type';
import { ManageComponent } from '../manage-base-component/manage.base.component';
import { MyAccountListItem, PeerGroupListItem } from '../../../models/user-preferences/my-account-list-item';
import { MyAccountService } from '../../../services/my-account.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { PeersService } from '../../../services/peers.service';
import { OrganisationService } from '../../../services/organisation.service';
import { NavigationService } from '../../../services/navigation.service';
import { OidcSecurityService } from '../../../../../node_modules/angular-auth-oidc-client';
import { UserData } from '../../../models/identity/user-data';
import * as _ from 'lodash';
import { first, Subscription } from 'rxjs';

@Component({
    selector: 'manage-peer-groups',
    templateUrl: './manage-peer-groups.component.html'
})

export class ManagePeerGroupsComponent extends ManageComponent implements OnInit, OnDestroy {
    PeerGroupType = PeerGroupType;
    type: PeerGroupType = PeerGroupType.All;
    peerGroupLoadedSubscription!: Subscription;
    landlordChangedSubscription!: Subscription;
    items: Array<PeerGroupListItem> = [];
    override selectedItem?: PeerGroupListItem | undefined;

    constructor(private myAccountService: MyAccountService, private spinnerService: SpinnerService, private oidcSecurityService: OidcSecurityService,
        private toastr: ToastrService, private peersService: PeersService, private organisationService: OrganisationService, private navigationService: NavigationService) {
        super();
    }

    ngOnInit() {
        let defaultListItem = this.myAccountService.userPeerGroups.filters.find(filter => filter.isHouseMarkDefinedDefault);
        if (this.peersService.defaultFilter) defaultListItem!.isDynamic = this.peersService.defaultFilter.isDynamic;

        this.peerGroupLoadedSubscription = this.myAccountService.peerGroupsLoaded.subscribe(() => {
            this.getListItems();
        });

        this.landlordChangedSubscription = this.organisationService.landlordChanged.subscribe(() => {
            this.peersService.peersLoaded.pipe(first()).subscribe(() => {
                defaultListItem = this.myAccountService.userPeerGroups.filters.find(filter => filter.isHouseMarkDefinedDefault);
                if (this.peersService.defaultFilter) defaultListItem!.isDynamic = this.peersService.defaultFilter.isDynamic;

                defaultListItem!.isDynamic = this.peersService.defaultFilter?.isDynamic ?? false;
            });
        });

        this.oidcSecurityService.getUserData().subscribe((data: UserData) => {
            this.isAdmin = data.feature.includes('reportingadmin');
        });
    }

    ngOnDestroy() {
        this.peerGroupLoadedSubscription.unsubscribe();
        this.landlordChangedSubscription.unsubscribe();
    }

    getListItems() {
        this.items = this.myAccountService.userPeerGroups.filters;
        this.filterList();
    }

    filterList(type?: PeerGroupType) {
        if (type !== undefined) this.type = type;

        this.items.forEach((mali: MyAccountListItem) => {
            mali.isVisible = (this.type === PeerGroupType.All || mali.type === this.type)
                && (this.searchTerm.length < 4 || mali.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()));
        });

        this.sort();
    }

    saveItem(): void {
        this.spinnerService.show('app-spinner');
        this.myAccountService.updatePeerGroupFilter(this.editName, this.selectedItem!.id)
            .subscribe({
                next: () => {
                    this.selectedItem!.name = this.editName;
                    this.myAccountService.peerGroupsLoaded.next(this.myAccountService.userPeerGroups);
                    this.toastr.success('Your peer group name was updated successfully', 'Success');
                    this.cancel();
                }, 
                error: () => {
                    this.toastr.error('Something went wrong when trying to save your peer group', 'Error');
                    this.cancel();
                },
                complete: () => {
                    this.spinnerService.hide('app-spinner');                    
                }
        });
    }

    deleteItem(): void {
        this.spinnerService.show('app-spinner');
        this.myAccountService.deletePeerGroup(this.selectedItem!.id)
            .subscribe({
            next: () => {                
                this.myAccountService.removeUserFilter(this.selectedItem!);
                this.toastr.success('Your peer group was removed successfully', 'Success');
                this.cancel();
            }, error: () => {
                this.toastr.error('Something went wrong when trying to remove your peer group', 'Error');
                
                this.cancel();
            }, complete: () => {
                this.spinnerService.hide('app-spinner');
            }

        });
    }

    loadItem(peerGroupItem: PeerGroupListItem): void {
        if (!peerGroupItem.isHouseMarkDefinedDefault) this.peersService.loadPeerGroup(peerGroupItem.filterId, peerGroupItem.name);
        else this.peersService.getDefaultPeers(peerGroupItem.name);

        this.navigationService.toggleComponentByName('MyAccountComponent');
    }

    setAsDefault(peerGroupItem: PeerGroupListItem) {
        this.spinnerService.show('app-spinner');

        if (peerGroupItem.isHouseMarkDefinedDefault) {
            this.peersService.deleteDefaultPeerGroup(this.organisationService.currentLandlord!.wKey).subscribe({
                next: () => this.setAsDefaultSuccess(peerGroupItem, this), error: this.setAsDefaultError});

        } else {
            this.peersService.setPeerGroupAsDefault(peerGroupItem.id,
                this.organisationService.currentLandlord!.wKey).subscribe({ next: () => this.setAsDefaultSuccess(peerGroupItem, this), error: this.setAsDefaultError});
        }
    }

    setAsDefaultSuccess(peerGroupItem: PeerGroupListItem, self: ManagePeerGroupsComponent) {
        self.items.forEach(item => item.isDefault = false);
        peerGroupItem.isDefault = true;

        self.toastr.success('Your default peer group was updated successfully', 'Success');
        self.spinnerService.hide('app-spinner');
    }

    setAsDefaultError(self: ManagePeerGroupsComponent) {
        self.spinnerService.hide('app-spinner');
        self.toastr.error('An error occured whilst setting your default peer group', 'Please try again');
    }

    sort() {
        if (this.sortCriteria === '') {
            this.items = _.sortBy(this.items, [item => item.name.toLocaleLowerCase()]);
        } else if (this.sortCriteria !== '') {
            let sortByAscending = false;
            if (this.sortCriteria.startsWith('-')) {
                sortByAscending = true;
            }

            if (this.sortCriteria.includes('name')) {
                this.items = _.sortBy(this.items, [item => item.name.toLocaleLowerCase()]);
            } else if (this.sortCriteria.includes('type')) {
                this.items = _.sortBy(this.items, ['type', 'name']);
            } else if (this.sortCriteria.includes('creationdate')) {
                this.items = _.sortBy(this.items, 'creationDate', 'name', 'type');
            }

            if (sortByAscending) {
                this.items.reverse();
            }
        }
    }

    itemAlreadyExists(): boolean {
        if (!this.editName || !this.items) return false;
        const normalisedName = this.normalizeString(this.editName);

        return this.items.some(item => this.normalizeString(item.name) === normalisedName);
    }
}
