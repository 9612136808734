import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeadersService } from './headers.service';
import { PeersService } from './peers.service';
import { Injectable } from '@angular/core';
import { OrganisationService } from './organisation.service';
import { UserData } from '../models/identity/user-data';
import { ServiceMessageService } from './service-message.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { forkJoin, Observable, of, switchMap, zip } from 'rxjs';

@Injectable()
export class SetupService {
    public isSetup = false;
    constructor(private oidcSecurityService: OidcSecurityService, private headersService: HeadersService,
        private peersService: PeersService, private organisationService: OrganisationService,
        private appInsightsService: ApplicationInsights, private serviceMessageService: ServiceMessageService) {
    }

    ensureAppIsSetUp(): Observable<unknown> {
        if (!this.isSetup) {

            this.oidcSecurityService.getUserData().subscribe((data: UserData) => {
                if (data.sub) {
                    this.appInsightsService.clearAuthenticatedUserContext();
                    this.appInsightsService.setAuthenticatedUserContext(data.sub, data.preferred_username, true);
                }
            });

            const zip$ = forkJoin([
                this.organisationService.getUsersOrganisations(),
                this.peersService.fetchOrganisations(),
                this.peersService.fetchDynamicPeerGroupFilters(),
                this.peersService.fetchDefaultPeers(),
                this.serviceMessageService.fetchServiceMessage()
            ]);

            return zip$.pipe(switchMap((res) => {
                this.isSetup = true;

                return of();
            }));
        }

        return of();
    }
}
