import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { LayoutService } from '../../../services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { ChartsService } from '../../../services/charts.service';
import { PeersService } from '../../../services/peers.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { SmallChartTileComponent } from '../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'esg-dashboard',
    templateUrl: './esg.component.html'
})
export class ESGComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.ESG;
    dashboardHTMLId = 'esg-dashboard-body';
    portraitExport = false;

    @ViewChild('ESGDashChart1') left1Tile!: SmallChartTileComponent;
    @ViewChild('ESGDashChart2') left2Tile!: SmallChartTileComponent;
    @ViewChild('ESGDashChart3') left3Tile!: SmallChartTileComponent;
    @ViewChild('ESGDashChart4') right1Tile!: SmallChartTileComponent;
    @ViewChild('ESGDashChart5') right2Tile!: SmallChartTileComponent;
    @ViewChild('ESGDashChart6') right3Tile!: SmallChartTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['ESGDashChart1', this.left1Tile],
            ['ESGDashChart2', this.left2Tile],
            ['ESGDashChart3', this.left3Tile],
            ['ESGDashChart4', this.right1Tile],
            ['ESGDashChart5', this.right2Tile],
            ['ESGDashChart6', this.right3Tile]
        ]);

        this.Init();
    }

}
