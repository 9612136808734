import { TileMetaData } from '../../../../models/tile-metadata';
import { HasDashboardItemRef } from '../../../../interfaces/has-dashboard-item-ref';
import { Output, EventEmitter, Input, Component } from '@angular/core';
import { EditTileOptions } from '../../../../models/edit-tile-options';

export interface IBaseTile {
    openEditEvent: EventEmitter<IBaseTile>;
    dashboardItemRefs: string[];
    getComponentsInTile(): Array<TileMetaData>;
    initializeModel(...model: Array<HasDashboardItemRef>): void;
    updateModel(...tileData: Array<TileMetaData>): void;
    getEditOptions(): EditTileOptions;
    openEdit(): void;
}

@Component({
    template: '',
    selector: ''
})
export abstract class BaseTile implements IBaseTile {
    abstract openEditEvent: EventEmitter<BaseTile>;
    @Input() dashboardItemRefs: string[] = [];
    abstract getComponentsInTile(): Array<TileMetaData>;
    abstract initializeModel(...model: Array<HasDashboardItemRef>): void;
    abstract updateModel(...tileData: Array<TileMetaData>): void;
    abstract getEditOptions(): EditTileOptions;
    public openEdit() {
        this.openEditEvent.emit(this);
    }
}