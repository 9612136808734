export enum DashboardId {
    ResponsiveRepairs = 1,
    SectorScorecard = 2,
    GlobalAccounts = 3,
    AnnualSummary = 4, 
    TenantSatisfaction = 5,
    RentCollection = 6,
    VoidsLettings = 7,
    QuarterlyPerformance = 8,
    VFMQuadrant = 9,
    Bespoke = 10,
    TSM = 11,
    ESG = 12,
}