import { NgModule } from '@angular/core';
import { ManageDropDownComponent } from './manage-drop-down/manage-dropdown.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SimpleLoadItemModalComponent } from './simple-load-item-modal/simple-load-item-modal.component';
import { SimpleSaveItemModalComponent } from './simple-save-item-modal/simple-save-item-modal.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        ManageDropDownComponent,
        SimpleLoadItemModalComponent,
        SimpleSaveItemModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbTooltipModule
    ],
    exports: [
        ManageDropDownComponent,
        SimpleLoadItemModalComponent,
        SimpleSaveItemModalComponent
    ]
})
export class SharedModule { }
