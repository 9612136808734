<div class="date-range sidebar-component" [class.hide]="!showTemplate" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                    <span class="hm-icon-close top-x" (click)="close()"></span>
                <div class="header">
                    <div>
                        <h1>Date Range</h1>
                        <h4>Select date range</h4>
                    </div>
                    
                    <div class="date-range-header-buttons">
                        <button class="medium-blue" (click)="reset()">Reset</button>
                        <button class="upload-link" (click)="openNexus()"> Enter data <span class="fas fa-external-link-alt"></span></button>
                        <button  (click)="apply()">Apply date range</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="date-range-body" id="date-range-body">
                <div class="col-lg-12">
                        <div class="table" *ngIf="viewModel">
                            <table>
                                <thead>
                                    <th>Date section</th>
                                    <th>Data period</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let section of viewModel.sections">
                                        <td>{{section.text}}</td>
                                        <td>
                                            <select [ngModel]="section.selectedOption" (ngModelChange)="changeSelection($event, section)" name="{{section.text}}">
                                                <option *ngFor="let option of section.options" [ngValue]="option.value">{{option.text}}</option>
                                            </select>
            
                                            <div class="extra-option" *ngIf="section.extraOption">
                                                <label><input [(ngModel)]="section.extraOption.isSelected" type="checkbox"> {{section.extraOption.text}}</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>